import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'currencyAmount',
})
export class CurrencyAmountPipe implements PipeTransform {
	transform(value: any): string {
		return `${parseFloat(value).toFixed(2)}`
	}
}
