<ion-toolbar class="global-footer" color="primary" *ngIf="!user.isLoggedIn && !isPaymentoptions">
	<ion-grid class="ion-no-padding">
		<ion-row>
			<ion-col size="12" (click)="login()">
				<ion-button class="footer-button" expand="full" fill="clear" aria-label="Login or Sign-Up"
					>LOGIN OR SIGNUP</ion-button
				>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-toolbar>
