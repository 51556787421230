import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Customer, Location, CurrentSelection, OnlineOrderingMethod } from '@golo/models/lib/interfaces'
import { map } from 'rxjs/operators'
import * as moment from 'dayjs'
import { OrdertimepickerComponent } from '../../ordertimepicker/ordertimepicker.component'
import { get, find, defaultsDeep, forIn, first } from 'lodash'
import { ActivatedRoute, Router } from '@angular/router'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'
import { Subscription } from 'rxjs'
import { GuideService } from 'src/app/services/guide.service'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'

@Component({
	selector: 'app-options',
	templateUrl: './options.component.html',
	styleUrls: ['./options.component.scss'],
})
export class OptionsComponent implements OnInit {
	currentSelectionFormState = {
		destinationType: {
			delivery: false,
			collection: false,
			curbside: false,
			drivethru: false,
			eatin: false,
		},
		when: {
			asap: true,
			schedule: false,
		},
	}
	private logger = LoggerFactory.getLogger(this)
	store: any
	showOptions: boolean = false
	constructor(
		private modalController: ModalController,
		private router: Router,
		private services: FacadeService,
		private guide: GuideService,
		private profile: CustomerProfileService,
		private routerActivate: ActivatedRoute
	) {}

	locations: Location[]
	currentSelection: CurrentSelection
	scheduleDateTimePretty = ''
	profileSub: Subscription
	orderingEnabled: boolean
	tableNo: string
	ngOnInit() {
		this.menuRouterParams()
		this.profileSub = this.profile
			.watch()
			.pipe(
				map((cust: Customer) => {
					if (cust.currentSelection.scheduleDateTime) {
						this.scheduleDateTimePretty = moment(cust.currentSelection.scheduleDateTime).format('ddd DD/MM HH:mm')
					}
					this.locations = cust.locations
					this.currentSelection = cust.currentSelection
					this.currentSelectionToForm()
				})
			)
			.subscribe()
	}

	menuRouterParams() {
		this.routerActivate.queryParamMap.subscribe((params) => {
			const paramsObject: any = { ...params }
			this.store = paramsObject?.params?.store
			this.tableNo = paramsObject?.params?.table
			this.orderingEnabled = this.store ? false : true
		})
	}

	ngOnDestroy() {
		this.profileSub.unsubscribe()
	}

	ngAfterViewInit() {
		this.autoSelectFirstAvailableIfNoneSelected()
	}

	async closeModal(reroute: boolean, skipGuide = false) {
		const close = async () => {
			const merged = defaultsDeep(this.formToCurrentSelection(), this.currentSelection)
			this.profile.update({
				currentSelection: merged,
			})
			await this.modalController.dismiss()
			if (reroute && this.router.url == '/home' && get(this.profile, 'customer.currentSelection.store._id')) {
				this.router.navigate([`/menu/${this.profile.customer.currentSelection.store._id}`], {
					queryParams: { skipLand: true },
				})
			}
			this.services.cart.updatePrices(get(this.currentSelection, 'orderingMethod.pricingOption'))
		}
		if (!skipGuide) {
			const checkPass = await this.guideChecks()
			if (checkPass) {
				close()
			}
		} else {
			close()
		}
	}

	async address() {
		this.services.geo.mapTarget = null
		await this.modalController.dismiss({ nav: 'AddressComponent' })
	}

	selectionChange($event) {
		if ($event.detail.value.indexOf('_') === -1) {
			const prop = $event.detail.value.split('.')[1]
			const sub = $event.detail.value.split('.')[0]
			Object.keys(this.currentSelectionFormState[sub]).map((k) => {
				if (k === prop) {
					this.currentSelectionFormState[sub][k] = true
				} else {
					this.currentSelectionFormState[sub][k] = false
				}
			})
		} else {
			const field = $event.detail.value.split('_')[0]
			const val = $event.detail.value.split('_')[1]
			if (field === 'selectedLocationName') {
				this.currentSelection.location = find(this.locations, ['nickName', val])
			}
		}

		if (
			$event.detail.value.indexOf('destinationType') > -1 ||
			$event.detail.value.indexOf('selectedLocationName') > -1
		) {
			this.guideChecks()
		}
	}

	private guideChecks() {
		const isDelivery = this.currentSelectionFormState['destinationType']['delivery']
		if (isDelivery) {
			return this.guide.optionsDeliveryRadiusCheck(this.currentSelection.location, this.closeModal.bind(this))
		} else {
			return true
		}
	}

	schedule() {
		this.currentSelectionFormState.when.schedule = true
		this.currentSelectionFormState.when.asap = false
		this.openScheduleDayTimeSelectionModal()
	}

	private async warnNoStoreSelected() {
		this.services.toast.toast({ text: 'Please select your store before scheduling' })
	}

	async openScheduleDayTimeSelectionModal() {
		const modal = await this.modalController.create({
			component: OrdertimepickerComponent,
			cssClass: 'golo-main-modal',
			componentProps: {
				scheduleDateTime: this.currentSelection.scheduleDateTime,
				storeStartHour: parseInt(this.currentSelection.store.tradingDetails.times.open),
				storeEndHour: parseInt(this.currentSelection.store.tradingDetails.times.close),
			},
		})
		modal.onWillDismiss().then((results: any) => {
			const storeOrderingMethod = this.currentSelection.store.orderingMethods.find(
				(oo) => oo.name === this.currentSelection.orderingMethod.name
			)
			if (results.data !== '') {
				this.currentSelection.scheduleDateTime = results.data
				if (this.services.trading.isTrading(storeOrderingMethod?.availability, results.data)) {
					this.scheduleDateTimePretty = moment(results.data).format('ddd DD/MM HH:mm')
				} else {
					this.services.toast.toast({ text: 'Store is not open at that time' })
					this.currentSelectionFormState.when = {
						asap: true,
						schedule: false,
					}
				}
			} else {
				this.currentSelectionFormState.when = {
					asap: true,
					schedule: false,
				}
			}
		})
		return await modal.present()
	}

	asap() {
		this.currentSelectionFormState.when.schedule = false
		this.currentSelectionFormState.when.asap = true
		this.scheduleDateTimePretty = ''
		this.currentSelection.scheduleDateTime = ''
	}

	currentSelectionToForm() {
		Object.keys(this.currentSelectionFormState.destinationType).map((k) => {
			if (k === this.currentSelection.destinationType) {
				this.currentSelectionFormState.destinationType[k] = true
			} else {
				this.currentSelectionFormState.destinationType[k] = false
			}
		})
		Object.keys(this.currentSelectionFormState.when).map((k) => {
			if (k === this.currentSelection.when) {
				this.currentSelectionFormState.when[k] = true
			} else {
				this.currentSelectionFormState.when[k] = false
			}
		})
	}

	formToCurrentSelection(): CurrentSelection {
		const currentSelection: any = {}
		Object.keys(this.currentSelectionFormState).map((sub) => {
			Object.keys(this.currentSelectionFormState[sub]).map((prop) => {
				if (sub !== 'destination' && this.currentSelectionFormState[sub][prop]) {
					currentSelection[sub] = prop
				}
			})
		})
		return {
			...currentSelection,
			orderingMethod: (this.services.data.settings[0].orderingMethods || []).find(
				(oMethod) => oMethod.name.toLowerCase().replace(' ', '') == currentSelection.destinationType
			),
		}
	}

	isLocationSelected(nickName) {
		return get(this.currentSelection, 'location.nickName') === nickName
	}

	isMethodAvailable(destinationTypeName: string) {
		if (this.currentSelection.store) {
			return !!find(this.currentSelection.store.orderingMethods, (item) => {
				return item.name.toLowerCase().replace(' ', '') === destinationTypeName
			})
		} else {
			return true
		}
	}
	getOrderMethodName(destinationTypeName: string) {
		if (!this.currentSelection.store) return
		return (
			this.currentSelection.store.orderingMethods.find(
				(item) => item.name.toLowerCase().replace(' ', '') === destinationTypeName
			) as OnlineOrderingMethod
		)?.salesOrderTag.name
	}

	private autoSelectFirstAvailableIfNoneSelected() {
		let anySelected = false
		forIn(this.currentSelectionFormState.destinationType, (value, key) => {
			if (this.isMethodAvailable(key)) {
				value && (anySelected = true)
			}
		})
		if (!anySelected) {
			//model change called from ngAfterViewInit
			setTimeout(() => {
				this.currentSelectionFormState.destinationType[
					first(
						Object.keys(this.currentSelectionFormState.destinationType).filter((key) => this.isMethodAvailable(key))
					)
				] = true
			})
		}
	}

	async selectStore() {
		await this.modalController.dismiss()
		this.router.navigate(['/home'], {
			queryParams: { skipLand: true },
		})
	}
}
