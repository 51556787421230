<ion-header>
	<ion-toolbar color="primary">
		<ion-title>SCAN QR CODE</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="closeScanModal()" aria-label="Close">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-card>
		<ion-grid>
			<ion-row>
				<ion-col size="12" size-xs="12" size-sm="8" size-md="8" size-lg="12">
					<ion-grid>
						<ion-row>
							<ion-col>
								<ion-item class="ion-text-center ion-text-wrap" lines="none">
									<ion-label class="details ion-text-wrap">Scan QR Code to order</ion-label>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-col>
				<ion-col size="12" size-xs="12" size-sm="4" size-md="4" size-lg="12">
					<ion-grid class="ion-no-padding">
						<ion-row class="qr-row">
							<ion-col class="qrcode">
								<video #video width="60%" height="80%" [hidden]="!scanActive"></video>
								<canvas #canvas hidden></canvas>
							</ion-col>
						</ion-row>
						<ion-row class="qr-row"></ion-row>
					</ion-grid>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-card>
</ion-content>
