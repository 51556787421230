import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'
import { SeqModalControllerService } from '../services/seq-modal-controller.service'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	private logger = LoggerFactory.getLogger(this)
	constructor(
		private services: FacadeService,
		private profile: CustomerProfileService,
		private modal: SeqModalControllerService
	) {}

	/**
	 * Test Cases
	 * 	if user not logged in -> open login dialog
	 */
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.profile.isLoggedIn) {
			return this.modal.openLoginModal().then(() => false)
		} else {
			return true
		}
	}
}
