<ion-header>
	<ion-toolbar color="primary">
		<ion-title>{{ reward.name }}</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()" aria-label="Close">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-grid class="ion-no-padding">
		<ion-row>
			<ion-col size="12" size-xs="12" size-sm="7" size-md="7" size-lg="12">
				<ion-card>
					<ion-grid class="ion-no-padding">
						<ion-row>
							<ion-col class="img-size">
								<picture>
									<source srcset="{{ env.api }}/images/{{ reward.image }}.webp" />
									<img
										*ngIf="reward.image"
										src="{{ env.api }}/images/{{ reward.image }}.png"
										#image
										(error)="image.hidden = true"
										[hidden]="image.hidden"
										alt="Reward Image"
										width="100%"
										height="100%"
									/>
								</picture>
							</ion-col>
							<ion-col *ngIf="reward.description">
								<div class="details">{{ reward.description }}</div>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-card>
			</ion-col>
			<ion-col size="12" size-xs="12" size-sm="5" size-md="5" size-lg="12">
				<ion-card>
					<ion-grid class="ion-no-padding qr-grid">
						<ion-row class="qr-row">
							<ion-col class="qr-row">
								<ngx-qrcode
									[elementType]="elementType"
									[errorCorrectionLevel]="correctionLevel"
									[value]="qrCode"
									[width]="200"
									class="ion-text-center"
								></ngx-qrcode>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col class="ion-text-center">
								<ion-item class="ion-text-center">
									<ion-label class="dash-code">{{ qrCode }}</ion-label>
								</ion-item>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col class="ion-text-center">
								<ion-item class="ion-text-center ion-text-wrap" line="none">
									<ion-label class="details ion-text-wrap">Ask merchant to scan or enter the code to redeem</ion-label>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-card>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>
