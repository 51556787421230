import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'
import { IonSearchbar } from '@ionic/angular'
import { map } from 'rxjs/operators'
import { get } from 'lodash'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'

@Component({
	selector: 'app-productsearch',
	templateUrl: './productsearch.component.html',
	styleUrls: ['./productsearch.component.scss'],
})
export class ProductSearchComponent implements OnInit {
	@ViewChild(IonSearchbar, { static: true }) ionSearchbar: IonSearchbar

	private logger = LoggerFactory.getLogger(this)
	constructor(
		private modalController: ModalController,
		private services: FacadeService,
		private profile: CustomerProfileService
	) {
		this.env = this.services.env.get()
	}

	public storeId: string
	searchTerm: string = ''
	env: any
	products: any[] = []
	ngOnInit() {}

	ionViewDidEnter() {
		setTimeout(() => {
			this.ionSearchbar.setFocus()
		})
	}

	//FIXME [ask] change to use local data service
	searchProducts() {
		if (this.searchTerm.length >= 3) {
			const currentOrderSalesTag = get(this.profile, 'customer.currentSelection.orderingMethod.salesOrderTag.id')
			const availabilityCheckTime =
				this.profile?.customer?.currentSelection?.when === 'asap'
					? new Date()
					: this.profile?.customer?.currentSelection?.scheduleDateTime
			this.services.api
				.searchProducts(this.searchTerm)
				.pipe(
					map((products) => {
						this.products = products
							.filter((p) => {
								return (
									this.services.trading.itemInStore(p, this.profile?.customer?.currentSelection?.store) &&
									(!currentOrderSalesTag || this.services.trading.itemCanSell(p, currentOrderSalesTag)) &&
									p.departments.length
								)
							})
							.map((prod) => {
								return {
									...prod,
									available:
										this.services.trading.itemIsAvailable(prod, availabilityCheckTime) &&
										this.services.trading.itemCanSell(prod, currentOrderSalesTag),
									currentPrice: this.services.data.getProductPrice(
										prod,
										this.profile.customer.currentSelection?.orderingMethod?.pricingOption
									),
									minPrice: prod.posConfig.selectedPrepMessages
										.filter(({ minimumNumOfCalls }) => !!minimumNumOfCalls)
										.reduce((prepTotal, pm) => {
											if (pm.messageProducts.length) {
												prepTotal += Math.min(
													...pm.messageProducts.map((mp) => {
														return (
															(mp.useProductPrice
																? this.services.data.getProductPriceById(
																		mp.productId,
																		this.profile.customer.currentSelection?.orderingMethod?.pricingOption
																  )
																: mp.price) || 0
														)
													})
												)
											}

											return prepTotal
										}, 0),
								}
							})
					})
				)
				.subscribe()
		} else {
			this.products = []
		}
	}

	async selectProduct(product) {
		await this.modalController.dismiss(product)
	}

	async closeModal() {
		await this.modalController.dismiss()
	}

	@ViewChildren('image') images: QueryList<ElementRef>
	imageError(index: number) {
		const el = this.images.toArray()[index]
		el.nativeElement.hidden = true
	}
}
