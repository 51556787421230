import { Order, PaymentMethod, PaymentProviders, Customer, LoyaltyBasket } from '@golo/models/lib/interfaces'
import { LoadingController } from '@ionic/angular'
import { Observable, of } from 'rxjs'
import { concatMap, map, tap } from 'rxjs/operators'
import { PaymentoptionsPage } from 'src/app/pages/paymentoptions/paymentoptions.page'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'
import { AbstractPaymentGatewayService } from 'src/app/payment-gateways/public-api'
import { HttpClient } from '@angular/common/http'
import { get } from 'lodash'

export class FnbPaymentGatewayService extends AbstractPaymentGatewayService {
	private logger = LoggerFactory.getLogger(this)
	constructor(public services: FacadeService, public loadingController: LoadingController, public http: HttpClient) {
		super(services, loadingController, http)
		this.typeKey = PaymentProviders.FNB
	}

	prep(orderDetails: [Order, LoyaltyBasket], customer: Customer, method: PaymentMethod) {
		const [order, rewards] = orderDetails
		this.logger.debug('prep -> method', method)
		//callback has no data on it client side is the only place the txnToken is stored and status can queried from
		const successURL = `${this.services.env.get().api}/payment-gateway/callback/success/${PaymentProviders.FNB}`
		const failureURL = `${this.services.env.get().api}/payment-gateway/callback/failure/${PaymentProviders.FNB}`
		this.logger.debug(`prep -> successURL`, successURL, `, failureURL: `, failureURL)
		const orderAmount = this.services.cart.getOrderTotal()
		const store = get(customer, 'currentSelection.store._id')
		const payload = {
			store,
			description: '',
			amount: orderAmount * 100,
			merchantOrderNumber: order.header.orderNo,
			successURL,
			failureURL,
		}
		return this.services.toast
			.presentLoading({
				cssClass: 'loader',
				message: 'Loading Payment Provider',
				showBackdrop: true,
			})
			.pipe(
				concatMap(() => super.prepare({ payload, orderDetails })),
				map((fnb: any) => {
					this.logger.debug('prep -> fnb', fnb)
					if (fnb.txnToken) {
						order.payment.value = orderAmount
						order.payment.txToken = fnb.txnToken
					} else {
						this.services.toast.toast(
							{ text: 'Error loading payment provider', position: 'top' },
							{
								header: 'Payment Status',
								buttons: [
									{
										text: 'ok',
										role: 'cancel',
									},
								],
							}
						)
					}
					return fnb
				}),
				tap(() => {
					this.services.toast.dismissLoading()
				})
			)
	}

	redirectBack(
		page: PaymentoptionsPage,
		orderDetails?: [Order, LoyaltyBasket],
		response?: {
			success: boolean
			reason?: string
			meta?: string
		}
	): Observable<[Order, LoyaltyBasket] | null> {
		this.logger.debug(`redirectBack -> response`, response)
		const [order, rewards] = orderDetails
		//recreate form state before redirect
		page.setPaymentMethod(order.payment.description)
		this.services.cart.updatePaymentDetails({ serviceFee: order.payment.serviceFee })
		if (response.success) {
			return of([order, rewards])
		} else {
			return of(null)
		}
	}

	redirect(orderDetails: [Order, LoyaltyBasket], options: any) {
		window.open(options.url, '_self')
	}
}
