import { Injectable } from '@angular/core';
import { Location } from '@golo/models/lib/interfaces';
import geolocator from 'geolocator';
import { get } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoggerFactory } from '../helpers/logger-factory.class';
import { ApiService } from '../support/api.service';
import { EnvService } from '../support/env.service';

export enum GeoType {
	PRECISE = 'PRECISE',
	APPROXIMATE = 'APPROXIMATE',
}
export interface GeocodeLocation {
	type: GeoType;
	location: Location;
}

export enum GeoPermissionState {
	PROMPT = 'prompt',
	GRANTED = 'granted',
	DENIED = 'denied',
}

@Injectable({
	providedIn: 'root',
})
export class GeolocatorService {
	private logger = LoggerFactory.getLogger(this);
	mapTarget: Partial<Location>;
	constructor(private env: EnvService, private api: ApiService) {
		geolocator.config({
			language: 'en',
			google: {
				version: '3',
				key: this.env.get().googleApiKey,
			},
		});
	}

	getGeoLocationPermissionStatus(): Observable<GeoPermissionState> {
		if (!this.api.offline) {
			return new Observable((o) => {
				navigator.geolocation.getCurrentPosition(
					() => {
						o.next(GeoPermissionState.GRANTED);
						o.complete();
					},
					() => {
						o.next(GeoPermissionState.DENIED);
						o.complete();
					}
				);
			});
		} else {
			this.logger.debug('getGeoLocationPermissionStatus -> offline', this.api.offline, ', assuming DENIED');
			return of(GeoPermissionState.DENIED);
		}
	}

	clearCached() {
		this.cached = null;
	}

	cached: GeocodeLocation;
	getGeoLocation(): Observable<GeocodeLocation> {
		if (this.cached) {
			this.logger.debug(`getGeoLocation -> this.cached`, this.cached);
			return of(this.cached);
		} else {
			return new Observable((o) => {
				geolocator.locate(
					{
						enableHighAccuracy: true,
						timeout: 3000,
						maximumWait: 2000,
						maximumAge: 0,
						desiredAccuracy: 30,
						fallbackToIP: true,
						addressLookup: true,
					},
					(err, geo) => {
						if (err) {
							console.error(`getGeoLocation -> err`, err);
							o.error(err);
						} else {
							const code = this.transform(geo);
							this.cached = code;
							this.logger.debug(`this.cached (new)`, this.cached);
							o.next(code);
							o.complete();
						}
					}
				);
			});
		}
	}

	isWithinRange(selected: Partial<Location>, range = 7.5): Observable<[boolean, GeocodeLocation]> {
		this.logger.debug('isWithinRange -> selected', selected);
		return this.getGeoLocation().pipe(
			map((geo: GeocodeLocation) => {
				this.logger.debug('map -> geo', geo);
				let distance;
				if (geo && selected) {
					distance = this.getDistance(get(geo, 'location.latitude'), get(geo, 'location.longitude'), selected.latitude, selected.longitude) <= Number(range);
				} else if (selected) {
					distance = true; //assume true if no geo
				} else {
					console.error('selected is null');
				}

				this.logger.debug('isWithinRange -> distance', distance);
				return [distance, geo];
			})
		);
	}

	isSelectedWithinDeliveryRadius(selectedOrig: Partial<Location>, selectedDest: Partial<Location>, range = 5.0) {
		return this.getDeliveryRadius(selectedOrig, selectedDest) <= Number(range);
	}

	getDeliveryRadius(selectedOrig: Partial<Location>, selectedDest: Partial<Location>) {
		const distance = this.getDistance(selectedOrig.latitude, selectedOrig.longitude, selectedDest.latitude, selectedDest.longitude);
		this.logger.debug('getDeliveryRadius -> distance', distance);
		return distance;
	}

	getDistance(lat, long, toLat, toLong) {
		return geolocator.calcDistance({
			from: {
				latitude: lat,
				longitude: long,
			},
			to: {
				latitude: toLat,
				longitude: toLong,
			},
			formula: geolocator.DistanceFormula.HAVERSINE,
			unitSystem: geolocator.UnitSystem.METRIC,
		});
	}

	private transform(geo: any): GeocodeLocation {
		if (geo.type === 'ROOFTOP') {
			return {
				type: GeoType.PRECISE,
				location: {
					...geo.coords,
					nickName: '_current',
					address: geo.formattedAddress,
					addressDetails: geo.address,
				},
			};
		} else {
			return {
				type: GeoType.APPROXIMATE,
				location: {
					...geo.coords,
					nickName: '_approximate',
					address: geo.formattedAddress,
					addressDetails: geo.address,
				},
			};
		}
	}
}
