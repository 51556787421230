import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { DestTypes } from '@golo/models/lib/interfaces'
import { pick, hasIn, set, get } from 'lodash'
import { GeocodeLocation, GeoType, LoggerFactory, FacadeService } from 'src/app/services/public-api'
import { concatMap, map } from 'rxjs/operators'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
	address: string
	addressDetails: any
	nickName: string
	complex: string
	lastnickName: string
	currentCoords: {
		latitude?: string
		longitude?: string
	}
	private logger = LoggerFactory.getLogger(this)
	constructor(
		private modalController: ModalController,
		public services: FacadeService,
		private profile: CustomerProfileService
	) {}

	ngOnInit() {
		this.unstashFormData()
		this.profile
			.watch()
			.pipe(
				concatMap(() => {
					return this.services.geo.getGeoLocation()
				}),
				map((geo: GeocodeLocation) => {
					if (geo && !this.services.geo.mapTarget) {
						this.address = get(geo, 'location.type') === GeoType.PRECISE && get(geo, 'location.address')
						this.currentCoords = pick(geo.location, ['latitude', 'longitude'])
						this.addressDetails = get(geo, 'location.addressDetails')
					}
				})
			)
			.subscribe()
	}

	clear() {
		this.address = null
		this.nickName = null
		this.complex = null
		this.services.geo.mapTarget = null
		this.currentCoords = null
	}

	async presentValidationCheckToast() {
		this.services.toast.toast({ text: `Please complete required fields` })
	}

	async closeModal() {
		await this.modalController.dismiss()
	}

	async back() {
		if (this.address && this.nickName) {
			const newLocation = <any>{
				...(this.services.geo.mapTarget || this.currentCoords),
				address: this.address,
				nickName: this.nickName,
				complex: this.complex,
			}
			this.profile.addLocation(newLocation)
			newLocation.meta.type === 'APPROXIMATE' &&
				this.profile.update({ currentSelection: { destinationType: DestTypes.COLLECT } })
			await this.modalController.dismiss({ nav: 'OptionsComponent' })
		} else {
			this.presentValidationCheckToast()
		}
	}

	async map() {
		this.stashFormData()
		await this.modalController.dismiss({ nav: 'MapComponent' })
	}

	private stashFormData() {
		if (this.nickName) {
			set(this.services.geo, 'mapTarget.nickName', this.nickName)
		}
		if (this.complex) {
			set(this.services.geo, 'mapTarget.complex', this.complex)
		}
		if (this.address) {
			set(this.services.geo, 'mapTarget.address', this.address)
		}
	}

	private unstashFormData() {
		if (hasIn(this.services.geo, 'mapTarget.nickName')) {
			this.nickName = this.services.geo.mapTarget.nickName
		}
		if (hasIn(this.services.geo, 'mapTarget.complex')) {
			this.complex = this.services.geo.mapTarget.complex
		}
		if (hasIn(this.services.geo, 'mapTarget.address')) {
			this.address = this.services.geo.mapTarget.address
		}
	}
}
