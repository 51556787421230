import { Component, OnInit } from '@angular/core'
import { LoggerFactory } from 'src/app/services/public-api'
import { ModalController } from '@ionic/angular'

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
	private logger = LoggerFactory.getLogger(this)
	constructor(private modalController: ModalController) {}

	public faq: any
	addons: any[]

	ngOnInit() {}

	async closeModal() {
		await this.modalController.dismiss()
	}
}
