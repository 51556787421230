import { Component, OnInit } from '@angular/core'
import { CartService, LoggerFactory } from 'src/app/services/public-api'

@Component({
	selector: 'app-cartcomponent',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
	private logger = LoggerFactory.getLogger(this)
	constructor(private cartService: CartService) {}

	public cartItems: any[] = []
	public itemCount: number

	ngOnInit() {
		this.cartService.watchItems().subscribe((items) => {
			this.cartItems = items
			this.itemCount = this.cartService.getCartItemCount()
		})
		if (this.cartItems.length) {
		}
	}
}
