import { SharedPipes } from 'src/app/pipes/shared-pipes'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { HeaderComponent } from './header/header.component'
import { IonicModule } from '@ionic/angular'
import { FooterComponent } from './footer/footer.component'
import { OptionsComponent } from './options/options/options.component'
import { MapComponent } from './options/map/map.component'
import { AddressComponent } from './options/address/address.component'
import { ProductComponent } from './product/product.component'
import { FaqComponent } from './faq/faq.component'
import { PrepMessageSingleSelectComponent } from './prep-message-single-select/prep-message-single-select.component'
import { PrepMessageMultiSelectComponent } from './prep-message-multi-select/prep-message-multi-select.component'
import { MultiselectpopoverComponent } from './prep-message-multi-select/multiselectpopover/multiselectpopover.component'
import { CartComponent } from './cart/cart.component'
import { ProductSearchComponent } from './productsearch/productsearch.component'
import { ScheduleDaytimeSelectionComponent } from './options/options/schedule-daytime-selection/schedule-daytime-selection.component'
import { OptiondetailsComponent } from './optiondetails/optiondetails.component'
import { OrdertimepickerComponent } from './ordertimepicker/ordertimepicker.component'
import { CartfooterComponent } from './cartfooter/cartfooter.component'
import { StorefilterComponent } from './storefilter/storefilter.component'
import { StorefilterpopoverComponent } from './storefilter/storefilterpopover/storefilterpopover.component'
import { RewardComponent } from './reward/reward.component'
import { QRCodeVoucherComponent } from './qrcode-voucher/qrcode-voucher.component'
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode'
import { PromosliderComponent } from './promoslider/promoslider.component'
import { EarnCodeComponent } from './earn-code/earn-code.component'
import { FeedbackRatingComponent } from './feedback-rating/feedback-rating.component'
import { FeedbackComponent } from './feedback/feedback.component'
import { FeedbackQuestionComponent } from './feedback-question/feedback-question.component'
import { FeedbackInputComponent } from './feedback-input/feedback-input.component'
import { QrscannerComponent } from './qrscanner/qrscanner.component'

const components = [
	HeaderComponent,
	FooterComponent,
	OptionsComponent,
	AddressComponent,
	MapComponent,
	ProductComponent,
	FaqComponent,
	PrepMessageSingleSelectComponent,
	PrepMessageMultiSelectComponent,
	MultiselectpopoverComponent,
	CartComponent,
	ProductSearchComponent,
	ScheduleDaytimeSelectionComponent,
	OptiondetailsComponent,
	OrdertimepickerComponent,
	CartfooterComponent,
	StorefilterComponent,
	StorefilterpopoverComponent,
	RewardComponent,
	QRCodeVoucherComponent,
	PromosliderComponent,
	EarnCodeComponent,
	FeedbackRatingComponent,
	FeedbackComponent,
	FeedbackQuestionComponent,
	FeedbackInputComponent,
	QrscannerComponent,
]

@NgModule({
	declarations: components,
	exports: components,
	imports: [CommonModule, FormsModule, IonicModule, ReactiveFormsModule, FormsModule, SharedPipes, NgxQRCodeModule],
})
export class ComponentsModule {}
