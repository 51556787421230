<ion-header [translucent]="true">
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-menu-button></ion-menu-button>
		</ion-buttons>
		<ion-title class="title">{{ appTitle }}</ion-title>

		<ion-buttons slot="end">
			<ion-button aria-label="Offline">
				<ion-icon *ngIf="services.api.offline$ | async" name="cloud-offline-outline"></ion-icon>
			</ion-button>
			<ion-button *ngIf="!!lastOrderNo && showLastOrder" (click)="lastOrderView()" aria-label="Show last order">
				<ion-icon name="arrow-redo-outline"></ion-icon>
			</ion-button>
			<ion-button id="note-btn" (click)="notifications()" aria-label="Show notifications">
				<ion-icon name="notifications-outline"></ion-icon>
				<ion-badge *ngIf="unreadNotes > 0" id="note-badge" slot="end" color="danger">{{ unreadNotes }}</ion-badge>
			</ion-button>
			<ion-button *ngIf="eatInEnabled" slot="icon-only" (click)="startScan()">
				<ion-icon name="qr-code-outline" title="QR-Scanner"></ion-icon>
			</ion-button>
			<ion-button *ngIf="showSearch" (click)="searchFunc()" aria-label="Search">
				<ion-icon name="search-outline"></ion-icon>
			</ion-button>
			<ion-button *ngIf="currentRoute === '/about'" (click)="refresh()" aria-label="Refresh">
				<ion-icon slot="icon-only" name="refresh"></ion-icon>
			</ion-button>
			<app-cartcomponent *ngIf="cartItems.length" (click)="cart()" slot="end"></app-cartcomponent>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
