import { Injectable } from '@angular/core'
import { CartService } from './feature/cart.service'
import { TradingService } from './feature/trading.service'
import { PwaUpdateService } from './feature/pwa-update.service'
import { Storage } from '@ionic/storage'
import { ToasterService } from './support/toaster.service'
import { versions, Version } from './helpers/legal'
import { EnvService } from './support/env.service'
import { ApiService } from './support/api.service'
import { DataSourceService } from './support/data-source.service'
import { GeolocatorService } from './feature/geolocator.service'
import { AppNotificationsService } from './feature/app-notifications.service'
import { SocketService } from './support/socket.service'
import { PwaInstallService } from './feature/pwa-install.service'

@Injectable({
	providedIn: 'root',
})
export class FacadeService {
	public versions: Version[] = versions
	constructor(
		public api: ApiService,
		public cart: CartService,
		public data: DataSourceService,
		public geo: GeolocatorService,
		public pwa: PwaUpdateService,
		public storage: Storage,
		public toast: ToasterService,
		public trading: TradingService,
		public env: EnvService,
		public appNote: AppNotificationsService,
		public sock: SocketService,
		public pwaInstall: PwaInstallService
	) {}
}
