import { InjectionToken, NgModule, ModuleWithProviders } from "@angular/core"

export const ENV = new InjectionToken<any>(
	'ENV'
)

@NgModule()
export class ServicesModule {
	static forRoot(enviro: any): ModuleWithProviders<ServicesModule> {
		return {
			ngModule: ServicesModule,
			providers: [{ provide: ENV, useValue: enviro }],
		}
	}
}