import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ComponentsModule } from './components/components.module'
import { initApp } from './init-app'
import { FirebaseuiComponent } from './login/firebaseui/firebaseui.component'
import { LoginComponent } from './login/login.component'
import { SharedPipes } from './pipes/shared-pipes'
import { ApiService, DataSourceService, EnvService, ServicesModule } from 'src/app/services/public-api'
import { HttpInterceptorService } from 'src/app/customer-profile/public-api'
import { PaymentGatewaysModule } from 'src/app/payment-gateways/public-api'
import { FnbPaymentGatewayService as FnbPaymentGateway } from './services/payment-gateway-impl/fnb-payment-gateway.class'
import { AdumoonlinePaymentGatewayService as AdumoonlinePaymentGateway } from './services/payment-gateway-impl/adumoonline-payment-gateway.class'

@NgModule({
	declarations: [AppComponent, LoginComponent, FirebaseuiComponent],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		BrowserModule,
		IonicModule.forRoot({
			rippleEffect: true,
			mode: 'md',
			scrollPadding: true,
			scrollAssist: true,
			animated: false,
		}),
		AppRoutingModule,
		IonicStorageModule.forRoot({
			name: '__golodb',
			driverOrder: ['indexeddb', 'localstorage'],
		}),
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAuthModule,
		ComponentsModule,
		SharedPipes,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			registrationStrategy: 'registerImmediately',
		}),
		HttpClientModule,
		ServicesModule.forRoot(environment),
		PaymentGatewaysModule.forRoot({
			fnb: FnbPaymentGateway,
			adumoonline: AdumoonlinePaymentGateway,
		}),
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
		{
			provide: APP_INITIALIZER,
			useFactory: initApp,
			deps: [ApiService, EnvService, DataSourceService, HttpClient],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
