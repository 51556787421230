import { Injectable } from '@angular/core'
import { AppNotification, Customer } from '@golo/models/lib/interfaces'
import { get, orderBy } from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { Storage } from '@ionic/storage'
import { SocketService } from '../support/socket.service'
import { LoggerFactory } from '../helpers/logger-factory.class'

@Injectable({
	providedIn: 'root',
})
export class AppNotificationsService {
	private logger = LoggerFactory.getLogger(this)
	notes$ = new BehaviorSubject<AppNotification[]>([])
	private customer: Customer
	constructor(private storage: Storage, private sock: SocketService) {
		this.loadAppNotes()
		this.sock.socket.on('app-push', (msg) => {
			this.saveAppNote(msg)
			this.sock.socket.emit('app-push-ack', {
				customerId: get(this.customer, '_id'),
				msg,
			})
		})
	}

	setCustomer(customer: Customer) {
		this.customer = customer
		this.logger.debug('Register customer socket: ', `customer_${customer._id}`)
		this.sock.socket.on(`customer_${customer._id}`, (msg) => {
			this.saveAppNote(msg)
		})
	}

	markReadAppNote(note: AppNotification) {
		note.read = true
		this.updateAppNote(note)
	}

	async saveAppNote(note: AppNotification) {
		const notesStr = await this.storage.get('app-push-notes')
		const notes = (notesStr && JSON.parse(notesStr)) || []
		notes.unshift(note)
		notes.length > 100 && (notes.length = 100)
		this.storage.set('app-push-notes', JSON.stringify(notes))
		this.notes$.next(notes)
	}

	async updateAppNote(note: AppNotification) {
		const notesStr = await this.storage.get('app-push-notes')
		const notes = (notesStr && JSON.parse(notesStr)) || []
		let rest = []
		if (notes.length > 1) {
			rest = notes.filter((n: AppNotification) => [n.created, n.title].join('') !== [note.created, note.title].join(''))
		}
		rest.push(note)
		const ordered = orderBy(rest, ['created'], ['desc'])
		this.storage.set('app-push-notes', JSON.stringify(ordered))
		this.notes$.next(ordered)
	}

	async loadAppNotes() {
		const notesStr = await this.storage.get('app-push-notes')
		const notes = JSON.parse(notesStr)
		this.notes$.next(notes)
	}
}
