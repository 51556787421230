import { Injectable } from '@angular/core'
import { LoggerFactory } from 'src/app/services/public-api'
import { ModalController } from '@ionic/angular'
import { AddressComponent } from 'src/app/components/options/address/address.component'
import { MapComponent } from 'src/app/components/options/map/map.component'
import { OptionsComponent } from 'src/app/components/options/options/options.component'
import { LoginComponent } from 'src/app/login/login.component'

export const SEQ_DIALOG_DICT = {
	OptionsComponent: OptionsComponent,
	AddressComponent: AddressComponent,
	MapComponent: MapComponent,
}

@Injectable({
	providedIn: 'root',
})
export class SeqModalControllerService {
	optionsSeqCloseOn: string
	private logger = LoggerFactory.getLogger(this)
	constructor(private modalController: ModalController) {}

	async openOptionsModalSeq(component?: string, seqCloseOn?: string, onDidDismissFunc?: () => void) {
		seqCloseOn && (this.optionsSeqCloseOn = seqCloseOn)
		let compFac = component && SEQ_DIALOG_DICT[component]
		const modal = await this.modalController.create({
			cssClass: 'golo-main-modal',
			component: compFac || OptionsComponent,
		})
		modal.onWillDismiss().then((results: any) => {
			if (results.data && results.data.nav && results.data.nav !== this.optionsSeqCloseOn) {
				this.openOptionsModalSeq(results.data.nav)
			}
			onDidDismissFunc && onDidDismissFunc()
		})
		return await modal.present()
	}

	loginModal: HTMLIonModalElement
	async openLoginModal() {
		this.loginModal = await this.modalController.create({
			component: LoginComponent,
			cssClass: 'golo-main-modal',
		})
		this.loginModal.onWillDismiss().then((results: any) => {})
		return await this.loginModal.present()
	}

	closeLoginModal() {
		this.loginModal && this.loginModal.dismiss()
	}
}
