import { Injectable } from '@angular/core'
import { SwUpdate } from '@angular/service-worker'
import { isArray } from 'lodash'
import { interval } from 'rxjs'
import { LoggerFactory } from '../helpers/logger-factory.class'
import { ApiService } from '../support/api.service'
import { SocketService } from '../support/socket.service'
import { ToasterService } from '../support/toaster.service'

@Injectable({
	providedIn: 'root',
})
export class PwaUpdateService {
	private logger = LoggerFactory.getLogger(this)
	constructor(
		private swUpdate: SwUpdate,
		private toast: ToasterService,
		private soc: SocketService,
		private api: ApiService
	) {
		this.swUpdate.available.subscribe((evt) => {
			this.logger.debug('PwaUpdateService -> evt', evt)
			this.refreshTheme()
		})
		this.soc.socket.on('reload', (msg) => {
			this.logger.debug('PwaUpdateService -> socket.on(reload) -> msg', msg)
			if (isArray(msg) && msg.includes('settings')) {
				this.refreshTheme(true)
			}
		})
	}

	pollUpdateCheck() {
		if (this.swUpdate.isEnabled) {
			interval(30000).subscribe(() => this.swUpdate.checkForUpdate())
		} else {
			console.warn('PwaUpdateService -> this.swUpdate.isEnabled: ', this.swUpdate.isEnabled)
		}
	}

	async refreshTheme(theme = false) {
		if (!theme) {
			this.swUpdate.activateUpdate().then(() => window.location.replace(`${window.location.origin}/home`))
		} else {
			localStorage.setItem('theme_available', 'true')
		}
	}
}
