import { Injectable } from '@angular/core'
import { Observable, of, Subject, merge, from } from 'rxjs'
import { concatMap, filter, map } from 'rxjs/operators'
import {
	Store,
	Customer,
	Location,
	RecursivePartial,
	StoreStatus,
	WhenTypes,
	DestTypes,
} from '@golo/models/lib/interfaces'
import { defaultsDeep, omit, isEmpty, defaults, get, first, find, uniqBy, isString } from 'lodash'
import { Storage } from '@ionic/storage'
import { AngularFireAuth } from '@angular/fire/auth'
import { ApiService, KioskService, LoggerFactory } from 'src/app/services/public-api'

export interface UserProfileUpdateOptions {
	initStore?: boolean
}
@Injectable({
	providedIn: 'root',
})
export class CustomerProfileService {
	private logger = LoggerFactory.getLogger(this)
	table: string
	get isLoggedIn() {
		return !!this.fireUser
	}
	get hasPhoneNumber() {
		return !!(this.customer && this.customer.details && this.customer.details.phoneNumber)
	}
	fireUser: firebase.User
	customer: Customer = {
		_id: null,
		locations: [],
		currentSelection: {
			destinationType: null,
			when: WhenTypes.ASAP,
		},
		details: null,
	}
	customerChange$ = new Subject<Customer>()
	currentStoreChange$ = new Subject<Store>()

	loggedInStatus$ = new Subject<boolean>() // needed this we are posting customer updates to the api via the update method, can't set details: null
	constructor(
		public kioskService: KioskService,
		private storage: Storage,
		public auth: AngularFireAuth,
		private api: ApiService
	) {
		this.loadLocalData().subscribe()
		this.auth.authState
			.pipe(
				map((fireUser: firebase.User) => {
					this.fireUser = fireUser
					this.updateUserDetailsFromFireUser(fireUser).subscribe()
				})
			)
			.subscribe()
	}

	loadLocalData(): Observable<void> {
		if (this.customer._id === null) {
			this.customerChange$
				.asObservable()
				.pipe(
					filter((e) => !!e),
					map((e) => {
						this.storage.set('user', JSON.stringify(omit(e, 'details')))
					})
				)
				.subscribe()
			return from(this.storage.get('user')).pipe(
				map((lu) => {
					if (lu) {
						this.update(JSON.parse(lu))
					}
				})
			)
		} else {
			return of()
		}
	}

	update(partial: RecursivePartial<Customer>, updateOptions?: UserProfileUpdateOptions) {
		if (updateOptions && updateOptions.initStore && partial.currentSelection.store) {
			this.customer.currentSelection.store = partial.currentSelection.store as Store
			this.currentStoreChange$.next(this.customer.currentSelection.store)
		} else {
			this.customer = defaultsDeep(partial, this.customer)
			this.customer.locations = uniqBy(this.customer.locations, 'nickName') //localstorage locations can cause duplications when merged with api customer data
		}
		this.customerChange$.next(this.customer)
		if ((!isEmpty(this.customer.details) || this.customer.locations.length) && this.isLoggedIn) {
			this.api.upsertCustomer(this.customer).subscribe()
		}
	}

	savePhoneNumber(phoneNumber: string) {
		this.update({ details: { phoneNumber } })
	}

	saveNotes(notes: string) {
		this.customer.currentSelection.notes = notes
	}

	addLocation(location: Location) {
		this.customer.locations = this.customer.locations.filter((l) => l.nickName !== location.nickName)
		this.customer.locations.push(location)
		this.customer.currentSelection.location = location
		this.update(this.customer)
	}
	removeLocation(nickName: string) {
		this.customer.locations = this.customer.locations.filter((l) => l.nickName !== nickName)
		if (nickName === get(this.customer, 'currentSelection.location.nickName')) {
			this.customer.currentSelection.location =
				find(this.customer.locations, ['meta.type', 'PRECISE']) || first(this.customer.locations)
		}
		this.update(this.customer)
	}
	watch(): Observable<Customer> {
		return merge(of(this.customer), this.customerChange$.asObservable()).pipe(filter((e: Customer) => !!e))
	}
	logout() {
		return this.auth.signOut()
	}

	private updateUserDetailsFromFireUser(fireUser: firebase.User): Observable<void> {
		if (fireUser) {
			return from(fireUser.getIdToken()).pipe(
				concatMap((idToken) => {
					sessionStorage.setItem('token', idToken)
					// fireUser property values will overwrite customer.details unless they are null
					fireUser = JSON.parse(JSON.stringify(fireUser), (k, v) => (v === null ? undefined : v))
					this.customer.details = defaults(fireUser, this.customer.details)
					this.customer._id = this.fireUser.uid
					return this.api.getCustomer()
				}),
				map((cust: Customer) => {
					!cust.locations && (cust.locations = [])
					delete (<any>cust).error
					this.update(cust)
					this.loggedInStatus$.next(true)
				})
			)
		} else {
			sessionStorage.removeItem('token')
			this.loggedInStatus$.next(false)
			return of()
		}
	}

	public refreshToken(): Observable<string | null> {
		if (this.fireUser) {
			return from(this.fireUser.getIdToken(true)).pipe(
				map((idToken) => {
					sessionStorage.setItem('token', idToken)
					return idToken
				})
			)
		} else {
			return of(null)
		}
	}

	public resetCurrentSelection() {
		this.update({
			currentSelection: {
				when: 'asap',
				scheduleDateTime: '' as any,
			},
		})
	}

	public parseSelectedStoreLocation(): Partial<Location> {
		const coordStr = get(this.customer, 'currentSelection.store.coords')
		if (isString(coordStr) && coordStr.indexOf(',') > -1) {
			const coords = coordStr.split(',')
			return {
				latitude: coords[0],
				longitude: coords[1],
			}
		} else {
			throw new Error(`store.coords is ${coordStr}`)
		}
	}

	updateCurrentStore(store: Store) {
		this.logger.debug('Current Store', this.customer.currentSelection.store)
		if (store) {
			this.customer.currentSelection.store = store
			this.customer.currentSelection.orderingMethod = store.orderingMethods?.find(
				(oo) => oo.name === get(this.customer, 'currentSelection.orderingMethod.name')
			)
			this.currentStoreChange$.next(store)
			this.setOrderingMethods(store)
			this.storage.set('user', JSON.stringify(omit(this.customer, 'details')))
			this.logger.debug('Current Customer: ', this.customer.currentSelection)
		}
	}

	setOrderingMethods(s: Store) {
		this.table = this.kioskService.getKioskTableNo()
		if (this.table && s.orderingMethods) {
			this.customer.currentSelection.orderingMethod = s.orderingMethods.find((x) => x.name === 'Eat In')
		}
		if (this.customer.currentSelection.orderingMethod === undefined) {
			this.customer.currentSelection.orderingMethod = this.customer.currentSelection?.store.orderingMethods[0]
		}
	}

	updateCurrentStoreStatus(status: Partial<StoreStatus>) {
		this.customer.currentSelection.store.status = defaultsDeep(status, this.customer.currentSelection.store.status)
		this.currentStoreChange$.next(this.customer.currentSelection.store)
	}

	watchCurrentStore(): Observable<Store> {
		return merge(of(this.customer.currentSelection.store), this.currentStoreChange$.asObservable()).pipe(
			filter((store) => !!store)
		)
	}
}
