import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'distance',
})
export class DistancePipe implements PipeTransform {
	transform(value: number): string {
		return value < 1 ? `${(value * 1000).toFixed(0)} m` : `${value.toFixed(1)} km`
	}
}
