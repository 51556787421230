<ion-list class="list-background">
	<ion-list-header lines="full">Store Descriptors</ion-list-header>
	<ion-item *ngFor="let tag of tags">
		<ion-label>{{ tag.name }}</ion-label>
		<ion-toggle [(ngModel)]="tag.selected"></ion-toggle>
	</ion-item>
	<ion-list-header lines="full">Ordering Methods</ion-list-header>
	<ion-item *ngFor="let method of orderMethods">
		<ion-label>{{ method.salesOrderTag.name }}</ion-label>
		<ion-toggle [(ngModel)]="method.selected"></ion-toggle>
	</ion-item>
</ion-list>
<ion-toolbar
	><ion-buttons slot="end"
		><ion-button (click)="closePopover()" aria-label="Apply">Apply</ion-button></ion-buttons
	></ion-toolbar
>
