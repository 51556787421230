import { Component } from '@angular/core'
import { LoggerFactory } from 'src/app/services/public-api'
import { ModalController } from '@ionic/angular'

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
	private logger = LoggerFactory.getLogger(this)
	constructor(private modalController: ModalController) {}

	async closeModal() {
		await this.modalController.dismiss()
	}

	successCallback($event) {}

	errorCallback($event) {}
}
