import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import {
	CurrentSelection,
	Customer,
	DeliveryCharge,
	Order,
	Reward,
	TransientOrderLine,
	Location,
	LoyaltyBasket,
} from '@golo/models/lib/interfaces'
import { Storage } from '@ionic/storage'
import dayjs from 'dayjs'
import { get, isString, minBy, merge } from 'lodash'
import { BehaviorSubject, from, Observable, Subject } from 'rxjs'
import { concatMap, map } from 'rxjs/operators'
import { adjectives, foods } from '../randomnames'
import { uniqueNamesGenerator } from 'unique-names-generator'
import { v4 as uuidv4 } from 'uuid'
import { ApiService } from '../support/api.service'
import { DataSourceService } from '../support/data-source.service'
import { EnvService } from '../support/env.service'
import { ToasterService } from '../support/toaster.service'
import { GeolocatorService } from './geolocator.service'
import { TradingService } from './trading.service'
import { LoggerFactory } from '../helpers/logger-factory.class'
import { KioskService } from './kiosk.service'

export interface PaymentDetails {
	cartTotal: number
	description: string
	value: number
	serviceFee: number
	deliveryFee: number
	rewardTotal: number
	rewards?: any[]
}

@Injectable({
	providedIn: 'root',
})
export class CartService {
	private items: TransientOrderLine[] = []
	private currentOrderNumber: string = ''
	private paymentDetails: PaymentDetails = {
		cartTotal: 0,
		description: 'cod',
		value: 0,
		serviceFee: 0,
		deliveryFee: 0,
		rewardTotal: 0,
		rewards: [],
	}
	private paymentDetailsSubject = new BehaviorSubject<PaymentDetails>(this.paymentDetails)
	private itemsSubject = new BehaviorSubject([])
	private logger = LoggerFactory.getLogger(this)
	public profileResetCurrentSelection$ = new Subject()
	displayOrderNumber: string
	constructor(
		private storage: Storage,
		private geo: GeolocatorService,
		private api: ApiService,
		private data: DataSourceService,
		private toast: ToasterService,
		private router: Router,
		private trading: TradingService,
		public kioskService: KioskService,
		private env: EnvService
	) {
		from(this.storage.get('cart'))
			.pipe(
				map((cart: any) => {
					if (cart != null) {
						const loadedCart = JSON.parse(cart).filter((ci) => {
							return ci.addedTime && Math.abs(dayjs().diff(ci.addedTime, 'minute')) < 1440
						})
						this.itemsSubject.next([...this.items, ...loadedCart])
					}
				})
			)
			.subscribe()

		from(this.storage.get('currentOrderNumber'))
			.pipe(
				map((ono: string) => {
					if (!ono) {
						ono = this.getNewOrderNumber()
					}
					this.currentOrderNumber = ono
					return ono
				})
			)
			.subscribe()

		this.watchItems().subscribe((items) => {
			this.items = items
			this.storage.set('cart', JSON.stringify(items))
			if (!this.items.length) {
				this.currentOrderNumber = ''
				this.storage.set('currentOrderNumber', '')
			}
		})
	}

	updatePaymentDetails(details: Partial<PaymentDetails>) {
		this.paymentDetails = { ...this.paymentDetails, ...details }
		this.paymentDetailsSubject.next(this.paymentDetails)
	}

	watchPaymentDetails() {
		return this.paymentDetailsSubject.asObservable()
	}

	watchItems(): Observable<TransientOrderLine[]> {
		return this.itemsSubject.asObservable()
	}

	addToCart(item: TransientOrderLine) {
		item.addedTime = new Date()
		if (!item.id) {
			item.id = uuidv4()
			this.itemsSubject.next([...this.items, item])
		} else {
			this.items.forEach((cartItem) => (cartItem.id === item.id ? item : cartItem))
			this.itemsSubject.next(this.items)
		}
		if (!this.currentOrderNumber) {
			this.currentOrderNumber = this.getNewOrderNumber()
		}
	}

	calculateLineTotal(line) {
		const price = line.price
		const addOnPrice = (line.prepMessages || []).reduce((prepTotals, prep) => {
			return prepTotals + prep.addOnPrice
		}, 0)
		line.lineTotal = line.qty * (price + addOnPrice)
		return line.lineTotal
	}

	setAddOns(line, pricingOption?) {
		line.addOns = (line.prepMessages || [])
			.reduce((preps, prep) => {
				let internalOrdinal = 0
				preps.push(
					...(prep.selectedPreps || []).map((p) => {
						internalOrdinal += 1
						if (pricingOption && p.useProductPrice) {
							p.price = this.data.getProductPriceById(p.productId, pricingOption)
						}
						return {
							displayName: p.displayName,
							id: p.id,
							productId: p.productId,
							productCode: this.data.getProductCode(p.productId),
							qty: p.qty || 1,
							price: p.price || p.addonPrice,
							ordinal: `${prep.ordinal}.${internalOrdinal}`,
						}
					})
				)
				return preps
			}, [])
			.sort((a, b) => {
				if (a.ordinal > b.ordinal) {
					return 1
				} else if (a.ordinal < b.ordinal) {
					return -1
				} else return 0
			})
	}

	getCartItemCount() {
		return this.items.reduce((itemCount, i) => {
			return itemCount + i.qty
		}, 0)
	}

	updateItem(item: TransientOrderLine) {
		const itemToChange = this.items.find((i) => i.id === item.id)
		if (!!itemToChange) {
			Object.assign(itemToChange, item)
		}
		this.itemsSubject.next(this.items)
	}

	updateItemQuantity(item: TransientOrderLine, qty: number) {
		const itemToChange = this.items.find((i) => i.id === item.id)
		if (!!itemToChange) {
			itemToChange.qty = qty
			this.calculateLineTotal(itemToChange)
		}
		this.itemsSubject.next(this.items)
	}

	removeFromCart(id) {
		const currentItems = [...this.items]
		const updated = currentItems.filter((_) => _.id !== id)
		this.itemsSubject.next(updated)
	}

	translateDestination(dest) {
		return {
			curb: 'Curb',
			curbside: 'Curb',
			collect: 'Collection',
			collection: 'Collection',
			delivery: 'Delivery',
			drivethru: 'DriveThru',
			kiosk: 'Kiosk',
			eatin: 'Eat In',
		}[dest]
	}

	checkItemsAvailability(currentSelection: CurrentSelection): TransientOrderLine[] {
		const [unavailableItems] = this.checkAvailability(currentSelection, this.items)
		return unavailableItems
	}

	checkAvailability(
		currentSelection: CurrentSelection,
		items: TransientOrderLine[]
	): [TransientOrderLine[], TransientOrderLine[]] {
		const unavailableItems = []
		const availableItems = []
		const dateToCheck = currentSelection.when === 'asap' ? new Date() : currentSelection.scheduleDateTime
		items.forEach((item) => {
			item.notAvailable = !(
				this.trading.itemInStore(item.product, currentSelection.store) &&
				this.trading.itemCanSell(item.product, currentSelection.orderingMethod?.salesOrderTag?.id) &&
				this.trading.itemIsAvailable(item.product, dateToCheck)
			)
			if (item.notAvailable) {
				unavailableItems.push(item)
			} else {
				availableItems.push(item)
			}
		})
		return [unavailableItems, availableItems]
	}

	updatePrices(pricingOption?: string) {
		this.items.forEach((item) => {
			item.price = this.data.getProductPrice(item.product, pricingOption)
			this.setAddOns(item, pricingOption)
			this.calculateLineTotal(item)
		})
		this.itemsSubject.next(this.items)
	}

	getCartItemsSum() {
		return this.items.reduce((total, i) => {
			return total + i.lineTotal
		}, 0)
	}

	getOrderTotal(lessRewards = false): number {
		let sum = this.getCartItemsSum() + this.paymentDetails.deliveryFee + this.paymentDetails.serviceFee
		if (!lessRewards) {
			sum = sum - this.paymentDetails.rewardTotal
		}
		return sum
	}

	getDisplayOrderNo(destinationType) {
		if (this.translateDestination(destinationType) === 'Eat In') {
			return this.kioskService.getKioskTableNo()
		} else {
			const lastHyphenIndex = this.currentOrderNumber.lastIndexOf('-')
			return this.currentOrderNumber.slice(lastHyphenIndex + 1)
		}
	}

	generateOrder(customer: Customer): Order {
		return {
			header: {
				storeId: get(customer, 'currentSelection.store._id'),
				storeName: get(customer, 'currentSelection.store.name'),
				posAction: 'autoComplete',
				collectionMethod: this.translateDestination(customer.currentSelection.destinationType),
				notes: get(customer, 'currentSelection.notes', ''),
				totalValue: this.getOrderTotal(),
				orderNo: this.currentOrderNumber,
				displayedOrderNo: this.getDisplayOrderNo(customer.currentSelection.destinationType),
				deliveryTime: get(customer, 'currentSelection.scheduleDateTime') ?? dayjs().toISOString(),
				orderTime: new Date(),
				statusCallback: {
					url: `${this.env.get().api}/order-status/broadcast`,
					verb: 'PUT',
				},
				source: 'golo',
			},
			customer: {
				_id: customer._id,
				unityId: customer.unityId,
				details: {
					uid: get(customer, 'details.uid'),
					emailVerified: get(customer, 'details.emailVerified'),
					isAnonymous: get(customer, 'details.isAnonymous'),
					displayName: get(customer, 'details.displayName'),
					email: get(customer, 'details.email'),
					phoneNumber: get(customer, 'details.phoneNumber'),
					metadata: '',
				},
				currentSelection: customer.currentSelection,
			},
			lines: this.items,
			payment: {
				value: 0,
				description: 'cod',
				deliveryFee: this.paymentDetails.deliveryFee,
				serviceFee: this.paymentDetails.serviceFee,
				rewards: this.paymentDetails.rewards,
			},
		}
	}

	clearCurrent() {
		this.itemsSubject.next([])
		this.updatePaymentDetails({
			cartTotal: 0,
			value: 0,
			serviceFee: 0,
			deliveryFee: 0,
			rewardTotal: 0,
		})
		// this.profile.resetCurrentSelection();
		this.profileResetCurrentSelection$.next(true)
		this.currentOrderNumber = ''
	}

	loyaltyLookup(customer: Customer): Observable<Reward[]> {
		return this.api
			.loyaltyLookup({
				tranTime: new Date(),
				totalIncl: this.getOrderTotal(true),
				orderMethod: this.translateDestination(customer.currentSelection.destinationType),
				customerId: customer._id,
				storeId: customer.currentSelection.store._id,
				items: this.items.map((item) => {
					return {
						code: item.productId,
						qty: item.qty,
						total: item.lineTotal,
						unit: item.price,
					}
				}),
			})
			.pipe(
				map((rewards: Reward[]) => {
					if (rewards) {
						const tot = this.getOrderTotal(true)
						let rewardTotal = 0
						const active = rewards.map((reward: Reward & { selected?: boolean }) => {
							rewardTotal += reward.value
							if (rewardTotal <= tot) {
								reward.selected = true
							} else {
								reward.selected = false
							}
							return reward
						})
						return active
					} else {
						return []
					}
				})
			)
	}

	loyaltyBasketAccumulate(rewards: Reward[] = [], customer: Customer): Observable<void> {
		return this.api.loyaltyAccumulate(this.rewardsToLoyaltyBasket(rewards, customer))
	}

	rewardsToLoyaltyBasket(rewards: Reward[], customer: Customer): LoyaltyBasket {
		return {
			tranTime: new Date(),
			totalIncl: this.getOrderTotal(),
			orderMethod: this.translateDestination(customer.currentSelection.destinationType),
			customerId: customer._id,
			storeId: customer.currentSelection.store._id,
			items: this.items.map((item) => {
				return {
					code: item.productId,
					qty: item.qty,
					total: item.lineTotal,
					unit: item.price,
				}
			}),
			transactionDetails: {
				reference: this.currentOrderNumber,
				terminal: 'golo',
			},
			rewards: rewards.map((r) => ({
				dashCode: r.dashCode,
				productCode: r.productCode,
				value: r.value,
				amount: r.value,
				name: r.name,
				description: r.description,
			})),
		}
	}

	processDeliveryFee(customer: Customer) {
		const deliverySelected = get(customer, 'currentSelection.destinationType') === 'delivery'
		if (deliverySelected) {
			const deliveryCharges: DeliveryCharge[] = get(this.data, 'settings[0].deliveryCharges', [])
			const deliveryProductId = get(this.data, 'settings[0].deliveryProduct')
			const deliveryDistance = this.geo.getDeliveryRadius(
				get(customer, 'currentSelection.location'),
				this.parseSelectedStoreLocation(customer)
			)
			if (deliveryCharges.length && deliveryProductId && deliveryDistance) {
				const min = minBy(
					deliveryCharges.filter((d) => Number(d.kmDistance) > deliveryDistance),
					'kmDistance'
				)
				let fee = this.getDeliveryFee(min, this.getCartItemsSum())
				min && this.updatePaymentDetails({ deliveryFee: fee })
			}
		}
	}

	getDeliveryFee(min: DeliveryCharge, totalSpend: number) {
		let charge = min.charge
		if (totalSpend <= min.minSpend) {
			charge += min.minSpendPenalty
		}
		if (totalSpend >= min.rewardSpend) {
			charge = Math.abs(charge - min.rewardValue)
		}
		return charge
	}

	private parseSelectedStoreLocation(customer: Customer): Partial<Location> {
		const coordStr = get(customer, 'currentSelection.store.coords')
		if (isString(coordStr) && coordStr.indexOf(',') > -1) {
			const coords = coordStr.split(',')
			return {
				latitude: coords[0],
				longitude: coords[1],
			}
		} else {
			throw new Error(`store.coords is ${coordStr}`)
		}
	}

	afterOrderPlaced(order: Order) {
		return this.toast
			.presentLoading(
				{
					cssClass: 'loader',
					message: 'Placing Order at Store',
					showBackdrop: true,
				},
				'postOrder'
			)
			.pipe(
				map(() => {
					this.toast.dismissLoading('postOrder')
					this.clearCurrent()
					this.storage.set('lastOrderNo', order.header.orderNo)
					this.router.navigate([`/orderstatus`, order.header.orderNo])
				})
			)
	}

	postOrder(order: Order, checkedRewards: Reward[], customer: Customer) {
		this.logger.debug('Posting Order: ', order)
		return this.toast
			.presentLoading(
				{
					cssClass: 'loader',
					message: 'Placing Order at Store',
					showBackdrop: true,
				},
				'postOrder'
			)
			.pipe(
				concatMap(() => this.api.placeOrder(order)),
				map((response: any) => {
					this.logger.debug('postOrder -> placeOrder ->  response: ', response)
					this.toast.dismissLoading('postOrder')
					if (response.error) {
						this.toast.toast({
							text: `Could not Post Order: ${response.error}`,
						})
					} else {
						this.loyaltyBasketAccumulate(checkedRewards, customer)
							.pipe(
								map(() => {
									this.clearCurrent()
								})
							)
							.subscribe()
						this.storage.set('lastOrderNo', order.header.orderNo)
						this.router.navigate([`/orderstatus`, order.header.orderNo])
					}
				})
			)
	}

	getNewOrderNumber(): string {
		const startDate = new Date('2021-07-01T00:00:00')
		const endDate = new Date()
		const secondsSince = (endDate.getTime() - startDate.getTime()).toString(36)
		const seed = parseInt(dayjs().format('YMDHHmmsszzz') + Math.floor(Math.random() * 1e6).toString())
		const ono =
			uniqueNamesGenerator({
				dictionaries: [adjectives, foods],
				separator: '-',
				length: 2,
				seed,
			}) +
			'-' +
			secondsSince
		this.storage.set('currentOrderNumber', ono)
		return ono
	}

	addPreps(item: TransientOrderLine) {
		if (item.product.posConfig && item.product.posConfig.selectedPrepMessages) {
			if (!item.prepMessages) {
				item.prepMessages = []
			}
			item.product.posConfig.selectedPrepMessages.forEach((p) => {
				const thisPrep = this.data.getPrepMessage(p._id)
				if (p.numCalls > 1) {
					for (let i = 1; i <= p.numCalls; i++) {
						item.prepMessages.push({
							name: thisPrep.name + ' #' + i,
							callId: i,
							selectedPreps: [],
							addOnPrice: 0,
							...thisPrep,
						})
					}
				} else {
					item.prepMessages.push({
						callId: 1,
						selectedPreps: [],
						addOnPrice: 0,
						...thisPrep,
					})
				}
			})
		}
	}

	getCurrentCartItems(): TransientOrderLine[] {
		return this.items || []
	}
}
