import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import { Subscription } from 'rxjs'
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult, FIREBASE_UI_CONFIG } from './models'
import * as firebaseui from 'firebaseui'
import 'firebase/auth'
import UserCredential = firebase.auth.UserCredential
import * as firebase from 'firebase/app'
import { FirebaseuiService } from './services/firebaseui.service'
import { SeqModalControllerService } from 'src/app/services/seq-modal-controller.service'
import { LoggerFactory } from 'src/app/services/public-api'

@Component({
	selector: 'firebase-ui',
	template: '<div id="firebaseui-auth-container"></div>',
})
export class FirebaseuiComponent implements OnInit, OnDestroy {
	private static readonly COMPUTED_CALLBACKS = 'COMPUTED_CALLBACKS'

	@Output('signInSuccessWithAuthResult')
	signInSuccessWithAuthResultCallback: EventEmitter<FirebaseUISignInSuccessWithAuthResult> = new EventEmitter()
	@Output('signInFailure') signInFailureCallback: EventEmitter<FirebaseUISignInFailure> = new EventEmitter()
	private subscription: Subscription
	firebaseUiConfig = JSON.parse(JSON.stringify(FIREBASE_UI_CONFIG))

	private logger = LoggerFactory.getLogger(this)
	constructor(
		private angularFireAuth: AngularFireAuth,
		private ngZone: NgZone,
		private firebaseUIService: FirebaseuiService,
		private modal: SeqModalControllerService
	) {}

	ngOnInit(): void {
		this.subscription = this.angularFireAuth.authState.subscribe((value: firebase.User) => {
			if ((value && value.isAnonymous) || !value) {
				if (this.firebaseUiConfig.signInOptions.length !== 0) {
					this.firebaseUI()
				} else {
					throw new Error('There must be at least one AuthProvider.')
				}
			} else {
				this.modal.closeLoginModal()
			}
		})
	}

	ngOnDestroy(): void {
		if (!!this.subscription) {
			this.subscription.unsubscribe()
		}
	}

	private getUIAuthConfig(): any {
		if (!this.firebaseUiConfig.callbacks) {
			this.firebaseUiConfig[FirebaseuiComponent.COMPUTED_CALLBACKS] = true
			this.firebaseUiConfig.callbacks = this.getCallbacks()
		}
		return this.firebaseUiConfig
	}

	private firebaseUI() {
		const firebaseUiInstance = this.firebaseUIService.firebaseUiInstance
		const uiAuthConfig = this.getUIAuthConfig()

		// Check if callbacks got computed to reset them again after providing the to firebaseui.
		// Necessary for allowing updating the firebaseui config during runtime.
		let resetCallbacks = false
		if (uiAuthConfig[FirebaseuiComponent.COMPUTED_CALLBACKS]) {
			resetCallbacks = true
			delete uiAuthConfig[FirebaseuiComponent.COMPUTED_CALLBACKS]
		}

		// show the firebaseui
		firebaseUiInstance.start('#firebaseui-auth-container', uiAuthConfig)

		if (resetCallbacks) {
			this.firebaseUiConfig.callbacks = null
		}
	}

	private getCallbacks(): any {
		const signInSuccessWithAuthResult = (authResult: UserCredential, redirectUrl) => {
			this.logger.debug(' signInSuccessWithAuthResult -> authResult', authResult)
			this.ngZone.run(() => {
				this.signInSuccessWithAuthResultCallback.emit({
					authResult,
					redirectUrl,
				})
			})
			return this.firebaseUiConfig.signInSuccessUrl
		}

		const signInFailureCallback = (error: firebaseui.auth.AuthUIError) => {
			this.logger.debug(' signInFailureCallback -> error', error)
			this.ngZone.run(() => {
				this.signInFailureCallback.emit({
					code: error.code,
					credential: error.credential,
				})
			})
			return Promise.reject()
		}

		return {
			signInSuccessWithAuthResult: signInSuccessWithAuthResult,
			signInFailure: signInFailureCallback,
		}
	}
}
