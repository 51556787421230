import { AfterViewInit, Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { TradingService, FacadeService, ToasterService, LoggerFactory } from 'src/app/services/public-api'
import { sumBy } from 'lodash'
import { Product, TransientOrderLine, TransientPrepMessage } from '@golo/models/lib/interfaces'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'

@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, AfterViewInit {
	private logger = LoggerFactory.getLogger(this)
	constructor(
		private modalController: ModalController,
		private services: FacadeService,
		private profile: CustomerProfileService,
		private toaster: ToasterService,
		private sanitizer: DomSanitizer,
		private trading: TradingService
	) {
		this.env = this.services.env.get()
	}

	env: any
	public product: Product
	public line: TransientOrderLine
	qty: number = 1
	calculatedPrice: number = 0
	cleanProductText: string = ''
	productHTML: SafeHtml
	deferPrepSelectionEvent = true

	async ngOnInit() {
		if (!this.line) {
			this.deferPrepSelectionEvent = false
			this.line = {
				id: '',
				productId: this.product._id,
				name: this.product.name,
				productCode: this.services.data.getProductCodeFromProduct(this.product),
				qty: 1,
				lineTotal: 0,
				price: this.services.data.getProductPrice(
					this.product,
					this.profile.customer.currentSelection?.orderingMethod?.pricingOption || 'basePrice'
				),
				notes: '',
				product: this.product,
				addOns: [],
				prepMessages: (
					this.services.data.getTransientPreps(
						this.product,
						'',
						'',
						this.profile.customer.currentSelection?.orderingMethod?.pricingOption || 'basePrice'
					) || []
				).filter((p: TransientPrepMessage) => {
					return (
						!p.salesOrderTags ||
						!p.salesOrderTags.length ||
						!!p.salesOrderTags.find(
							(tag) => tag?.id === this.profile.customer.currentSelection?.orderingMethod?.salesOrderTag.id
						)
					)
				}),
			}
		}
		this.productHTML = this.sanitizer.bypassSecurityTrustHtml(this.product.additionalInfo?.description)
		this.calculatePrice()
	}

	ngAfterViewInit() {
		this.deferPrepSelectionEvent = false
	}

	calculatePrice() {
		this.services.cart.calculateLineTotal(this.line)
		this.calculatedPrice = this.line.lineTotal
	}

	increase() {
		this.line.qty++
		this.calculatePrice()
	}

	decrease() {
		if (this.line.qty > 1) {
			this.line.qty--
			this.calculatePrice()
		}
	}

	async closeModal() {
		await this.modalController.dismiss(this.product)
	}

	async submit() {
		const tradingDate =
			this.profile.customer.currentSelection.when === 'asap'
				? new Date()
				: this.profile.customer.currentSelection.scheduleDateTime
		if (
			!this.trading.itemCanSell(
				this.line.product,
				this.profile?.customer?.currentSelection?.orderingMethod?.salesOrderTag?.id
			)
		) {
			this.toaster.toast({ text: `Item is not available for this ordering method`, duration: 1000 })
			return
		}

		if (!this.trading.itemInStore(this.line.product, this.profile?.customer?.currentSelection?.store)) {
			this.toaster.toast({ text: `Item is not available at this Store`, duration: 1000 })
			return
		}

		if (!this.trading.itemIsAvailable(this.line.product, tradingDate)) {
			this.toaster.toast({ text: `Item is not available at this time`, duration: 1000 })
			return
		}

		if (!this.profile?.customer?.currentSelection?.store) {
			this.toaster.toast({ text: `You cannot order without choosing a Store first`, duration: 1000 })
			return
		}

		if (
			this.line.prepMessages &&
			this.line.prepMessages.find((p) => p.minimumNumOfCalls > sumBy(p.selectedPreps, 'selectedCountWeighting'))
		) {
			this.toaster.toast({ text: `Please complete all Required selections` })
		} else {
			this.services.cart.calculateLineTotal(this.line)
			this.services.cart.setAddOns(this.line)
			this.services.cart.addToCart(this.line)
			await this.modalController.dismiss(this.product)
		}
	}

	getAddons(prepMessages) {
		let a = {}
		try {
			return prepMessages.map((prep) => {
				a = prep
				const selectedAddon = prep.messageProducts.find((pm) => pm.id === prep.selectedAddon)
				return {
					productId: selectedAddon.productId,
					qty: selectedAddon.quantity * this.qty,
					price: selectedAddon.price || 0,
					description: selectedAddon.displayName,
					productCode: this.services.data.getProductCode(selectedAddon.productId),
					addons: prep.prepMessages && prep.prepMessages.length ? this.getAddons(prep.prepMessages) : [],
				}
			})
		} catch (err) {
			this.logger.debug(err)
			this.logger.debug(1, a)
			return []
		}
	}

	onPrepSelectionChanged(prep: TransientPrepMessage): void {
		if (this.deferPrepSelectionEvent) return
		let expandedPreps = this.line.prepMessages.filter((p) => {
			return !p.ordinal.startsWith(`${prep.ordinal}.`)
		})
		let subOrdinal = 0
		prep.selectedPreps.forEach((p) => {
			subOrdinal += 1
			let tmpProduct = this.services.data.getProduct(p.productId)
			let subPreps = (
				this.services.data.getTransientPreps(
					tmpProduct,
					`${p.displayName} <span class="bread-crumb">|</span> `,
					`${prep.ordinal}.${subOrdinal}`,
					this.profile.customer.currentSelection?.orderingMethod?.pricingOption || 'basePrice'
				) || []
			).filter(
				(p: TransientPrepMessage) =>
					!p.salesOrderTags ||
					!p.salesOrderTags.length ||
					!!p.salesOrderTags.find(
						(tag) => tag.id === this.profile.customer.currentSelection?.orderingMethod?.salesOrderTag.id
					)
			)
			if (subPreps.length) {
				expandedPreps.push(...subPreps)
			}
		})
		this.line.prepMessages = this.services.data.sortTransientPreps(expandedPreps)
		this.services.cart.calculateLineTotal(this.line)
		this.calculatedPrice = this.line.lineTotal
	}
}
