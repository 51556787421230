import { MultiselectpopoverComponent } from './multiselectpopover/multiselectpopover.component'
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FacadeService, LoggerFactory, ToasterService } from 'src/app/services/public-api'
import { ModalController } from '@ionic/angular'

@Component({
	selector: 'app-prep-message-multi-select',
	templateUrl: './prep-message-multi-select.component.html',
	styleUrls: ['./prep-message-multi-select.component.scss'],
})
export class PrepMessageMultiSelectComponent implements OnInit {
	@Input() prepMessage: any
	@Input() product: any
	@Output() prepChange = new EventEmitter()

	private logger = LoggerFactory.getLogger(this)
	constructor(
		private facade: FacadeService,
		private toaster: ToasterService,
		private modalController: ModalController
	) {}

	async ngOnInit() {
		this.addDefaults()
	}

	addDefaults() {}

	async addPrep() {
		let allowPrep = true
		if (this.prepMessage.selectedPreps) {
			allowPrep =
				this.prepMessage.maximumNumOfCalls === 0 ||
				this.prepMessage.maximumNumOfCalls >
					this.prepMessage.selectedPreps.reduce((cnt, prep) => (cnt += prep.selectedCountWeighting), 0)
		}
		if (allowPrep) {
			this.logger.debug('selectedPreps: ', this.prepMessage.selectedPreps)
			const ms = await this.modalController.create({
				component: MultiselectpopoverComponent,
				componentProps: {
					prepMessage: this.prepMessage,
					productName: this.product.name,
					previousSelected:
						this.prepMessage.selectedPreps.reduce((a, v) => {
							if (v.id in a) {
								a[v.id] += v.qty
							} else {
								a[v.id] = v.qty
							}
							return a
						}, []) || [],
				},
				showBackdrop: true,
			})
			ms.onDidDismiss().then((res) => {
				this.addSelected(res.data)
			})
			await ms.present()
		} else {
			this.toaster.toast({
				text: `You cannot choose more than ${this.prepMessage.maximumNumOfCalls} item${
					this.prepMessage.maximumNumOfCalls > 1 ? 's' : ''
				}`,
				duration: 1000,
			})
		}
	}

	addSelected(selectedData: any) {
		let canAdd = true
		this.prepMessage.selectedPreps = []
		if (selectedData) {
			selectedData
				.filter(({ selectedCount }) => selectedCount)
				.forEach((selected) => {
					if (this.prepMessage.selectedPreps.length) {
						if (selected.maxSelection) {
							canAdd =
								selected.maxSelection >=
								this.prepMessage.selectedPreps.reduce(
									(cnt, prep) => (prep.id === selected.id ? (cnt += selected.selectedCount) : cnt),
									0
								)
						}

						if (this.prepMessage.maximumNumOfCalls) {
							canAdd =
								this.prepMessage.maximumNumOfCalls === 0 ||
								this.prepMessage.maximumNumOfCalls >=
									this.prepMessage.selectedPreps.reduce((cnt, prep) => (cnt += prep.selectedCountWeighting), 0) +
										selected.selectedCountWeighting * selected.selectedCount
						}
					}
					if (canAdd) {
						Array.prototype.push.apply(
							this.prepMessage.selectedPreps,
							Array(selected.selectedCount).fill({
								id: selected.id,
								displayName: selected.displayName,
								productId: selected.productId,
								addonPrice: selected.addonPrice,
								qty: 1,
								maxSelection: selected.maxselection,
								productCode: this.facade.data.getProductCode(selected.productId),
								selectedCountWeighting: selected.selectedCountWeighting,
							})
						)
					}
				})
			this.calculateTotalAddOnPrice()
			this.prepChange.emit(this.prepMessage)
		}
	}

	async removePrep(index: number, ev: Event) {
		this.prepMessage.selectedPreps = await this.prepMessage.selectedPreps.filter((p, i) => i !== index)
		this.calculateTotalAddOnPrice()
		this.prepChange.emit(this.prepMessage)
		ev.stopPropagation()
	}

	calculateTotalAddOnPrice(): void {
		this.prepMessage.addOnPrice = this.prepMessage.selectedPreps.reduce((addon, prep) => {
			return addon + prep.addonPrice
		}, 0)
	}
}
