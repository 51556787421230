<ion-card class="ion-no-padding" (click)="generateQrCode()">
	<ion-card-content class="ion-no-padding">
		<ion-grid class="ion-no-padding">
			<ion-row>
				<ion-col *ngIf="reward.image" class="img-size">
					<picture>
						<source srcset="{{ env.api }}/images/{{ reward.image }}.webp" />
						<img
							alt="Reward Image"
							width="100%"
							height="100%"
							src="{{ env.api }}/images/{{ reward.image }}.png"
							#image
							(error)="image.hidden = true"
							[hidden]="image.hidden"
						/>
					</picture>
				</ion-col>
				<ion-col>
					<ion-grid>
						<ion-row>
							<ion-col size="12">
								<ion-label>
									<h2 class="name">{{ reward.name }}</h2>
								</ion-label>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col class="details" size="12">
								<p class="details">{{ reward.description }}</p>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col class="ion-text-right" size="12">
								<ion-icon *ngIf="reward.dashCode" name="qr-code-outline"></ion-icon>
								<ion-label class="progress" *ngIf="reward.progress?.type == 'count'">
									{{ reward?.progress.current }} / {{ reward?.progress.target }}
								</ion-label>
								<ion-label class="progress" *ngIf="reward.progress?.type == 'value'">{{
									reward?.progress.current | currencyAmount
								}}</ion-label>
								<ion-progress-bar
									*ngIf="reward.progress && reward.progress.target"
									value="{{ reward.progress.current / reward.progress.target || 0 }}"
								></ion-progress-bar>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-card-content>
</ion-card>
