import { Injectable } from '@angular/core'
import { AppNotification } from '@golo/models/lib/interfaces'
import { ModalController } from '@ionic/angular'
import { EnvService } from '../support/env.service'
import { AppNotificationsService } from './app-notifications.service'
import { Storage } from '@ionic/storage'
import { from } from 'rxjs'
import { map } from 'rxjs/operators'
import moment from 'dayjs'
import { LoggerFactory } from '../helpers/logger-factory.class'

@Injectable({
	providedIn: 'root',
})
export class PwaInstallService {
	private logger = LoggerFactory.getLogger(this)
	env: any
	constructor(
		public storage: Storage,
		public modalController: ModalController,
		private appNote: AppNotificationsService,
		private enviro: EnvService
	) {
		this.env = this.enviro.get()
		;(<any>window).testPwaInstallIos = (() => {
			this.logger.debug('testPwaInstallIos')
			this.addPromoteAppNote('ios')
		}).bind(this)
		;(<any>window).testPwaInstall = (() => {
			this.logger.debug('testPwaInstall')
			this.addPromoteAppNote()
		}).bind(this)
	}

	promotePwa() {
		if (
			/iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase()) &&
			!('standalone' in window.navigator && (<any>window.navigator).standalone)
		) {
			from(this.storage.get('golo-promote'))
				.pipe(
					map((done: boolean) => {
						this.logger.debug('promotePwa -> done', done)
						if (!done) {
							this.addPromoteAppNote('ios')
							this.logger.debug('map -> ios addPromoteAppNote')
						}
					})
				)
				.subscribe()
		} else if (!window.matchMedia('(display-mode: standalone)').matches) {
			const wait = setInterval(() => {
				if ((<any>window).gpposolo.deferredPrompt) {
					clearInterval(wait)
					from(this.storage.get('golo-promote'))
						.pipe(
							map((done: boolean) => {
								this.logger.debug('promotePwa -> done', done)
								if (!done) {
									this.addPromoteAppNote()
								}
							})
						)
						.subscribe()
				}
			}, 100)
		}
	}

	private addPromoteAppNote(mode: 'ios' | 'normal' = 'normal') {
		let note: Partial<AppNotification> = {
			title: 'Add To Home Screen',
			brief: 'Get to the app quickly and easily by adding it to your home screen.',
			read: false,
			created: moment().toISOString(),
			actions: [],
			image: `${this.env.api}/pwa/icons/192x192.webp`,
		}
		if (mode === 'ios') {
			note.body = `
			<div style="padding-top: 0.5rem; display: flex;">
				<span>
					<span
						>Click the share button
						<span
							style="
								font-size: var(--gaap-font-size-l);
								color: var(--ion-color-toast-contrast);
								padding-left: 0.25rem;
								padding-right: 0.25rem;
							"
						>
							<img src="assets/share-outline.svg" class="promote-install-ios-svg" />
						</span>
					</span>
					<span
						>and then click "Add to Home Screen"
						<span
							style="
								font-size: var(--gaap-font-size-l);
								color: var(--ion-color-toast-contrast);
								padding-left: 0.25rem;
								padding-right: 0.25rem;
							"
						>
							<img src="assets/add-outline.svg" class="promote-install-ios-svg"
						/></span> </span
				></span>
			</div>
        `
		} else {
			note.body = ``
			note.actions = [{ title: 'Add', action: 'pwa-install' }]
		}
		this.appNote.saveAppNote(<AppNotification>note).then(() => {
			this.storage.set('golo-promote', true)
		})
	}

	triggerInstall() {
		if ((<any>window).gpposolo.deferredPrompt) {
			this.logger.debug('triggerInstall -> deferredPrompt', (<any>window).gpposolo.deferredPrompt)
			;(<any>window).gpposolo.deferredPrompt.prompt()
			;(<any>window).gpposolo.deferredPrompt.userChoice.then(() => {
				this.logger.debug('triggerInstall -> deferredPrompt.userChoice')
			})
		} else {
			console.error('triggerInstall -> deferredPrompt not available, cannot install')
		}
	}
}
