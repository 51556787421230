import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { orderBy, find, get } from 'lodash'
import { SeqModalControllerService } from './seq-modal-controller.service'
import { Location } from '@golo/models/lib/interfaces'
import {
	DataSourceService,
	ToasterService,
	LoggerFactory,
	GeolocatorService,
	GeoType,
} from 'src/app/services/public-api'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'

@Injectable({
	providedIn: 'root',
})
export class GuideService {
	private logger = LoggerFactory.getLogger(this)
	constructor(
		private data: DataSourceService,
		private geo: GeolocatorService,
		private toast: ToasterService,
		private modal: SeqModalControllerService,
		private router: Router,
		private profile: CustomerProfileService
	) {}

	/**
	 * Test Cases
	 * 	Delivery:
	 * 		select store outside range >5km -> outsideDeliveryRadius dialog
	 * 		select a delivery destination >7.5km far from the current user location -> distanceFromDelivery
	 * 		noStreetAddressSelected or noStreetAddressSaved
	 */
	runGuideChecks(): Promise<boolean> {
		const maxDist = get(
			orderBy(get(this.data, 'settings[0].deliveryCharges', []), ['kmDistance'], ['desc']),
			'[0].kmDistance',
			5
		)
		const deliverySelected = get(this.profile, 'customer.currentSelection.destinationType') === 'delivery'
		if (deliverySelected) {
			const hasPrecise = !!find(this.profile, ['customer.locations.meta.type', GeoType.PRECISE])
			const selectedDeliveryLocation = get(this.profile, 'customer.currentSelection.location')
			const selectedStoreLocation = this.profile.parseSelectedStoreLocation()

			const hasPreciseSelected =
				get(selectedDeliveryLocation, 'meta.type') === GeoType.PRECISE || selectedDeliveryLocation?.complex
			if (!hasPreciseSelected) {
				if (hasPrecise) {
					return this.noStreetAddressSelected()
						.then(() => {
							this.modal.openOptionsModalSeq('OptionsComponent')
						})
						.then(() => false)
				} else {
					return this.noStreetAddressSaved()
						.then(() => {
							this.modal.openOptionsModalSeq('AddressComponent', 'OptionsComponent')
						})
						.then(() => false)
				}
			} else {
				const urlParams = new URLSearchParams(window.location.search)
				if (!urlParams.has('wireTx')) {
					return this.geo
						.isWithinRange(selectedDeliveryLocation, maxDist)
						.toPromise()
						.then((tup) => {
							const [within, geo] = tup
							this.logger.debug('runGuideChecks (delivery) -> isWithinRange -> geo: ', geo, ', within: ', within)
							if (!within && geo.type === 'PRECISE') {
								return this.distanceWarning(this.distanceFromDelivery)
									.then(() => {
										return this.distanceWarningToast.onDidDismiss()
									})
									.then(() => {
										if (this.distanceWarningResponseOk) {
											return this.isSelectedWithinDeliveryRadius(
												selectedStoreLocation,
												selectedDeliveryLocation,
												maxDist
											)
										} else {
											this.modal.openOptionsModalSeq('OptionsComponent')
											return false
										}
									})
							} else {
								return this.isSelectedWithinDeliveryRadius(selectedStoreLocation, selectedDeliveryLocation, maxDist)
							}
						})
				} else {
					return Promise.resolve(true)
				}
			}
		} else {
			return Promise.resolve(true)
		}
	}

	optionsDeliveryRadiusCheck(
		selectedDeliveryLocation: Location,
		closeDialog: (reroute: boolean, skipGuide: boolean) => void
	): Promise<boolean> {
		const selectedStoreLocation = this.profile.parseSelectedStoreLocation()
		const maxDist = get(
			orderBy(get(this.data, 'settings[0].deliveryCharges', []), ['kmDistance'], ['desc']),
			'[0].kmDistance',
			5
		)
		this.logger.debug('optionsDeliveryRadiusCheck -> maxDist', maxDist)
		if (get(selectedDeliveryLocation, 'meta.type') === GeoType.PRECISE || selectedDeliveryLocation?.complex) {
			if (!this.geo.isSelectedWithinDeliveryRadius(selectedStoreLocation, selectedDeliveryLocation, maxDist)) {
				return this.distanceWarning(this.outsideDeliveryRadius, '', 'close')
					.then(() => {
						return this.distanceWarningToast.onDidDismiss()
					})
					.then(() => {
						if (this.distanceWarningResponseOk) {
							closeDialog(true, true)
							this.router.navigate(['/home'])
							return false
						} else {
							return false
						}
					})
			} else {
				return Promise.resolve(true)
			}
		} else {
			const hasPrecise = !!find(this.profile, ['customer.locations.meta.type', GeoType.PRECISE])
			if (hasPrecise) {
				return this.noStreetAddressSelected().then(() => false)
			} else {
				return this.noStreetAddressSaved().then(() => false)
			}
		}
	}

	private isSelectedWithinDeliveryRadius(
		selectedStoreLocation: Partial<Location>,
		selectedDeliveryLocation: Partial<Location>,
		maxDist: number,
		cancelText = 'change option'
	): Promise<boolean> {
		if (!this.geo.isSelectedWithinDeliveryRadius(selectedStoreLocation, selectedDeliveryLocation, maxDist)) {
			return this.distanceWarning(this.outsideDeliveryRadius, 'change store', cancelText)
				.then(() => {
					return this.distanceWarningToast.onDidDismiss()
				})
				.then(() => {
					if (this.distanceWarningResponseOk) {
						this.router.navigate(['/home', { returnTo: '/cart' }])
						return false
					} else {
						this.modal.openOptionsModalSeq('OptionsComponent')
						return false
					}
				})
		} else {
			return Promise.resolve(true)
		}
	}

	private async noStreetAddressSaved() {
		return this.toast.toast({ text: 'Please select a street address or add building/complex details' })
	}
	private async noStreetAddressSelected() {
		return this.toast.toast({ text: 'Please select a street or complex address for for delivery' })
	}

	distanceFromDelivery = {
		header: 'Distance Warning',
		message: 'Please confirm that your delivery address is correct',
	}
	outsideDeliveryRadius = {
		header: 'Delivery Not Available',
		message: 'Your selected address is outside the delivery range. Please select a different order method',
	}
	distanceWarningToast: HTMLIonToastElement
	distanceWarningResponseOk: boolean
	async distanceWarning(msg: any, okText = 'ok', cancelText = 'change') {
		this.distanceWarningResponseOk = true
		this.distanceWarningToast = await this.toast.toast(
			{ text: msg.message, position: 'top' },
			{
				header: msg.header,
				buttons: [
					{
						side: 'end',
						text: okText,
						handler: () => {
							this.distanceWarningResponseOk = true
						},
					},
					{
						text: cancelText,
						role: 'cancel',
						handler: () => {
							this.distanceWarningResponseOk = false
						},
					},
				],
			}
		)
	}
}
