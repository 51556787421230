<ion-header>
	<ion-toolbar color="primary">
		<ion-buttons slot="end">
			<ion-button (click)="closeModal()" aria-label="Close">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-card>
		<ion-card-header>
			<ion-card-title>{{ faq.question }}</ion-card-title>
		</ion-card-header>
		<ion-card-content>
			<ion-text style="white-space: pre-wrap">{{ faq.answer }}</ion-text>
		</ion-card-content>
	</ion-card>
</ion-content>
