import { Component, Input, OnInit } from '@angular/core'
import { Reward } from '@golo/models/lib/interfaces'
import { ModalController } from '@ionic/angular'
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'

@Component({
	selector: 'app-qrcode-voucher',
	templateUrl: './qrcode-voucher.component.html',
	styleUrls: ['./qrcode-voucher.component.scss'],
})
export class QRCodeVoucherComponent implements OnInit {
	private logger = LoggerFactory.getLogger(this)
	constructor(private modalController: ModalController, private services: FacadeService) {}
	@Input() reward: Reward
	qrCode = ''
	env: any
	elementType = NgxQrcodeElementTypes.URL
	correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH

	async ngOnInit() {
		this.env = this.services.env.get()
		this.qrCode = this.reward.dashCode
	}

	close() {
		this.modalController.dismiss()
	}
}
