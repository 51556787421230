export const firebaseConfig = {
	apiKey: 'AIzaSyAut2rtw1TZgatvX6v4dC_XTrsMuC7zaBQ',
	authDomain: 'golo-283916.firebaseapp.com',
	databaseURL: 'https://golo-283916.firebaseio.com',
	projectId: 'golo-283916',
	storageBucket: 'golo-283916.appspot.com',
	messagingSenderId: '591612111652',
	appId: '1:591612111652:web:f1a36c635b308fbb5f21b1',
	measurementId: 'G-3099WNFNR0',
}
