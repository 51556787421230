import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { FacadeService } from 'src/app/services/public-api'
import { FeedbackQuestion, FeedbackResponse, FeedbackResponses } from '@golo/models/lib/interfaces/feedbacks'
import { Router, ActivatedRoute } from '@angular/router'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'
import { filter } from 'rxjs/operators'

@Component({
	selector: 'app-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.css'],
})
export class FeedbackComponent implements OnInit {
	constructor(
		private modalController: ModalController,
		private services: FacadeService,
		private router: Router,
		private profile: CustomerProfileService,
		private route: ActivatedRoute
	) {}
	feedbackQuestions: FeedbackQuestion[]
	feedbackResponses: { [key: string]: FeedbackResponse } = {}
	orderNo: string

	ngOnInit(): void {
		this.route.queryParams.subscribe((params) => {
			this.orderNo = params.orderNo
		})
		this.services.api.getFeedbackQuestions('golo').subscribe((questions: FeedbackQuestion[]) => {
			this.feedbackQuestions = questions || []
			this.feedbackQuestions.forEach((fq: FeedbackQuestion) => {
				const response: FeedbackResponse = { id: fq.id, title: fq.title, description: fq.description, type: fq.type }
				switch (fq.type) {
					case 'comment':
						response.comment = ''
						this.feedbackResponses[fq.id] = response
						break
					case 'rating':
						response.rating = 0
						this.feedbackResponses[fq.id] = response
						break
					case 'yesno':
						response.yesno = true
						this.feedbackResponses[fq.id] = response
						break
					default:
						this.feedbackResponses[fq.id] = response
				}
			})
		})
	}

	submitFeedback() {
		// adminNodeId will be completed by api
		// optional nodeId can be inserted as current node if needed
		const responses = Object.keys(this.feedbackResponses).map((k) => this.feedbackResponses[k])
		const feedbackResponse: FeedbackResponses = {
			nodeId: this.profile.customer.currentSelection.store.nodeId,
			customer: {
				id: this.profile.customer.unityId,
				name: this.profile.customer.details.displayName,
				email: this.profile.customer.details.email,
				phone: this.profile.customer.details.phoneNumber,
			},

			group: 'golo',
			orderNo: this.orderNo,
			responses,
		}

		this.services.api.sendFeedbackResponse(feedbackResponse).subscribe((res: any) => {
			this.home()
		})
	}

	home() {
		this.router.navigate(['/'], { queryParams: { skipLand: true } })
	}
}
