<ion-header>
	<ion-toolbar color="primary">
		<ion-title>EARN CODE</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()" aria-label="Close">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-card>
		<ion-grid>
			<ion-row>
				<ion-col size="12" size-xs="12" size-sm="8" size-md="8" size-lg="12">
					<ion-grid>
						<ion-row>
							<ion-col>
								<ion-item class="ion-text-center ion-text-wrap" lines="none">
									<ion-label class="details ion-text-wrap"
										>Ask the merchant to scan this code to earn rewards</ion-label
									>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-col>
				<ion-col size="12" size-xs="12" size-sm="4" size-md="4" size-lg="12">
					<ion-grid class="ion-no-padding qr-grid">
						<ion-row class="qr-row">
							<ion-col class="qrcode">
								<ngx-qrcode
									[elementType]="elementType"
									[errorCorrectionLevel]="correctionLevel"
									[value]="qrCode"
									[width]="200"
									class="ion-text-center"
								></ngx-qrcode>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col>
								<ion-item class="ion-text-center">
									<ion-label class="dash-code">{{ qrCode }}</ion-label>
								</ion-item>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col>
								<ion-item class="ion-text-center ion-text-wrap" lines="none">
									<ion-label class="details ion-text-wrap"
										>If the merchant does not have a scanner, give this code to the cashier</ion-label
									>
								</ion-item>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-card>
</ion-content>
