import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { catchError, filter, switchMap, take } from 'rxjs/operators'
import { CustomerProfileService } from './customer-profile.service'
import { LoggerFactory } from 'src/app/services/public-api'

@Injectable({
	providedIn: 'root',
})
export class HttpInterceptorService {
	private logger = LoggerFactory.getLogger(this)
	private isRefreshing = false
	private refreshTokenSubject = new BehaviorSubject(null)
	constructor(private profile: CustomerProfileService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		request = this.addHeader(request)
		return next.handle(request).pipe(
			catchError((error: any) => {
				if (error instanceof HttpErrorResponse) {
					this.logger.debug(`error`, error)
					if (error.status === 504 || error.status === 0) {
						return throwError(error)
					} else if (error.status === 401) {
						if (!this.isRefreshing) {
							this.isRefreshing = true
							this.refreshTokenSubject.next(null)
							request = this.addHeader(request)
							return this.profile.refreshToken().pipe(
								switchMap((token: string | null) => {
									this.isRefreshing = false
									if (token) {
										localStorage.setItem('token', token)
									}
									this.refreshTokenSubject.next(token)
									return next.handle(this.addHeader(request))
								})
							)
						} else {
							return this.refreshTokenSubject.pipe(
								filter((e) => !!e),
								take(1),
								switchMap(() => {
									return next.handle(this.addHeader(request))
								})
							)
						}
					}
				}
			})
		)
	}

	private addHeader(request: HttpRequest<any>) {
		const token: string = localStorage.getItem('token')
		if (token) {
			request = request.clone({ headers: request.headers.set('authorization', 'Bearer ' + token) })
		}
		if (!request.headers.has('Content-Type')) {
			request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') })
		}
		request = request.clone({ headers: request.headers.set('Accept', 'application/json') })
		return request
	}
}
