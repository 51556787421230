<ion-item>
	<ion-label text-wrap>
		<p class="feedback-name">{{ feedbackQuestion.title }}</p>
		<p class="feedback-details">{{ feedbackQuestion.description }}</p></ion-label
	>
	<ion-buttons slot="end">
		<ion-chip
			*ngFor="let answer of ['No', 'Yes']"
			(click)="submitAnswer(answer)"
			[ngClass]="{ selectedChip: currentAnswer === yesNoToBoolean(answer) }"
			>{{ answer }}</ion-chip
		>
	</ion-buttons>
</ion-item>
