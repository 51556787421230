<ion-header>
	<ion-toolbar color="primary">
		<ion-title>Product Search</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="closeModal()" aria-label="Close">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-searchbar
		#ionSearchbar
		placeholder="Search Products"
		(ionChange)="searchProducts()"
		[(ngModel)]="searchTerm"
	></ion-searchbar>
	<ion-list class="list-background">
		<ion-item lines="full" *ngIf="!products.length">
			<ion-label class="list-placeholder">No products found</ion-label>
		</ion-item>
		<ion-item lines="full" (click)="selectProduct(product)" *ngFor="let product of products; let i = index">
			<ion-label text-wrap>
				<h1 class="product-name">{{ product.name }}</h1>
				<p class="product-details">{{ product.description }}</p>
				<ion-badge *ngIf="!product.minPrice" class="badge-tertiary">{{
					product.currentPrice | currencyAmount
				}}</ion-badge>
				<ion-badge *ngIf="product.minPrice" class="badge-tertiary"
					>From {{ product.minPrice + product.currentPrice | currencyAmount }}</ion-badge
				>
				<ion-badge *ngIf="!product.available" class="badge-warning">Unavailable</ion-badge>
			</ion-label>
			<ion-thumbnail slot="end" *ngIf="product.image">
				<picture>
					<source srcset="{{ env.api }}/images/{{ product.image }}.webp" />
					<img
						alt="Product image"
						#image
						src="{{ env.api }}/images/{{ product.image }}.png"
						witdh="100%"
						height="100%"
						(error)="imageError(i)"
					/>
				</picture>
			</ion-thumbnail>
		</ion-item>
	</ion-list>
</ion-content>
