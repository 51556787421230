import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Store, Customer } from '@golo/models/lib/interfaces'
import { map } from 'rxjs/operators'
import * as moment from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
moment.extend(isoWeek)
import { find } from 'lodash'
import { LoggerFactory } from 'src/app/services/public-api'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'

@Component({
	selector: 'app-schedule-daytime-selection',
	templateUrl: './schedule-daytime-selection.component.html',
	styleUrls: ['./schedule-daytime-selection.component.scss'],
})
export class ScheduleDaytimeSelectionComponent implements OnInit {
	private logger = LoggerFactory.getLogger(this)
	constructor(private modalController: ModalController, public user: CustomerProfileService) {}

	nextDays: any[]
	timeIntervals: any[]
	results: any = {}
	selectedStore: Store
	startDayNum = moment().isoWeekday()
	days = [
		{ value: 1, text: 'Mon' },
		{ value: 2, text: 'Tues' },
		{ value: 3, text: 'Wed' },
		{ value: 4, text: 'Thurs' },
		{ value: 5, text: 'Fri' },
		{ value: 6, text: 'Sat' },
		{ value: 7, text: 'Sun' },
	]
	ngOnInit() {
		this.user
			.watch()
			.pipe(
				map((u: Customer) => {
					this.nextDays = this.buildNextDays()
					this.selectedStore = u.currentSelection.store
					this.timeIntervals = this.buildTimeIntervals()
				})
			)
			.subscribe()
	}

	day($event) {
		this.results.day = find(this.days, ['value', $event.detail.value])
		if ($event.detail.value !== this.startDayNum.toString()) {
			this.timeIntervals = this.buildTimeIntervals(false)
		} else {
			this.timeIntervals = this.buildTimeIntervals()
		}
	}

	time($event) {
		this.results.time = $event.detail.value
	}

	private buildNextDays() {
		let nextDays = []
		for (let i = 0; i < 5; i++) {
			let dayNum = this.startDayNum + i
			if (dayNum > 7) {
				dayNum = dayNum - 7
			}
			let day = find(this.days, ['value', dayNum])
			nextDays.push(day)
		}
		return nextDays
	}

	private buildTimeIntervals(useNow = true) {
		let endTimeStr = '20:00'
		let startTimeStr = '10:00' //defaults
		if (this.selectedStore) {
			startTimeStr = this.selectedStore.tradingDetails.times.open
			endTimeStr = this.selectedStore.tradingDetails.times.close
		}
		const interval = 15
		const endTime = moment(endTimeStr, 'HH:mm')
		const givenStartTime = startTimeStr && moment(startTimeStr, 'HH:mm')
		const now = moment()
		let startTime
		if (useNow && now.isAfter(givenStartTime)) {
			const remainder = interval - (now.minute() % interval)
			const nowNearestInterval = now.add(remainder, 'm')
			startTime = nowNearestInterval.add(interval - (nowNearestInterval.minute() % interval), 'm')
		} else {
			startTime = givenStartTime.add(interval - (givenStartTime.minute() % interval), 'm')
		}
		const timeStops = []
		while (startTime <= endTime) {
			timeStops.push(startTime.format('HH:mm'))
			startTime = startTime.add(interval, 'm')
		}
		return timeStops.map((i) => {
			return { value: i, text: i }
		})
	}

	async closeModal() {
		await this.modalController.dismiss(this.results)
	}
}
