import { PopoverController } from '@ionic/angular'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'
import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-storefilterpopover',
	templateUrl: './storefilterpopover.component.html',
	styleUrls: ['./storefilterpopover.component.scss'],
})
export class StorefilterpopoverComponent implements OnInit {
	private logger = LoggerFactory.getLogger(this)
	constructor(private services: FacadeService, private popovercontroller: PopoverController) {}

	public filter: any
	tags = []
	orderMethods = []

	ngOnInit() {
		this.logger.debug(this.services.data.settings)
		const descriptiveTag = this.services.data.settings[0].descriptiveTag
		const selectedTags = this.filter.tags.map(({ id }) => id)
		const selectedMethods = this.filter.orderingMethods.map(({ name }) => name)
		this.logger.debug('Selected Method:' + selectedMethods)

		this.tags = this.services.data.tags
			.find(({ type }) => type === 'descriptiveTag')
			.tags.filter(({ parentId }) => parentId === descriptiveTag)
			.map((tag) => {
				return { ...tag, selected: selectedTags.includes(tag.id) }
			})

		this.orderMethods = this.services.data.settings[0].orderingMethods
			.filter(({ active }) => active)
			.map((method) => {
				return { ...method, selected: selectedMethods.includes(method.name) }
			})
	}

	closePopover() {
		this.filter.tags = this.tags.filter(({ selected }) => selected)
		this.filter.orderingMethods = this.orderMethods.filter(({ selected }) => selected)
		this.filter.filtered = this.filter.tags.length || this.filter.orderingMethods.length
		this.popovercontroller.dismiss()
	}
}
