import { Customer, LoyaltyBasket, Order, OrderPayment, PaymentMethod, Reward } from '@golo/models/lib/interfaces'
import { LoadingController } from '@ionic/angular'
import { from, Observable, of } from 'rxjs'
import { catchError, concatMap, map, tap } from 'rxjs/operators'
import { PaymentView } from './payment-view.interface'
import { FacadeService } from 'src/app/services/public-api'
import { HttpClient } from '@angular/common/http'
import { concat, set } from 'lodash'

const PENDING = 'cc-pending-order'
export const GATEWAY_KEY = 'cc-gateway-key'
const GATEWAY_OPTIONS = 'cc-gateway-options'

export interface PaymentResponse {
	success: boolean
	reasons?: string
	meta?: any
}

export abstract class AbstractPaymentGatewayService {
	typeKey: string

	constructor(public services: FacadeService, public loadingController: LoadingController, public http: HttpClient) {
		/**hook general supportive behavior into child methods */
		let redirectBack = this.redirectBack.bind(this)
		this.redirectBack = (page: PaymentView, orderDetails?: [Order, LoyaltyBasket], response?: PaymentResponse) => {
			return this.retrieveOrder().pipe(
				concatMap((orderDetails: [Order, LoyaltyBasket]) => {
					if (orderDetails) {
						const [order, rewards] = orderDetails
						const status: any = this.queryStringToJSON(window.location.href)
						let meta: any = {}
						if (status.success === 'false') {
							try {
								meta = JSON.parse(atob(status.meta))
							} catch (error) {
								console.error(error)
							}
							this.services.toast.toast(
								{
									text: (status.reason && decodeURIComponent(status.reason)) || 'The payment has failed',
									position: 'top',
								},
								{
									header: 'Payment Status',
									buttons: [
										{
											text: 'ok',
											role: 'cancel',
										},
									],
								}
							)
						}
						try {
							set(order, 'payment.meta', meta)
						} catch (error) {
							console.error(error)
						}
						return this.updateOrderPayment(order.header.orderNo, order.payment).pipe(
							catchError((error) => {
								console.error(error)
								return of(null)
							}),
							concatMap(() => {
								return redirectBack(page, orderDetails, {
									meta,
									success: status.success === 'true',
								})
							})
						)
					} else {
						return of(null)
					}
				}),
				map((order: [Order, LoyaltyBasket]) => {
					this.clearAll()
					return order
				})
			)
		}
		let redirect = this.redirect.bind(this)
		this.redirect = (orderDetails: [Order, LoyaltyBasket], options: any) => {
			this.storeOrder(orderDetails)
			this.storeOptions(options)
			this.storeGatewayKey(this.typeKey)
			redirect(orderDetails, options)
		}
		let prep = this.prep.bind(this)
		this.prep = (orderDetails: [Order, LoyaltyBasket], customer: Customer, method: PaymentMethod) => {
			const [order, rewards] = orderDetails
			order.payment.description = method.type
			return prep(orderDetails, customer, method)
		}
	}

	/** Abstract methods */

	/**
	 * @param order
	 */
	abstract prep(orderDetails: [Order, LoyaltyBasket], customer: Customer, method: PaymentMethod): Observable<any>
	/**
	 * @param page PaymentView - setting component class fields
	 * @param order provided by base class
	 */
	abstract redirectBack(
		page: PaymentView,
		orderDetails?: [Order, LoyaltyBasket],
		response?: PaymentResponse
	): Observable<[Order, LoyaltyBasket] | null>
	/**
	 * @param order
	 * @param options
	 */
	abstract redirect(orderDetails: [Order, LoyaltyBasket], options: any): void

	/**Utility methods */

	queryStringToJSON(queryString) {
		if (queryString.indexOf('?') > -1) {
			queryString = queryString.split('?')[1]
		}
		var pairs = queryString.split('&')
		var result = {}
		pairs.forEach(function (pair) {
			pair = pair.split('=')
			result[pair[0]] = decodeURIComponent(pair[1] || '')
		})
		return result
	}

	storeOrder(order: [Order, LoyaltyBasket]) {
		this.services.storage.set(PENDING, JSON.stringify(order))
	}

	retrieveOrder(): Observable<[Order, LoyaltyBasket]> {
		return from(this.services.storage.get(PENDING)).pipe(
			map((jsonStr: string) => {
				const order: [Order, LoyaltyBasket] = jsonStr && JSON.parse(jsonStr)
				return order
			})
		)
	}

	storeOptions(options: any) {
		this.services.storage.set(GATEWAY_OPTIONS, JSON.stringify(options))
	}

	retrieveOptions(): Observable<any> {
		return from(this.services.storage.get(GATEWAY_OPTIONS)).pipe(
			map((jsonStr: string) => {
				return jsonStr && JSON.parse(jsonStr)
			})
		)
	}

	storeGatewayKey(gatewayKey: string) {
		this.services.storage.set(GATEWAY_KEY, gatewayKey)
	}

	clearAll() {
		this.services.storage.remove(PENDING)
		this.services.storage.remove(GATEWAY_OPTIONS)
		this.services.storage.remove(GATEWAY_KEY)
	}

	/**http methods */
	prepare(body: { orderDetails: [Order, LoyaltyBasket]; payload: any }) {
		return this.http
			.post(`${this.services.env.get().api}/payment-gateway/prepare/${this.typeKey}`, body, {
				responseType: 'json',
			})
			.pipe(
				tap((response) => {
					this.services.api.setOnline()
					return of(response)
				}),
				catchError((err: any) => {
					this.services.api.handleError(err)
					return of(null)
				})
			)
	}

	updateOrderPayment(orderNo: string, payment: OrderPayment): Observable<any> {
		return this.http.put(`${this.services.env.get().api}/orders/payment/${orderNo}`, payment).pipe(
			tap(() => {
				this.services.api.setOnline()
			}),
			catchError((err: any) => {
				this.services.api.handleError(err)
				return of(null)
			})
		)
	}
}
