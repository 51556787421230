<ion-header>
	<ion-toolbar color="primary">
		<ion-buttons slot="start">
			<ion-back-button (click)="home()"></ion-back-button>
			<ion-title>Feedback</ion-title>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-card>
		<ul class="items">
			<li *ngFor="let feedbackQuestion of feedbackQuestions" class="items__item" [ngSwitch]="feedbackQuestion.type">
				<ng-template ngSwitchCase="rating" *ngIf="!!feedbackResponses[feedbackQuestion.id]">
					<app-feedback-rating
						[(rating)]="feedbackResponses[feedbackQuestion.id].rating"
						[feedbackQuestion]="feedbackQuestion"
					></app-feedback-rating>
				</ng-template>
				<ng-template ngSwitchCase="yesno" *ngIf="!!feedbackResponses[feedbackQuestion.id]">
					<app-feedback-question
						[(currentAnswer)]="feedbackResponses[feedbackQuestion.id].yesno"
						[feedbackQuestion]="feedbackQuestion"
					></app-feedback-question>
				</ng-template>
				<ng-template ngSwitchCase="comment" *ngIf="!!feedbackResponses[feedbackQuestion.id]">
					<app-feedback-input
						[(inputValue)]="feedbackResponses[feedbackQuestion.id].comment"
						[feedbackQuestion]="feedbackQuestion"
					></app-feedback-input>
				</ng-template>
			</li>
		</ul>
	</ion-card>
	<ion-footer>
		<ion-toolbar class="global-footer" color="primary">
			<ion-grid>
				<ion-row>
					<ion-col class="ion-text-left" size="7">
						<ion-label class="footer-button" (click)="home()">CANCEL</ion-label>
					</ion-col>
					<ion-col class="ion-text-right" size="5">
						<ion-label class="footer-button" (click)="submitFeedback()">SUBMIT</ion-label>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-toolbar>
	</ion-footer>
</ion-content>
