<ion-header>
	<ion-toolbar color="primary">
		<ion-title>ADDRESS DETAILS</ion-title>
		<ion-buttons slot="start">
			<ion-button (click)="closeModal()" aria-label="Close">
				<ion-icon name="arrow-back"></ion-icon>
			</ion-button>
		</ion-buttons>
		<ion-buttons slot="end">
			<ion-button (click)="closeModal()" aria-label="Close">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-card>
		<ion-list class="card-list">
			<ion-radio-group [(ngModel)]="nickName">
				<ion-list-header class="list-header"><ion-label>Address Nickname</ion-label></ion-list-header>
				<ion-item>
					<ion-label>Current</ion-label>
					<ion-radio color="secondary" value="Current"></ion-radio>
				</ion-item>
				<ion-item>
					<ion-label>Home</ion-label>
					<ion-radio color="secondary" value="Home"></ion-radio>
				</ion-item>
				<ion-item>
					<ion-label>Work</ion-label>
					<ion-radio color="secondary" value="Work"></ion-radio>
				</ion-item>
				<ion-item>
					<ion-label>2nd home</ion-label>
					<ion-radio color="secondary" value="2nd home"></ion-radio>
				</ion-item>
			</ion-radio-group>
			<ion-list-header class="list-header"><ion-label>Street Address</ion-label></ion-list-header>
			<ion-item>
				<ion-input type="text" [(ngModel)]="address" required name="address" (ionFocus)="map()"></ion-input>
				<ion-fab-button size="small" slot="end" color="secondary" (click)="map()">
					<ion-icon name="map-outline"></ion-icon
				></ion-fab-button>
			</ion-item>
			<ion-list-header class="list-header"><ion-label>Building / Complex Details</ion-label></ion-list-header>
			<ion-item>
				<ion-input type="text" [(ngModel)]="complex" name="complex"></ion-input>
			</ion-item>
		</ion-list>
	</ion-card>
	<ion-toolbar class="global-footer" color="primary">
		<ion-grid class="ion-no-padding">
			<ion-row>
				<ion-col size="12" (click)="back()">
					<ion-button class="footer-button" expand="full" fill="clear" aria-label="Accept">ACCEPT</ion-button>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-toolbar>
</ion-content>
