import { Injectable } from '@angular/core'
import { EnvService } from './env.service'
import { io, Socket } from 'socket.io-client'
import { isArray } from 'lodash'
import { DataSourceService } from './data-source.service'
import { ApiService } from './api.service'
import { concatMap } from 'rxjs/operators'
import { LoggerFactory } from '../helpers/logger-factory.class'

@Injectable({
	providedIn: 'root',
})
export class SocketService {
	private logger = LoggerFactory.getLogger(this)
	public socket: Socket
	constructor(private env: EnvService, private api: ApiService, private data: DataSourceService) {
		this.socket = io(this.env.get().api)
		this.socket.on('reload', (msg) => {
			this.logger.debug('socket.on(reload) -> msg', msg)
			if (isArray(msg) && this.data.dataReloads.some((r) => msg.indexOf(r) >= 0)) {
				this.logger.debug('socket.on(reload) -> initData')
				this.api
					.initData()
					.pipe(
						concatMap((data) => {
							return this.data.load(data)
						})
					)
					.subscribe()
			}
		})
	}
}
