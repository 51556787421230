import { firebaseConfig } from './firebase.config'
import { googleApiKey } from './googleapis'

export const environment = {
	production: true,
	api: 'https://master.golo.gppos.net',
	// api: 'http://localhost:8500',
	firebase: firebaseConfig,
	googleApiKey,
}
