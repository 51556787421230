import * as firebase from 'firebase/app'
import 'firebase/auth'

export const FIREBASE_UI_CONFIG: firebaseui.auth.Config = {
	signInFlow: 'popup',
	signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		{
			provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
			signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
		},
		{
			provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
			defaultCountry: 'ZA',
		},
		'apple.com',
	],
}

export class FirebaseUISignInSuccessWithAuthResult {
	authResult: any
	redirectUrl: string
}

export class FirebaseUISignInFailure {
	code: string
	credential: any
}
