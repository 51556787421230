<ion-header>
	<ion-toolbar color="primary">
		<ion-buttons slot="end">
			<ion-button (click)="closeModal()" aria-label="Close">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content fullscreen="true">
	<ion-grid style="height: 100%">
		<ion-row justify-content-center align-items-center style="height: 100%; flex-direction: column">
			<firebase-ui
				(signInSuccessWithAuthResult)="successCallback($event)"
				(signInFailure)="errorCallback($event)"
			></firebase-ui>
		</ion-row>
	</ion-grid>
	<ion-toolbar class="global-footer" color="primary">
		<ion-grid class="ion-no-padding">
			<ion-row>
				<ion-col size="12" (click)="closeModal()">
					<ion-button class="footer-button" expand="full" fill="clear" aria-label="Cancel">CANCEL</ion-button>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-toolbar>
</ion-content>
