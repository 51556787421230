import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'
import { GestureController } from '@ionic/angular'
import { PromoImages } from '@golo/models'
import { IonSlides, ModalController } from '@ionic/angular'

@Component({
	selector: 'app-promoslider',
	templateUrl: './promoslider.component.html',
	styleUrls: ['./promoslider.component.scss'],
})
export class PromosliderComponent implements OnInit {
	@ViewChild('promo', { read: ElementRef, static: true })
	promo: ElementRef
	@ViewChild('promo') slides: IonSlides

	@Output() promoSelect = new EventEmitter<PromoImages>()

	slideOpts = {
		spaceBetween: 10,
		slidesPerView: 1,
		grabCursor: true,
		effect: 'fade',
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		breakpoints: {
			480: {
				slidesPerView: 1,
				spaceBetweenSlides: 10,
			},
			520: {
				slidesPerView: 2,
				spaceBetweenSlides: 10,
			},
			960: {
				slidesPerView: 3,
				spaceBetweenSlides: 10,
			},
			1530: {
				slidesPerView: 4,
				spaceBetweenSlides: 10,
			},
		},
	}
	private logger = LoggerFactory.getLogger(this)
	constructor(
		public services: FacadeService,
		public modalController: ModalController,
		public gestureCtrl: GestureController
	) {
		this.env = this.services.env.get()
	}

	env: any
	slideImages

	ngOnInit() {
		this.slideImages = this.services.data.settings[0].promoImages.filter((item) => this.promoIsAvailable(item))
		this.slideImages = [...this.slideImages, ...this.slideImages, ...this.slideImages]
	}

	promoIsAvailable(p: PromoImages): boolean {
		if (!p.availability.length) return true // if no availability is set then it is available
		const d = new Date()
		return !!p.availability.find((a) => this.services.trading.isTrading(a, d))
	}

	async ngAfterViewInit() {
		let gesture = await this.gestureCtrl.create({
			el: this.promo.nativeElement,
			gestureName: 'header-swipe',
			gesturePriority: 10,
			threshold: 5,
			direction: 'y',
			passive: false,
		})
		gesture.enable(true)
	}

	ionSlideDrag() {
		this.logger.debug('drag')
		this.slides.startAutoplay()
	}

	ionSlideTap() {
		this.slides.stopAutoplay()
	}

	ionSlideTouchStart() {
		this.slides.stopAutoplay()
	}

	slidesHidden = false
	ionViewDidEnter() {
		this.slidesHidden = false
	}
}
