import { ModalController, NavParams } from '@ionic/angular'
import { Component, OnInit } from '@angular/core'
import * as moment from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { TradingService, LoggerFactory } from 'src/app/services/public-api'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'

moment.extend(isSameOrAfter)

@Component({
	selector: 'app-ordertimepicker',
	templateUrl: './ordertimepicker.component.html',
	styleUrls: ['./ordertimepicker.component.scss'],
})
export class OrdertimepickerComponent implements OnInit {
	days = []
	times = []
	daysToAdd = 5
	tradingTimes: any
	selectedTime: string = ''
	selectedDate: string = moment().format('ddd DD/MM')
	actualTime: string
	defaultDate: string

	private logger = LoggerFactory.getLogger(this)
	constructor(
		private modalController: ModalController,
		public navParams: NavParams,
		private trading: TradingService,
		private profile: CustomerProfileService
	) {}

	ngOnInit() {
		this.tradingTimes = this.trading.tradingTimes(this.profile.customer.currentSelection.orderingMethod.availability)
		this.buildDays()
		const originalTime = this.navParams.get('scheduleDateTime')
		if (originalTime && originalTime !== '') {
			this.selectedDate = moment(originalTime).format('ddd DD/MM')
			this.selectedTime = moment(originalTime).format('HH:mm')
			this.defaultDate = moment(originalTime).format('YYYY-MM-DD')
			this.buildTimes(this.defaultDate)
		} else {
			this.defaultDate = moment().format('YYYY-MM-DD')
			this.buildTimes(moment().format('YYYY-MM-DD'))
		}
	}

	public buildDays() {
		let orderDate = moment().startOf('day')
		for (let i = 1; i <= this.daysToAdd; i++) {
			this.days.push({
				name: i === 1 ? 'Today' : orderDate.format('ddd'),
				date: orderDate.format('DD/MM'),
				realDate: orderDate.format('YYYY-MM-DD'),
			})
			orderDate = orderDate.add(1, 'day')
		}
	}

	public buildTimes(day?: string) {
		let idx = 0
		const tradingHours = this.tradingTimes && this.tradingTimes[moment(day).day() + 1]
		let ctime = moment(day)
		this.logger.debug(tradingHours)
		this.times = []
		tradingHours.forEach((range) => {
			const starting = range.start.split(':').map((i) => parseInt(i))
			starting[1] = Math.floor(starting[1] / 15) * 15
			const ending = range.end.split(':')
			ending[1] = Math.floor(ending[1] / 15) * 15
			ctime = ctime.set('hour', starting[0]).set('minute', starting[1]).set('second', 0)
			const endTime = moment(day).set('hour', ending[0]).set('minute', ending[1]).set('second', 0)
			while (ctime.isBefore(endTime)) {
				if (ctime.isSameOrAfter(moment(), 'minute')) {
					this.times.push({
						index: idx,
						time: ctime.format('HH:mm').toString(),
						selected: this.selectedTime === ctime.format('HH:mm').toString(),
						realTime: ctime.toISOString(),
					})
					idx++
				}
				ctime = ctime.add(15, 'minute')
			}
		})
	}

	public selectDate(day: any) {
		this.selectedDate = moment(day).format('ddd DD/MM')
		this.buildTimes(day)
		this.selectTime(0)
	}

	public selectTime(timeId: number) {
		this.times.forEach((tm) => {
			if (tm.index === timeId) {
				tm.selected = true
				this.selectedTime = tm.time
				this.actualTime = tm.realTime
			} else {
				tm.selected = false
			}
		})
	}

	async closeModal(accepted: string) {
		if (accepted === 'accept') {
		}
		await this.modalController.dismiss(accepted === 'accept' ? this.actualTime : '')
	}
}
