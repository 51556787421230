import { Component, HostListener, OnInit } from '@angular/core'
import { MenuController, LoadingController, ModalController } from '@ionic/angular'
import { Router, NavigationEnd } from '@angular/router'
import { filter, map } from 'rxjs/operators'
import { FirebaseAuthRedirectService } from './login/firebaseui/services/firebase-auth-redirect.service'
import { FacadeService, PwaInstallService, LoggerFactory } from 'src/app/services/public-api'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'
import { Title } from '@angular/platform-browser'
import { Storage } from '@ionic/storage'
import { set, find } from 'lodash'
import { PaymentGatewayFacadeService } from 'src/app/payment-gateways/public-api'
import { Customer } from '@golo/models/lib/interfaces'

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	public selectedIndex = 0
	public appPages = [
		{
			title: 'Home',
			url: '/home',
			icon: 'home',
			isHidden: false,
		},
		{
			title: 'Menu',
			url: '/menu',
			icon: 'book',
			isHidden: true,
		},
		{
			title: 'Orders',
			url: '/orders',
			icon: 'cart',
			isHidden: true,
		},
		{
			title: 'Profile',
			url: '/profile',
			icon: 'person',
			isHidden: false,
		},
		{
			title: 'Notifications',
			url: '/notifications',
			icon: 'notifications',
			isHidden: false,
		},
		{
			title: 'Rewards',
			url: '/rewards',
			icon: 'gift',
			isHidden: true,
		},
		{
			title: 'Contact',
			url: '/contact',
			icon: 'call',
			isHidden: false,
		},
		{
			title: 'Our Story',
			url: '/story',
			icon: 'newspaper',
			isHidden: false,
		},
		{
			title: 'About',
			url: '/about',
			icon: 'information-circle',
			isHidden: false,
		},
		{
			title: 'FAQs',
			url: '/help',
			icon: 'help-circle',
			isHidden: false,
		},
	]

	activePath: string
	env: any
	ordersEnabled: false
	rewardsEnabled: false
	private logger = LoggerFactory.getLogger(this)
	constructor(
		private menu: MenuController,
		private router: Router,
		public loadingController: LoadingController,
		private authRedirect: FirebaseAuthRedirectService,
		private profile: CustomerProfileService,
		private gateways: PaymentGatewayFacadeService,
		private services: FacadeService,
		private title: Title,
		public storage: Storage,
		public modalController: ModalController,
		private pwInstall: PwaInstallService
	) {
		/**decoupling profile from services */
		this.profile.watch().subscribe((customer: Customer) => {
			this.services.appNote.setCustomer(customer)
		})
		this.services.cart.profileResetCurrentSelection$
			.asObservable()
			.pipe(
				map(() => {
					this.profile.resetCurrentSelection()
				})
			)
			.subscribe()

		this.router.events
			.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.activePath = event.url
			})
		this.env = this.services.env.get()
		this.services.data.loaded$
			.pipe(
				filter((e) => !!e),
				map(() => {
					this.pwInstall.promotePwa()
				})
			)
			.subscribe()
	}

	closeMenu() {
		this.menu.close()
	}

	ngOnInit() {
		this.authRedirect.handleRedirect()
		this.services.pwa.pollUpdateCheck()
		this.services.api
			.getPageTitle()
			.pipe(
				map((title) => {
					this.title.setTitle(title.title || 'GAAP Online Ordering')
				})
			)
			.subscribe()
	}

	menuOpened() {
		set(find(this.appPages, { title: 'Menu' }), 'isHidden', !this.services.data.settings[0]?.onlineOrdering)
		set(find(this.appPages, { title: 'Orders' }), 'isHidden', !this.services.data.settings[0]?.onlineOrdering)
		set(find(this.appPages, { title: 'Rewards' }), 'isHidden', !this.services.data.settings[0]?.loyaltyEnabled)
		set(find(this.appPages, { title: 'Our Story' }), 'isHidden', !this.services.data.settings[0]?.ourStory)
	}

	@HostListener('window:beforeinstallprompt', ['$event'])
	promotePwaInstall(e) {
		;(<any>window).gpposolo = {
			deferredPrompt: e,
		}
	}
}
