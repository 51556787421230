import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'
import { get } from 'lodash'
import { SwPush } from '@angular/service-worker'
import { LoadingController } from '@ionic/angular'
import { GuideService } from 'src/app/services/guide.service'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'

@Injectable({
	providedIn: 'root',
})
export class PaymentoptionsGuideGuard implements CanActivate {
	private logger = LoggerFactory.getLogger(this)
	constructor(
		private services: FacadeService,
		private profile: CustomerProfileService,
		public loadingController: LoadingController,
		private swPush: SwPush,
		private guide: GuideService
	) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this.logger.debug('canActivate -> next.url.toString()', next.url.toString())
		return this.services.data
			.localLoad()
			.toPromise()
			.then(() => this.subscribeToNotifications())
			.then(() => {
				if (!get(this.profile.customer, 'currentSelection.store')) {
					return this.profile.loadLocalData().toPromise()
				} else {
					return Promise.resolve()
				}
			})
			.then(() => {
				this.logger.debug('canActivate -> guide')
				return this.guide.runGuideChecks()
			})
	}

	async subscribeToNotifications() {
		try {
			const sub = await this.swPush.requestSubscription({
				serverPublicKey: this.services.data.vapidPublicKey,
			})
			this.services.api.addWebPushSubscriber(sub).subscribe()
		} catch {
			return Promise.resolve()
		}
	}
}
