import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
	Customer,
	FeedbackQuestion,
	FeedbackQuestions,
	FeedbackResponses,
	FnbPreparePaymentRequest,
	FnbPreparePaymentResponse,
	LoyaltyBasket,
	LoyaltyResponse,
	Order,
	OrderStatus,
	Product,
	RecursivePartial,
	Reward,
} from '@golo/models/lib/interfaces'
import { BehaviorSubject, fromEvent, Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { LoggerFactory } from '../helpers/logger-factory.class'
import { InitData } from './data-source.service'
import { EnvService } from './env.service'
import { ToasterService } from './toaster.service'

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	private logger = LoggerFactory.getLogger(this)
	offline$ = new BehaviorSubject<boolean>(false)
	offline = false
	constructor(private http: HttpClient, private env: EnvService, private toaster: ToasterService) {
		fromEvent(window, 'online')
			.pipe(
				map(() => {
					this.setOnline()
				})
			)
			.subscribe()

		fromEvent(window, 'offline')
			.pipe(
				map(() => {
					this.setOffline()
				})
			)
			.subscribe()
	}

	/**
	 * there is no way to know if the response is coming from the sw or from the net
	 */
	setOnline() {
		// check we can connect to the api on a non-cached endpoint
		fetch(this.env.get().api).then((res) => {
			this.logger.debug('setOnline -> res.ok', res.ok)
			if (res.ok) {
				this.offline = false
				this.offline$.next(this.offline)
			}
		})
	}

	setOffline() {
		this.logger.debug('setOffline')
		this.offline = true
		this.offline$.next(this.offline)
	}

	handleError(err: any) {
		this.logger.debug('handleError -> err.code: ', err.code)
		this.setOffline()
		this.toaster.dismissAllLoading()
		this.toaster.toast({
			text: `Currently offline, unable to complete request`,
			duration: 3000,
		})
	}

	refreshTheme(kiosk = false): Observable<void> {
		const backgroundversion = localStorage.getItem('backgroundversion') || '1'
		return this.http
			.get(`${this.env.get().api}/theme${kiosk ? '/kiosk' : ''}`, {
				responseType: 'text',
			})
			.pipe(
				tap(() => {
					this.setOnline()
				}),
				catchError((err: any) => {
					this.handleError(err)
					return of(null)
				}),
				map((data) => {
					if (data) {
						const pairs = data
							.split('\n')
							.map((line) => line.trim())
							.map((line) => {
								if (line.indexOf(':') !== -1) {
									return line.split(':').map((prop) => prop.trim())
								}
							})
							.map((pair: [string, string]) => {
								if (pair) {
									return [pair[0], pair[1].substring(0, pair[1].indexOf(';')).replace('!important', '').trim()]
								}
							})
							.filter((pair: [string, string]) => pair && pair.every((pair) => !!pair))
						pairs.push([
							'--background-texture-image',
							`url(${this.env.get().api}/pwa/icons/background-texture.webp?version=${backgroundversion})`,
						])
						pairs.forEach((pair: [string, string]) => {
							document.body.style.setProperty(pair[0], pair[1], 'important')
						})
					}

					return
				})
			)
	}

	initData(): Observable<InitData> {
		return this.http.get<any>(`${this.env.get().api}/sync`).pipe(
			tap(() => {
				this.setOnline()
			}),
			catchError((err: any) => {
				this.handleError(err)
				return of(null)
			})
		)
	}

	getPageTitle(): Observable<{ title: string }> {
		return this.http.get<any>(`${this.env.get().api}/pwa/title`).pipe(
			tap(() => {
				this.setOnline()
			}),
			catchError((err: any) => {
				this.handleError(err)
				return of(null)
			})
		)
	}

	searchProducts(searchTerm): Observable<Product[]> {
		return this.http
			.post<any[]>(`${this.env.get().api}/products/search`, {
				searchTerm,
			})
			.pipe(
				tap(() => {
					this.setOnline()
				}),
				catchError((err: any) => {
					this.handleError(err)
					return of(null)
				})
			)
	}

	addWebPushSubscriber(sub: any) {
		return this.http.post(`${this.env.get().api}/notifications/add-web-push-subscriber`, sub).pipe(
			tap(() => {
				this.setOnline()
			}),
			catchError((err: any) => {
				this.handleError(err)
				return of(null)
			})
		)
	}

	placeOrder(cart: Order, kiosk = false): Observable<any> {
		return this.http
			.post<any[]>(`${this.env.get().api}/${kiosk ? 'kioskorders' : 'orders'}/${cart.header.storeId}`, cart)
			.pipe(
				tap(() => {
					this.setOnline()
				}),
				catchError((err: any) => {
					this.handleError(err)
					return of(null)
				})
			)
	}

	orderStatus(orderNo: string, kiosk = false): Observable<OrderStatus> {
		return this.http.get<any>(`${this.env.get().api}/${kiosk ? 'kioskorders' : 'orders'}/status/${orderNo}`).pipe(
			tap(() => {
				this.setOnline()
			}),
			catchError((err: any) => {
				this.handleError(err)
				return of(null)
			})
		)
	}

	storeStatus(storeId: string): Observable<any> {
		return this.http.get<any>(`${this.env.get().api}/stores/status/${storeId}`).pipe(
			tap(() => {
				this.setOnline()
			}),
			catchError((err: any) => {
				this.handleError(err)
				return of(null)
			})
		)
	}

	upsertCustomer(partial: RecursivePartial<Customer>): Observable<void> {
		return this.http
			.post(`${this.env.get().api}/customers`, partial)
			.pipe(map(() => null))
			.pipe(
				tap(() => {
					this.setOnline()
				}),
				catchError((err: any) => {
					this.handleError(err)
					return of(null)
				})
			)
	}

	getCustomer(): Observable<Customer> {
		return this.http
			.get(`${this.env.get().api}/customers`)
			.pipe(
				map((res) => {
					return <Customer>res
				})
			)
			.pipe(
				tap(() => {
					this.setOnline()
				}),
				catchError((err: any) => {
					this.handleError(err)
					return of(null)
				})
			)
	}

	getOrderDetails(orderId: string, kiosk = false) {
		return this.http
			.get(`${this.env.get().api}/${kiosk ? 'kioskorders' : 'orders'}/${orderId}`)
			.pipe(
				map((res) => {
					return res
				})
			)
			.pipe(
				tap(() => {
					this.setOnline()
				}),
				catchError((err: any) => {
					this.handleError(err)
					return of(null)
				})
			)
	}

	getHistoricOrders(limit: number, skip: number): Observable<Order[]> {
		return this.http
			.get(`${this.env.get().api}/customers/orders?limit=${limit}&skip=${skip}`)
			.pipe(
				map((res) => {
					return res as Order[]
				})
			)
			.pipe(
				tap(() => {
					this.setOnline()
				}),
				catchError((err: any) => {
					this.handleError(err)
					return of(null)
				})
			)
	}

	loyaltyLookup(basket: LoyaltyBasket): Observable<Reward[]> {
		return this.http
			.post(`${this.env.get().api}/loyalty/lookup`, basket)
			.pipe(
				map((res: any) => {
					if (res.success) {
						return res.rewards as Reward[]
					}
				})
			)
			.pipe(
				tap(() => {
					this.setOnline()
				}),
				catchError((err: any) => {
					this.handleError(err)
					return of(null)
				})
			)
	}

	loyaltyAccumulate(basket: LoyaltyBasket): Observable<void> {
		return this.http
			.post(`${this.env.get().api}/loyalty/accumulate`, basket)
			.pipe(
				map((res: any) => {
					return res
				})
			)
			.pipe(
				tap(() => {
					this.setOnline()
				}),
				catchError((err: any) => {
					this.handleError(err)
					return of(null)
				})
			)
	}

	getEarnCode(): Observable<any> {
		return this.http
			.get(`${this.env.get().api}/loyalty/earncode`)
			.pipe(
				map((res) => {
					return res
				})
			)
			.pipe(
				tap(() => {
					this.setOnline()
				}),
				catchError((err: any) => {
					this.handleError(err)
					return of(null)
				})
			)
	}

	getLoyaltyRewards(storeId?: string): Observable<LoyaltyResponse> {
		return this.http
			.get(`${this.env.get().api}/loyalty/lookup${storeId ? `/${storeId}` : ''}`)
			.pipe(
				map((res: any) => {
					this.logger.debug('getLoyaltyRewards: ', res)
					return res as LoyaltyResponse
				})
			)
			.pipe(
				tap(() => {
					this.setOnline()
				}),
				catchError((err: any) => {
					this.handleError(err)
					return of(null)
				})
			)
	}

	getFeedbackQuestions(group: string = 'golo'): Observable<FeedbackQuestion[]> {
		return this.http.get(`${this.env.get().api}/feedback/${group}`).pipe(
			map((res: any) => {
				this.logger.debug('getFeedbackQuestions: ', res)
				return res as FeedbackQuestions
			}),
			catchError((err: any) => {
				this.handleError(err)
				return of(null)
			})
		)
	}

	sendFeedbackResponse(response: FeedbackResponses): Observable<any> {
		return this.http.post(`${this.env.get().api}/feedback`, response).pipe(
			map((res: any) => {
				//navigate away. maybe show thank you toast?
				this.logger.debug('sendFeedbackResponse: ', res)
				return res as any
			}),
			catchError((err: any) => {
				this.handleError(err)
				return of(null)
			})
		)
	}

	getFAQ(): Observable<any> {
		return this.http.get(`${this.env.get().api}/faq`).pipe(
			map((res: any) => {
				this.logger.debug('getFAQ: ', res)
				return res
			}),
			catchError((err: any) => {
				this.handleError(err)
				return of(null)
			})
		)
	}
	postFAQ(response): Observable<any> {
		return this.http.post(`${this.env.get().api}/faq`, response).pipe(
			map((res: any) => {
				this.logger.debug('sendFaqResponse: ', res)
				return res as any
			}),
			catchError((err: any) => {
				this.handleError(err)
				return of(null)
			})
		)
	}

	getFaqCategory(): Observable<any> {
		return this.http.get(`${this.env.get().api}/faq/category`).pipe(
			map((res: any) => {
				this.logger.debug('getFAQCategory: ', res)
				return res
			}),
			catchError((err: any) => {
				this.handleError(err)
				return of(null)
			})
		)
	}

	postFaqCategory(response): Observable<any> {
		return this.http.post(`${this.env.get().api}/faq/category`, response).pipe(
			map((res: any) => {
				this.logger.debug('sendFAQCategoryResponse: ', res)
				return res as any
			}),
			catchError((err: any) => {
				this.handleError(err)
				return of(null)
			})
		)
	}
}
