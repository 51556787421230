import { DistancePipe } from './distance.pipe'
import { TimePipe } from 'src/app/pipes/time.pipe'
import { AmountPipe } from 'src/app/pipes/amount.pipe'
import { FunctionFormatPipe } from 'src/app/pipes/function-format.pipe'
import { CurrencyAmountPipe } from 'src/app/pipes/currency-amount.pipe'
import { SafePipe } from 'src/app/pipes/safe.pipe'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

@NgModule({
	declarations: [CurrencyAmountPipe, AmountPipe, TimePipe, DistancePipe, FunctionFormatPipe, SafePipe],
	exports: [CurrencyAmountPipe, AmountPipe, TimePipe, DistancePipe, FunctionFormatPipe, SafePipe],
	imports: [CommonModule],
})
export class SharedPipes {}
