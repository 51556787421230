import { Subject } from 'rxjs'
import { Location } from '@golo/models/lib/interfaces'
import { isObject } from 'lodash'

class PlacesStatic {
	selection$ = new Subject()
	init(current: Location, inputElOrId: any, mapElOrId?: any) {
		let map
		if (mapElOrId) {
			if (!isObject(mapElOrId)) {
				mapElOrId = document.getElementById(mapElOrId)
			}
			map = new (<any>window).google.maps.Map(mapElOrId, {
				center: {
					lat: current.latitude,
					lng: current.longitude,
				},
				zoom: 13,
			})
		}
		if (!isObject(inputElOrId)) {
			inputElOrId = document.getElementById(inputElOrId)
		}
		// Create the autocomplete object, restricting the search predictions to
		// geographical location types.
		let autocomplete = new (<any>window).google.maps.places.Autocomplete(inputElOrId, {
			types: ['geocode'],
			componentRestrictions: { country: ['ZA'] },
		})
		// Bias the autocomplete object to the user's geographical location,
		// as supplied by the browser's 'navigator.geolocation' object.
		let geolocation = {
			lat: current.latitude,
			lng: current.longitude,
		}
		let circle = new (<any>window).google.maps.Circle({
			center: geolocation,
			radius: 100,
		})
		autocomplete.setBounds(circle.getBounds())

		// Avoid paying for data that you don't need by restricting the set of
		// place fields that are returned to just the address components.
		autocomplete.setFields(['address_component', 'geometry'])

		let marker = new (<any>window).google.maps.Marker({
			map: map,
			anchorPoint: new (<any>window).google.maps.Point(0, -29),
		})
		const wait = setInterval(() => {
			const input = document.getElementById('geoadd')
			if (input) {
				input.focus()
			}
		}, 100)
		// When the user selects an address from the drop-down, populate the
		// address fields in the form.
		autocomplete.addListener('place_changed', () => {
			clearInterval(wait)
			let place = autocomplete.getPlace()
			this.selection$.next(place)
			if (place.geometry && map) {
				if (place.geometry.viewport) {
					map.fitBounds(place.geometry.viewport)
				} else {
					map.setCenter(place.geometry.location)
					map.setZoom(17) // Why 17? Because it looks good.
				}
				marker.setPosition(place.geometry.location)
				marker.setVisible(true)
			}
		})
		return this
	}
}
export const Places = new PlacesStatic()
