import { Component, OnInit, Input } from '@angular/core'
import { Campaign, Reward } from '@golo/models/lib/interfaces'
import { ModalController } from '@ionic/angular'
import { QRCodeVoucherComponent } from 'src/app/components/qrcode-voucher/qrcode-voucher.component'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'
@Component({
	selector: 'app-reward',
	templateUrl: './reward.component.html',
	styleUrls: ['./reward.component.scss'],
})
export class RewardComponent implements OnInit {
	@Input() reward: Reward & Campaign
	env: any
	colSize = '12'
	private logger = LoggerFactory.getLogger(this)
	constructor(private modalcontroller: ModalController, private services: FacadeService) {}

	ngOnInit() {
		this.env = this.services.env.get()
		if (this.reward.progress) {
			this.colSize = '8'
		}
	}

	async generateQrCode() {
		if (this.reward.dashCode) {
			let qrCodeViewer = await this.modalcontroller.create({
				component: QRCodeVoucherComponent,
				componentProps: { reward: this.reward },
			})
			qrCodeViewer.present()
		}
	}
}
