import { HttpClient } from '@angular/common/http';
import { isString, forEach, isObject } from 'lodash';
import dayjs from 'dayjs';

export enum LogLevel {
	DEBUG = 'debug',
	INFO = 'info',
	WARN = 'warn',
	ERROR = 'error',
}

class LoggerFactoryStatic {
	private logLevelOrder = ['debug', 'info', 'warn', 'error', 'disable'];
	private level = 'debug';
	private backendLogBuffer = [];
	private backendLogBufferLimit = 5;
	private showObjects = true;
	private consoleEnabled = false;
	private http: HttpClient;
	private url: string;

	constructor() {}

	setHttpClient(http: HttpClient, url: string) {
		this.http = http;
		this.url = url;
	}

	setShowObjects(val: boolean) {
		this.showObjects = val;
	}

	setLevel(lev: string) {
		this.level = lev;
	}

	setBufferLimit(limit: number) {
		this.backendLogBufferLimit = limit;
	}

	setEnableConsole(enable: boolean) {
		this.consoleEnabled = enable;
	}

	private addToBuffer(logMsg: any) {
		if (this.backendLogBuffer.length >= this.backendLogBufferLimit) {
			this.url && this.http.put(this.url, this.backendLogBuffer).subscribe();
			this.backendLogBuffer = [];
		} else {
			this.backendLogBuffer.push(logMsg);
		}
	}

	private getTimeFormat() {
		return dayjs().format('MMMM Do HH:mm:ss:SSSS');
	}

	getLogger(loggerId: string | any) {
		let name = isString(loggerId) ? loggerId : loggerId.constructor.toString().match(/\w+/g)[1];
		return {
			debug: (...args) => {
				if (this.isPassLevel('debug')) {
					let msgArgs = [`[DEBUG] ${name}: `, this.handleLogArgs(args)].join('');
					this.addToBuffer(msgArgs);

					this.consoleEnabled && console.log(`%c[${this.getTimeFormat()}] ${msgArgs}`, this.colorStyle('darkorange'));
				}
			},
			info: (...args) => {
				if (this.isPassLevel('info')) {
					let msgArgs = [`[INFO] ${name}: `, this.handleLogArgs(args)].join('');
					this.addToBuffer(msgArgs);
					this.consoleEnabled && console.log(`%c[${this.getTimeFormat()}] ${msgArgs}`, this.colorStyle('green'));
				}
			},
			warn: (...args) => {
				if (this.isPassLevel('warn')) {
					let msgArgs = [`[WARN] ${name}: `, this.handleLogArgs(args)].join('');
					this.addToBuffer(msgArgs);
					this.consoleEnabled && console.log(`%c[${this.getTimeFormat()}] ${msgArgs}`, this.colorStyle('yellow'));
				}
			},
			error: (...args) => {
				if (this.isPassLevel('error')) {
					let msgArgs = [`[ERROR] ${name}: `, this.handleLogArgs(args)].join('');
					this.addToBuffer(msgArgs);
					this.consoleEnabled && console.log(`%c[${this.getTimeFormat()}] ${msgArgs}`, this.colorStyle('red'));
				}
			},
		};
	}

	handleLogArgs(args: any[]): string {
		let logString = '';
		forEach(args, (arg) => {
			if (isObject(arg)) {
				logString += this.showObjects ? ` ${this.stringify(arg)}` : ' [object]';
			} else {
				logString += ` ${arg}`;
			}
		});
		return logString;
	}

	isPassLevel(forLev: string): boolean {
		let indexOfFor = this.logLevelOrder.indexOf(forLev);
		let indexOfLev = this.logLevelOrder.indexOf(this.level);
		return indexOfFor >= indexOfLev;
	}

	colorStyle(color) {
		return `color: ${color};`;
	}

	stringify(obj, replacer?, spaces?, cycleReplacer?) {
		return JSON.stringify(obj, this.serializer(replacer, cycleReplacer), spaces);
	}

	serializer(replacer, cycleReplacer) {
		var stack = [],
			keys = [];

		if (cycleReplacer == null)
			cycleReplacer = function (key, value) {
				if (stack[0] === value) return '[Circular ~]';
				return '[Circular ~.' + keys.slice(0, stack.indexOf(value)).join('.') + ']';
			};

		return function (key, value) {
			if (stack.length > 0) {
				var thisPos = stack.indexOf(this);
				~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
				~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
				if (~stack.indexOf(value)) value = cycleReplacer.call(this, key, value);
			} else stack.push(value);

			return replacer == null ? value : replacer.call(this, key, value);
		};
	}
}

export const LoggerFactory = new LoggerFactoryStatic();
