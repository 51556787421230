<ion-toolbar *ngIf="show" class="global-footer" color="primary">
	<ion-grid class="ion-no-padding">
		<ion-row>
			<ion-col class="ion-text-left" size="3">
				<ion-label class="footer-qty"
					>{{ cartItemCount }}
					<ion-label class="footer-qty" *ngIf="cartItemCount == 1">ITEM</ion-label>
					<ion-label class="footer-qty" *ngIf="cartItemCount !== 1">ITEMS</ion-label>
				</ion-label>
			</ion-col>
			<ion-col class="ion-text-center" size="6">
				<ion-label class="footer-button">{{ text }}</ion-label>
			</ion-col>
			<ion-col class="ion-text-right" size="3">
				<ion-label class="footer-total">{{ cartTotal | currencyAmount }}</ion-label>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-toolbar>
