<ion-header>
	<ion-toolbar color="primary">
		<ion-title>{{ productName }}</ion-title>
		<ion-buttons slot="start">
			<ion-button (click)="closePopover()"
				><ion-icon slot="icon-only" name="arrow-back" aria-label="Back"></ion-icon
			></ion-button>
		</ion-buttons>
	</ion-toolbar>
	<ion-toolbar class="prep-header">
		<ion-grid>
			<ion-row>
				<ion-col size="8" class="ion-text-wrap">
					<ion-label
						class="large-font ion-text-wrap"
						[innerHTML]="prepMessage?.displayName || prepMessage.name"
					></ion-label>
				</ion-col>
				<ion-col size="4" class="ion-text-right">
					<ion-badge *ngIf="!!prepMessage.minimumNumOfCalls" class="badge-success"
						>MIN: {{ prepMessage.minimumNumOfCalls }}</ion-badge
					>
					<ion-badge *ngIf="!!prepMessage.maximumNumOfCalls" color="warning" class="status-badge"
						>MAX: {{ prepMessage.maximumNumOfCalls }}</ion-badge
					>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-toolbar>
</ion-header>
<ion-content>
	<ion-card>
		<ion-list class="card-list">
			<ion-item *ngFor="let message of selectedPreps">
				<ion-label class="ion-text-wrap flex-down"
					>{{ message.displayName }}
					<ion-badge *ngIf="!message.available" class="badge-warning">Unavailable</ion-badge>
					<ion-badge class="price-badge badge-tertiary" *ngIf="message.addonPrice !== 0 && message.available"
						>{{ message.addonPrice | currencyAmount }}
					</ion-badge>
				</ion-label>

				<ion-buttons class="ion-no-padding">
					<ion-button
						slot="start"
						(click)="decrease(message)"
						color="medium"
						[disabled]="!message.available"
						aria-label="Decrease"
					>
						<ion-icon slot="icon-only" name="remove-circle"></ion-icon>
					</ion-button>
					<ion-label class="large-font">{{ message.selectedCount }} </ion-label>
					<ion-button (click)="increase(message)" color="medium" [disabled]="!message.available" aria-label="Increase">
						<ion-icon slot="icon-only" name="add-circle"></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-item>
		</ion-list>
	</ion-card>
	<ion-toolbar class="global-footer" color="primary" (click)="closePopover()">
		<ion-grid class="ion-no-padding">
			<ion-row>
				<ion-col class="ion-text-left" size="7">
					<ion-label class="footer-qty">{{ totalSelected }} SELECTED</ion-label>
				</ion-col>
				<ion-col class="ion-text-right" size="5">
					<ion-label class="footer-button">ADD TO ORDER</ion-label>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-toolbar>
</ion-content>
