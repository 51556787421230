import { Customer, LoyaltyBasket, Order, PaymentMethod, PaymentProviders, Reward } from '@golo/models/lib/interfaces'
import { LoadingController } from '@ionic/angular'
import { get } from 'lodash'
import { Observable, of } from 'rxjs'
import { PaymentoptionsPage } from 'src/app/pages/paymentoptions/paymentoptions.page'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'
import { AbstractPaymentGatewayService } from 'src/app/payment-gateways/public-api'
import { HttpClient } from '@angular/common/http'
import { concatMap, map } from 'rxjs/operators'

export class AdumoonlinePaymentGatewayService extends AbstractPaymentGatewayService {
	private logger = LoggerFactory.getLogger(this)
	constructor(public services: FacadeService, public loadingController: LoadingController, public http: HttpClient) {
		super(services, loadingController, http)
		this.typeKey = PaymentProviders.ADUMOONLINE
	}

	prep(orderDetails: [Order, LoyaltyBasket], customer: Customer, method: PaymentMethod) {
		const [order, rewards] = orderDetails
		const url = this.services.data.wirecardUrl

		this.logger.debug('prep -> url', url)
		if (url) {
			this.logger.debug('prep -> method', method)
			const orderAmount = this.services.cart.getOrderTotal()
			const RedirectSuccessfulURL = `${this.services.env.get().api}/payment-gateway/callback/success/${
				PaymentProviders.ADUMOONLINE
			}`
			const RedirectFailedURL = `${this.services.env.get().api}/payment-gateway/callback/failure/${
				PaymentProviders.ADUMOONLINE
			}`
			this.logger.debug(
				'prep -> RedirectSuccessfulURL: ',
				RedirectSuccessfulURL,
				', RedirectFailedURL: ',
				RedirectFailedURL
			)
			const store = get(customer, 'currentSelection.store._id')
			let payload: any = {
				Mode: '1',
				MerchantID: method.merchantId,
				ApplicationID: method.applicationId,
				MerchantReference: order.header.orderNo,
				Amount: orderAmount,
				txtCurrencyCode: 'ZAR',
				RedirectSuccessfulURL,
				RedirectFailedURL,
				Variable1: store,
				Variable2: customer._id,
			}

			order.payment.value = orderAmount
			return this.services.api.getCustomer().pipe(
				concatMap((cust: Customer) => {
					const puid = get(cust, 'puid')
					puid && (payload.puid = puid)
					return super.prepare({ payload, orderDetails }).pipe(
						map((response) => {
							return {
								url,
								payload,
								response,
							}
						})
					)
				})
			)
		} else {
			throw Error('wirecard url not available')
		}
	}

	redirectBack(
		page: PaymentoptionsPage,
		orderDetails?: [Order, LoyaltyBasket],
		response?: {
			success: boolean
			reasons?: string
			meta?: string
		}
	): Observable<[Order, LoyaltyBasket] | null> {
		this.logger.debug(`redirectBack -> response`, response)
		const [order, rewards] = orderDetails
		//recreate form state before redirect
		page.setPaymentMethod(order.payment.description)
		this.services.cart.updatePaymentDetails({ serviceFee: order.payment.serviceFee })
		if (response.success) {
			return of([order, rewards])
		} else {
			return of(null)
		}
	}

	redirect(orderDetails: [Order, LoyaltyBasket], options: any) {
		let form = document.createElement('form')
		document.body.appendChild(form)
		form.method = 'post'
		form.action = options.url
		for (let name in options.payload) {
			let input = document.createElement('input')
			input.type = 'hidden'
			input.name = name
			input.value = options.payload[name]
			form.appendChild(input)
		}
		//token
		if (options.response.token) {
		const token = document.createElement('input')
		token.type = 'hidden'
		token.name = 'Token'
		token.value = options.response.token
		form.appendChild(token)
			
		}
		//token
		this.logger.debug('redirect -> form', form)
		form.submit()
	}
}
