<ion-header>
	<ion-toolbar color="primary" mode="ios">
		<ion-title>{{ selectedDate }} {{ selectedTime }} </ion-title>
		<ion-buttons slot="start">
			<ion-button (click)="closeModal('cancel')"
				><ion-icon slot="icon-only" name="arrow-back" aria-label="Back"></ion-icon
			></ion-button>
		</ion-buttons>
		<ion-buttons slot="end">
			<ion-button (click)="closeModal('cancel')"
				><ion-icon slot="icon-only" name="close-circle" aria-label="Close"></ion-icon
			></ion-button>
		</ion-buttons>
	</ion-toolbar>
	<ion-toolbar>
		<ion-segment scrollable="true" [value]="defaultDate" (ionChange)="selectDate($event.detail.value)">
			<ion-segment-button *ngFor="let iday of days" [value]="iday.realDate">
				{{ iday.name }} <span> {{ iday.date }} </span></ion-segment-button
			>
		</ion-segment>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-card>
		<ion-radio-group>
			<ion-item *ngFor="let tm of times">
				<ion-label>{{ tm.time }}</ion-label>
				<ion-radio
					slot="end"
					color="secondary"
					[value]="tm.time"
					(click)="selectTime(tm.index)"
					[class.radio-checked]="selectedTime == tm.time"
				></ion-radio>
			</ion-item>
		</ion-radio-group>
	</ion-card>
	<ion-toolbar class="global-footer" color="primary">
		<ion-button class="global-footer-button" (click)="closeModal('accept')" expand="full" aria-label="Done"
			>DONE</ion-button
		>
	</ion-toolbar>
</ion-content>
