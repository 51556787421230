import { Component, Input, OnInit } from '@angular/core'
import { LoggerFactory } from 'src/app/services/public-api'
import { ModalController } from '@ionic/angular'
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode'

@Component({
	selector: 'app-earn-code',
	templateUrl: './earn-code.component.html',
	styleUrls: ['./earn-code.component.scss'],
})
export class EarnCodeComponent implements OnInit {
	private logger = LoggerFactory.getLogger(this)
	constructor(private modalController: ModalController) {}
	@Input() qrCode
	elementType = NgxQrcodeElementTypes.URL
	correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH

	async ngOnInit() {}

	close() {
		this.modalController.dismiss()
	}
}
