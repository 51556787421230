import { Inject, Injectable, Type } from '@angular/core'
import { Storage } from '@ionic/storage'
import { AbstractPaymentGatewayService, GATEWAY_KEY } from './abstract-payment-gateway.service'
import { from, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { GATEWAY_IMPLEMENTATIONS } from './payment-gateways.module'
import { FacadeService } from 'src/app/services/public-api'
import { LoadingController } from '@ionic/angular'
import { HttpClient } from '@angular/common/http'

@Injectable({
	providedIn: 'root',
})
export class PaymentGatewayFacadeService {
	/**
	 * NB: this.typeKey needs to be defined in the concrete class constructor and be the same as the concrete class service name used here
	 * eg: this.typeKey = 'fnb'
	 */
	singletons = new Map<string, AbstractPaymentGatewayService>()
	constructor(
		public storage: Storage,
		public services: FacadeService,
		public loadingController: LoadingController,
		public http: HttpClient,
		@Inject(GATEWAY_IMPLEMENTATIONS)
		private gatewayImplMap: {
			[key: string]: Type<AbstractPaymentGatewayService>
		}
	) {
		Object.keys(this.gatewayImplMap).forEach((key) => {
			this.singletons.set(key, new this.gatewayImplMap[key](services, loadingController, http))
		})
	}

	get(): Observable<AbstractPaymentGatewayService | null> {
		return from(this.storage.get(GATEWAY_KEY)).pipe(
			map((key) => {
				return (key && this.singletons.get(key)) || null
			})
		)
	}
}
