<ion-card>
	<ion-card-header>
		<ion-grid>
			<ion-row>
				<ion-col size="9">
					<ion-card-subtitle
						class="ion-text-wrap prep-name"
						[innerHTML]="prepMessage?.displayName || prepMessage.name"
					></ion-card-subtitle>
				</ion-col>
				<ion-col size="3" class="ion-text-right">
					<ion-badge *ngIf="!prepMessage.minimumNumOfCalls" class="status-badge badge-success" color="success"
						>OPTIONAL</ion-badge
					>
					<ion-badge *ngIf="!!prepMessage.minimumNumOfCalls" class="status-badge badge-warning">REQUIRED</ion-badge>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-card-header>
	<ion-card-content>
		<ion-chip
			*ngFor="let addon of prepMessage.messageProducts"
			[ngClass]="{
				selectedChip: addon.id === (this.prepMessage.selectedPreps[0] ? this.prepMessage.selectedPreps[0].id : null)
			}"
			(click)="selectAddOn(addon)"
			(value)="(addon.id)"
			>{{ addon.displayName }}
			<span style="white-space: pre" *ngIf="addon.price || (addon.productPrice && addon.useProductPrice)">
				{{ (addon.useProductPrice ? addon.productPrice : addon.price) | currencyAmount }}</span
			>
		</ion-chip>
	</ion-card-content>
</ion-card>

<!--<app-prepmessages *ngFor="let pm of prepMessage.prepMessages" [prepMessage]="pm" (prepChange)="onPrepSelectionChanged($event)"></app-prepmessages>-->
