import { PipeTransform, Pipe } from '@angular/core'
@Pipe({
	name: 'functionFormat',
})
export class FunctionFormatPipe implements PipeTransform {
	transform(initialValue: any, functionReference: Function, ...functionArguments: any[]): any {
		functionArguments.unshift(initialValue)
		return functionReference.apply(null, functionArguments)
	}
}
export const FunctionFormat = new FunctionFormatPipe()
