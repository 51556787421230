import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'

@Injectable({
	providedIn: 'root',
})
export class CartGuideGuard implements CanActivate {
	private logger = LoggerFactory.getLogger(this)
	constructor(private services: FacadeService, private profile: CustomerProfileService, private router: Router) {}

	/**
	 * Test Cases
	 *
	 * To cart Page:
	 * 	if profile has no phone number -> route to profile
	 */
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!this.profile.hasPhoneNumber || !this.profile.customer.details.displayName) {
			return this.noPhoneNumber()
				.then(() => this.router.navigate(['/profile', { returnTo: next.url }]))
				.then(() => false)
		} else {
			return true
		}
	}

	private async noPhoneNumber() {
		return this.services.toast.toast({ text: 'Please complete your profile details' })
	}
}
