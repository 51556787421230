<ion-card>
	<ion-card-header>
		<ion-grid>
			<ion-row>
				<ion-col size="9">
					<ion-card-subtitle
						class="ion-text-wrap prep-name"
						[innerHTML]="prepMessage?.displayName || prepMessage.name"
					></ion-card-subtitle>
				</ion-col>
				<ion-col size="3" class="ion-text-right">
					<ion-badge *ngIf="!prepMessage.minimumNumOfCalls" class="status-badge badge-success">OPTIONAL</ion-badge>
					<ion-badge *ngIf="!!prepMessage.minimumNumOfCalls" class="status-badge badge-warning">REQUIRED</ion-badge>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-card-header>
	<ion-card-content (click)="addPrep()">
		<ion-item *ngIf="!prepMessage.selectedPreps.length">
			<ion-label class="chip-placeholder">Please select an option...</ion-label>
		</ion-item>
		<ion-chip
			class="selectedChip"
			*ngFor="let prep of prepMessage.selectedPreps; let i = index"
			(click)="removePrep(i, $event)"
		>
			{{ prep.displayName }}
			<span style="white-space: pre" *ngIf="prep.addonPrice || prep.productPrice">
				{{ (prep.useProductPrice ? prep.productPrice : prep.addonPrice) | currencyAmount }}</span
			>
			<ion-icon class="selectedChip" name="close-circle"></ion-icon>
		</ion-chip>
	</ion-card-content>
</ion-card>
