import { Component, OnInit, Input } from '@angular/core'
import { merge } from 'rxjs'
import { CartService, LoggerFactory } from 'src/app/services/public-api'

@Component({
	selector: 'app-cartfooter',
	templateUrl: './cartfooter.component.html',
	styleUrls: ['./cartfooter.component.scss'],
})
export class CartfooterComponent implements OnInit {
	@Input() text: string
	show = false
	cartTotal = 0
	cartItemCount = 0
	private logger = LoggerFactory.getLogger(this)
	constructor(private cart: CartService) {
		merge(this.cart.watchItems(), this.cart.watchPaymentDetails()).subscribe(() => {
			this.cartTotal = this.cart.getOrderTotal()
			this.cartItemCount = this.cart.getCartItemCount()
			this.show = !!this.cartItemCount
		})
	}

	ngOnInit() {}
}
