import { InjectionToken, ModuleWithProviders, NgModule, Type } from '@angular/core'
import { AbstractPaymentGatewayService } from './abstract-payment-gateway.service'
// import { PaymentGatewayFacadeService } from './payment-gateway-facade.service'

export const GATEWAY_IMPLEMENTATIONS = new InjectionToken<{ [key: string]: Type<AbstractPaymentGatewayService> }>(
	'GATEWAY_IMPLEMENTATIONS'
)

@NgModule()
export class PaymentGatewaysModule {
	static forRoot(gatewaysImpls: {
		[key: string]: Type<AbstractPaymentGatewayService>
	}): ModuleWithProviders<PaymentGatewaysModule> {
		return {
			ngModule: PaymentGatewaysModule,
			providers: [{ provide: GATEWAY_IMPLEMENTATIONS, useValue: gatewaysImpls }],
		}
	}
}
