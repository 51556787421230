import { Component, Input, Output, EventEmitter } from '@angular/core'
import { FeedbackQuestion } from '@golo/models/lib/interfaces/feedbacks'

@Component({
	selector: 'app-feedback-rating',
	templateUrl: './feedback-rating.component.html',
	styleUrls: ['./feedback-rating.component.css'],
})
export class FeedbackRatingComponent {
	@Input() rating: number
	@Input() feedbackQuestion: FeedbackQuestion

	@Output() ratingChange: EventEmitter<number> = new EventEmitter()

	constructor() {}

	rate(index: number) {
		this.rating = index
		this.ratingChange.emit(this.rating)
	}

	getColor(index: number, rating) {
		if (index > rating) {
			return 'default'
		}
		switch (rating) {
			case 1:
			case 2:
				return 'danger'
			case 3:
				return 'warning'
			case 4:
			case 5:
				return 'success'
			default:
				return 'default'
		}
	}
}
