import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FeedbackQuestion } from '@golo/models/lib/interfaces/feedbacks'

@Component({
	selector: 'app-feedback-question',
	templateUrl: './feedback-question.component.html',
	styleUrls: ['./feedback-question.component.css'],
})
export class FeedbackQuestionComponent {
	@Input() currentAnswer: boolean
	@Input() feedbackQuestion: FeedbackQuestion
	@Output() currentAnswerChange: EventEmitter<boolean> = new EventEmitter()

	constructor() {}

	submitAnswer(answer: string) {
		this.currentAnswer = this.yesNoToBoolean(answer)
		this.currentAnswerChange.emit(this.currentAnswer)
	}

	yesNoToBoolean(s: string): boolean {
		return s == 'Yes' ? true : false
	}

	booleanToYesNo(b: boolean): string {
		return b ? 'Yes' : 'No'
	}
}
