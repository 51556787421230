export const adjectives = [
	'alluring',
	'amazing',
	'aperitive',
	'appetizing',
	'awesome',
	'brilliant',
	'cool',
	'delectable',
	'delightful',
	'elegant',
	'enchanting',
	'engaging',
	'enjoyable',
	'excellent',
	'fair',
	'fantastic',
	'favourable',
	'flavorful',
	'flavorsome',
	'flavory',
	'gleaming',
	'glimmering',
	'glowing',
	'gripping',
	'heavenly',
	'hilarious',
	'incredible',
	'mellow',
	'moving',
	'palatable',
	'perfect',
	'pleasant',
	'powerful',
	'remarkable',
	'rousing',
	'savoury',
	'shining',
	'sleek',
	'sparkling',
	'spicy',
	'splendid',
	'stellar',
	'super',
	'sweetened',
	'tasteful',
	'tasty',
	'tempting',
	'unique',
	'vibrant',
	'vivid',
	'zestful',
	'zingy',
	'glazed',
	'assortment',
	'infused',
	'plain',
	'reduce',
	'refreshing',
	'seasoned',
	'fresh',
	'balanced',
	'aromatic',
	'intense',
	'generous',
	'lite',
	'famous',
	'golden',
	'lively',
]

export const foods = [
	'acorn',
	'tuna',
	'alfalfa',
	'almond',
	'antelope',
	'apple',
	'apples',
	'applesauce',
	'artichoke',
	'arugula',
	'asparagus',
	'avocado',
	'bagel',
	'banana',
	'barley',
	'basil',
	'beans',
	'beet',
	'bisque',
	'bluefish',
	'bread',
	'broccoli',
	'brownie',
	'bruschetta',
	'burger',
	'burrito',
	'butter',
	'cabbage',
	'calamari',
	'calzone',
	'cappuccino',
	'carrots',
	'cashew',
	'celery',
	'cereal',
	'cheddar',
	'cheese',
	'cherries',
	'chips',
	'chowder',
	'chutney',
	'cloves',
	'coffee',
	'cola',
	'cooldrink',
	'corn',
	'cranberry',
	'cream',
	'cucumber',
	'cupcakes',
	'curry',
	'dates',
	'dips',
	'donuts',
	'dumplings',
	'edamame',
	'eggrolls',
	'eggs',
	'fennel',
	'figs',
	'fish',
	'fondu',
	'fries',
	'fritters',
	'garlic',
	'ginger',
	'gnocchi',
	'granola',
	'grapes',
	'grits',
	'guacamole',
	'gumbo',
	'haddock',
	'hake',
	'halibut',
	'hazelnut',
	'honey',
	'hummus',
	'ice cream',
	'jalapeno',
	'jelly',
	'juice',
	'kabobs',
	'kale',
	'ketchup',
	'kingfish',
	'kiwi',
	'lasagne',
	'lemon',
	'lemongrass',
	'lentils',
	'lettuce',
	'linguine',
	'macaroni',
	'mango',
	'milk',
	'milkshake',
	'muffins',
	'noodles',
	'nougat',
	'olives',
	'omelette',
	'onion',
	'orange',
	'oregano',
	'pancakes',
	'papaya',
	'parfait',
	'parmesan',
	'parsley',
	'parsnip',
	'pasta',
	'pastry',
	'peach',
	'peanut',
	'pears',
	'pecan',
	'pepper',
	'pesto',
	'pickle',
	'pineapple',
	'pizza',
	'popcorn',
	'potato',
	'pretzel',
	'pumpkin',
	'quiche',
	'radish',
	'relish',
	'rice',
	'roll',
	'roti',
	'rosemary',
	'salad',
	'salmon',
	'salsa',
	'salt',
	'sandwich',
	'sardine',
	'sausage',
	'smoothie',
	'soda',
	'spaghetti',
	'spinach',
	'squash',
	'stew',
	'sushi',
	'syrup',
	'tea',
	'tiramisu',
	'tortilla',
	'toast',
	'turmeric',
	'turnip',
	'vanilla',
	'waffles',
	'walnuts',
	'wasabi',
	'watercress',
	'watermelon',
	'wheat',
	'wrap',
	'yogurt',
	'ziti',
]
