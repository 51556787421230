import { Injectable } from '@angular/core'
import {
	OnlineOrderingMethod,
	OnlineOrderHeader,
	OnlineOrderCustomer,
	OnlineOrderPayment,
	Order,
	TransientOrderLine,
	Store,
	OrderPayment,
	KioskOrderDetails,
	KioskTerminalSettings,
} from '@golo/models/lib/interfaces'

import { Storage } from '@ionic/storage'
import { BehaviorSubject, from, Observable } from 'rxjs'
import { get } from 'lodash'

@Injectable({
	providedIn: 'root',
})
export class KioskService {
	emptyOrder: KioskOrderDetails = {
		header: {
			notes: '',
			posAction: 'autoAccept',
			source: 'golo',
			collectionMethod: 'Kiosk',
			serviceType: 'takeout',
			totalValue: 0,
			orderNo: '',
			storeId: '',
		},
	}

	private currentKioskOrderDetails: KioskOrderDetails = this.emptyOrder
	private kioskTerminalSettings: KioskTerminalSettings

	kioskOrderDetailsSubject = new BehaviorSubject(this.currentKioskOrderDetails)

	constructor(private storage: Storage) {
		this.storage.get('kioskOrderDetails').then((value) => {
			if (value) this.currentKioskOrderDetails = value
			this.kioskOrderDetailsSubject.next(this.currentKioskOrderDetails)
		})

		this.storage.get('kioskTerminalSetup').then((value) => {
			this.kioskTerminalSettings = value
		})
	}

	getCurrentKioskOrderDetails(): KioskOrderDetails {
		return this.currentKioskOrderDetails
	}

	clearCurrentKioskOrderDetails() {
		this.currentKioskOrderDetails = this.emptyOrder
		this.storage.set('kioskOrderDetails', this.currentKioskOrderDetails)
		this.kioskOrderDetailsSubject.next(this.currentKioskOrderDetails)
	}

	updateCurrentKioskOrderDetails(newDetails: Partial<KioskOrderDetails>) {
		this.currentKioskOrderDetails = {
			...this.currentKioskOrderDetails,
			...newDetails,
		}
		this.currentKioskOrderDetails.header.serviceType =
			get(this.currentKioskOrderDetails, 'orderingMethod.name') === 'Kiosk Eat In' ? 'eatin' : 'takeaway'

		this.storage.set('kioskOrderDetails', this.currentKioskOrderDetails)
		this.kioskOrderDetailsSubject.next(this.currentKioskOrderDetails)
	}

	generateKioskOrder(orderDetails: KioskOrderDetails, cartItems: TransientOrderLine[]): Order {
		return {
			header: {
				storeId: get(orderDetails, 'header.storeId'),
				posAction: 'autoComplete',
				collectionMethod: 'Kiosk',
				serviceType: get(orderDetails, 'orderingMethod.name') === 'Kiosk Eat In' ? 'eatin' : 'takeaway',
				notes: get(orderDetails, 'header.notes'),
				totalValue: get(orderDetails, 'header.totalValue'),
				orderNo: get(orderDetails, 'header.orderNo'),
				displayedOrderNo: get(orderDetails, 'header.displayedOrderNo'),
				deliveryTime: get(orderDetails, 'header.deliveryTime'),
				orderTime: new Date(),
				source: 'golo',
			},
			customer: {
				_id: '',
				details: {
					uid: '',
					emailVerified: false,
					isAnonymous: true,
					displayName: get(orderDetails, 'customer.Name'),
					email: get(orderDetails, 'customer.email'),
					phoneNumber: get(orderDetails, 'customer.phone'),
					metadata: '',
				},
			},
			lines: cartItems,
			payment: {
				value: 0,
				description: 'cod',
				deliveryFee: 0,
				serviceFee: 0,
				rewards: [],
			},
		}
	}

	getKioskTerminalSettings() {
		return this.kioskTerminalSettings
	}

	setKioskTerminalSettings(kioskTerminalSettings: KioskTerminalSettings) {
		this.kioskTerminalSettings = kioskTerminalSettings
		this.storage.set('kioskTerminalSetup', kioskTerminalSettings)
	}

	getKioskTerminalSettings$(): Observable<KioskTerminalSettings | null> {
		return from(this.storage.get('kioskTerminalSetup'))
	}

	getKioskTableNo() {
		const params = localStorage.getItem('table_params')
		if (params) {
			const paramsObj = JSON.parse(params)
			return paramsObj.table
		}
	}
}
