import { Injectable } from '@angular/core'
import { LoadingController, ToastController } from '@ionic/angular'
import { get } from 'lodash'
import { from, Observable } from 'rxjs'
import { concatMap, map } from 'rxjs/operators'
import { LoggerFactory } from '../helpers/logger-factory.class'

@Injectable({
	providedIn: 'root',
})
export class ToasterService {
	private logger = LoggerFactory.getLogger(this)
	loading = new Map<string, any>()
	constructor(private toasterController: ToastController, private loadingController: LoadingController) {}

	async toast(options: { text: string; duration?: number; position?: string }, extraOptions?: any) {
		return this.presentToast(options, extraOptions).toPromise()
	}

	presentToast(
		options: { text: string; duration?: number; position?: string },
		extraOptions?: any
	): Observable<HTMLIonToastElement> {
		const dura = (get(extraOptions, 'buttons.length', 0) === 0 && (options.duration || 5000)) || 0
		return from(
			this.toasterController.create({
				...(extraOptions || {}),
				position: <any>options.position || 'top',
				message: options.text,
				cssClass: 'golo-toaster',
				duration: dura,
			})
		).pipe(
			concatMap((toast: HTMLIonToastElement) => {
				return from(toast.present()).pipe(map(() => toast))
			})
		)
	}

	presentLoading(opts: any, id: string = 'default'): Observable<void> {
		return from(
			this.loadingController.create(opts).then((loader) => {
				this.loading.set(id, loader)
				return loader.present()
			})
		)
	}

	dismissLoading(id: string = 'default') {
		if (this.loading.has(id)) {
			this.loading
				.get(id)
				.dismiss()
				.then(() => {
					this.loading.delete(id)
				})
		}
	}

	dismissAllLoading() {
		Array.from(this.loading.keys()).map((id: string) => this.dismissLoading(id))
	}
}
