<ion-item>
	<ion-label text-wrap>
		<p class="feedback-name">{{ feedbackQuestion.title }}</p>
		<p class="feedback-details">{{ feedbackQuestion.description }}</p></ion-label
	>
	<ion-buttons slot="end">
		<ion-icon
			name="star"
			*ngFor="let num of [1, 2, 3, 4, 5]"
			(click)="rate(num)"
			[ngClass]="num | functionFormat: getColor:rating"
		></ion-icon>
	</ion-buttons>
</ion-item>
