import { HttpClient } from '@angular/common/http'
import { LoggerFactory, LogLevel, ApiService, EnvService, DataSourceService } from 'src/app/services/public-api'
import { environment } from '../environments/environment'

export function initApp(api: ApiService, env: EnvService, data: DataSourceService, http: HttpClient) {
	if (!environment.production) {
		LoggerFactory.setLevel(LogLevel.DEBUG)
		LoggerFactory.setEnableConsole(true)
	} else {
		LoggerFactory.setLevel(LogLevel.DEBUG)
		if (data.frontendLogUrl) {
			LoggerFactory.setHttpClient(
				http,
				data.frontendLogUrl.startsWith('https://') ? data.frontendLogUrl : `${env.get().api}/${data.frontendLogUrl}`
			)
		}
		if (env.get().api === 'https://master.golo.gppos.net') {
			LoggerFactory.setEnableConsole(true)
		}
	}
	return () => {
		return api.refreshTheme().toPromise()
	}
}
