import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter } from 'rxjs/operators'
import { LoggerFactory } from 'src/app/services/public-api'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'
import { SeqModalControllerService } from 'src/app/services/seq-modal-controller.service'

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	isPaymentoptions: boolean // don't flash login button on payments page where payment redirect comes back from
	private logger = LoggerFactory.getLogger(this)
	constructor(public user: CustomerProfileService, public seqModal: SeqModalControllerService, private router: Router) {
		this.router.events
			.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.isPaymentoptions = event.url === '/paymentoptions'
			})
	}

	ngOnInit() {}

	login() {
		this.seqModal.openLoginModal()
	}
}
