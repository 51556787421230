import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { ToasterService, LoggerFactory, FacadeService } from 'src/app/services/public-api'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'
import { get, find } from 'lodash'

@Component({
	selector: 'app-multiselectpopover',
	templateUrl: './multiselectpopover.component.html',
	styleUrls: ['./multiselectpopover.component.scss'],
})
export class MultiselectpopoverComponent implements OnInit {
	totalSelected = 0
	selectedPreps = []
	public productName: String
	public prepMessage: any
	public previousSelected: any
	private logger = LoggerFactory.getLogger(this)
	constructor(
		private modalController: ModalController,
		private toaster: ToasterService,
		private profile: CustomerProfileService,
		private services: FacadeService
	) {}

	ngOnInit() {
		const availabilityCheckTime =
			this.profile?.customer?.currentSelection?.when === 'asap'
				? new Date()
				: this.profile?.customer?.currentSelection?.scheduleDateTime
		const currentOrderSalesTag = get(this.profile, 'customer.currentSelection.orderingMethod.salesOrderTag.id')
		this.selectedPreps = this.prepMessage.messageProducts.map((mp) => {
			this.totalSelected += this.previousSelected[mp.id] || 0
			const product = find(this.services.data.products, ['_id', mp.productId])
			return {
				id: mp.id,
				productId: mp.productId,
				displayName: mp.displayName,
				addonPrice: mp.useProductPrice ? mp.productPrice : mp.price,
				selectedCount: this.previousSelected[mp.id] || 0,
				maxSelection: mp.maxSelection,
				selectedCountWeighting: mp.selectedCountWeighting,
				available:
					this.services.trading.itemIsAvailable(product, availabilityCheckTime) &&
					this.services.trading.itemCanSell(product, currentOrderSalesTag),
			}
		})
		this.logger.debug('Prep', this.prepMessage)
	}

	increase(message) {
		if (
			!this.prepMessage.maximumNumOfCalls ||
			(this.prepMessage.maximumNumOfCalls &&
				this.totalSelected + message.selectedCountWeighting <= this.prepMessage.maximumNumOfCalls)
		) {
			message.selectedCount += 1
			this.totalSelected += message.selectedCountWeighting
		} else {
			this.toaster.toast({ text: 'Maximum items selected', duration: 1000 })
		}
	}

	decrease(message) {
		if (message.selectedCount >= 1) {
			message.selectedCount -= 1
			this.totalSelected -= message.selectedCountWeighting
		}
	}

	closePopover() {
		this.modalController.dismiss(this.selectedPreps)
	}
}
