<ion-header>
	<ion-toolbar color="primary">
		<ion-title>Set Location</ion-title>
		<ion-buttons slot="start">
			<ion-button (click)="back()" aria-label="Back">
				<ion-icon name="arrow-back"></ion-icon>
			</ion-button>
		</ion-buttons>
		<ion-buttons slot="end">
			<ion-button (click)="closeModal()" aria-label="Close">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-content fullscreen="true">
	<input type="text" id="geoadd" />
	<div #map id="map"></div>
	<ion-toolbar class="global-footer" color="primary">
		<ion-grid class="ion-no-padding">
			<ion-row>
				<ion-col size="12" (click)="back()">
					<ion-button class="footer-button" expand="full" fill="clear" aria-label="Accept">ACCEPT</ion-button>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-toolbar>
</ion-content>
