import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Places } from '../../../helpers/places.helper'
import { defaultsDeep } from 'lodash'
import { Location } from '@golo/models/lib/interfaces'
import { GeocodeLocation, GeolocatorService, GeoType, LoggerFactory } from 'src/app/services/public-api'
import { map } from 'rxjs/operators'

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
	mapTarget: Partial<Location>
	current: Location
	private logger = LoggerFactory.getLogger(this)
	constructor(private modalController: ModalController, public geo: GeolocatorService) {
		this.geo
			.getGeoLocation()
			.pipe(
				map((geo: GeocodeLocation) => {
					this.current = geo.location
				})
			)

			.subscribe()
	}

	ngOnInit() {
		const wait = setInterval(() => {
			if (this.current) {
				clearInterval(wait)
				Places.init(this.current, 'geoadd', 'map').selection$.subscribe((res: any) => {
					const latitude = res.geometry.location.lat()
					const longitude = res.geometry.location.lng()
					const [address, type] = this.parseAddressComponents(res.address_components)
					this.mapTarget = {
						address,
						latitude,
						longitude,
						meta: {
							type,
						},
					}
				})
			}
		}, 100)
	}

	ngAfterViewInit() {}

	async closeModal() {
		await this.modalController.dismiss()
	}

	async back() {
		this.geo.mapTarget = defaultsDeep(this.mapTarget, this.geo.mapTarget)
		await this.modalController.dismiss({ nav: 'AddressComponent' })
	}

	parseAddressComponents(addresscomponents: any[]): [string, GeoType] {
		const streetNum = addresscomponents.find((c) => {
			return c.types.includes('street_number')
		})
		const street = addresscomponents.find((c) => {
			return c.types.includes('route')
		})
		const suburb = addresscomponents.find((c) => {
			return c.types.includes('sublocality')
		})
		const city = addresscomponents.find((c) => {
			return c.types.includes('locality')
		})
		const province = addresscomponents.find((c) => {
			return c.types.includes('administrative_area_level_1')
		})
		const country = addresscomponents.find((c) => {
			return c.types.includes('country')
		})
		let type: GeoType = streetNum ? GeoType.PRECISE : GeoType.APPROXIMATE
		const components = [streetNum, street, suburb, city, province, country].filter((e) => !!e).map((c) => c.short_name)
		return [components.join(', '), type]
	}
}
