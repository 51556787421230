<ion-app>
	<ion-split-pane contentId="main-content" when="false">
		<ion-menu contentId="main-content" type="overlay" [swipeGesture]="false" (ionDidOpen)="menuOpened()">
			<ion-content>
				<ion-icon name="close-circle" (click)="closeMenu()"></ion-icon>
				<ion-list class="list" id="inbox-list">
					<ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
						<ion-item
							[hidden]="p.isHidden"
							(click)="selectedIndex = i"
							routerDirection="root"
							[routerLink]="[p.url]"
							[class.selected]="activePath === p.url"
							lines="none"
							detail="false"
						>
							<ion-icon slot="start" [ios]="p.icon" [md]="p.icon"></ion-icon>
							<ion-label>{{ p.title }}</ion-label>
						</ion-item>
					</ion-menu-toggle>
				</ion-list>
			</ion-content>
		</ion-menu>
		<ion-router-outlet id="main-content">
			<div id="hidden-firebaseui-auth-container"></div>
		</ion-router-outlet>
	</ion-split-pane>
</ion-app>
