import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'
import { CustomerProfileService } from 'src/app/customer-profile/public-api'
import { get, find } from 'lodash'

@Component({
	selector: 'app-prep-message-single-select',
	templateUrl: './prep-message-single-select.component.html',
	styleUrls: ['./prep-message-single-select.component.scss'],
})
export class PrepMessageSingleSelectComponent implements OnInit {
	@Input() prepMessage: any
	@Output() prepChange = new EventEmitter()

	private logger = LoggerFactory.getLogger(this)
	constructor(private facade: FacadeService, private profile: CustomerProfileService) {}

	async ngOnInit() {
		const availabilityCheckTime =
			this.profile?.customer?.currentSelection?.when === 'asap'
				? new Date()
				: this.profile?.customer?.currentSelection?.scheduleDateTime
		const currentOrderSalesTag = get(this.profile, 'customer.currentSelection.orderingMethod.salesOrderTag.id')
		this.prepMessage.messageProducts = this.prepMessage.messageProducts.map((mp) => {
			const product = find(this.facade.data.products, ['_id', mp.productId])
			let addon = {
				...mp,
				available:
					this.facade.trading.itemIsAvailable(product, availabilityCheckTime) &&
					this.facade.trading.itemCanSell(product, currentOrderSalesTag),
			}
			return addon
		})
		if (!this.prepMessage.selectedPreps[0]) {
			await this.setDefaultPrepMsg()
		}
		await this.itemSelected()
	}

	async itemSelected() {
		this.prepChange.emit(this.prepMessage)
	}

	async setDefaultPrepMsg() {
		const defaultItem = await this.prepMessage.messageProducts.find((p) => p.defaultCount === 1)
		if (defaultItem) {
			await this.selectAddOn(defaultItem)
		}
	}

	async selectAddOn(addon) {
		if (addon.available) {
			this.prepMessage.selectedPreps[0] = {
				id: addon.id,
				addonPrice: addon.useProductPrice ? addon.productPrice : addon.price,
				displayName: addon.displayName,
				productId: addon.productId,
				qty: 1,
				productCode: this.facade.data.getProductCode(addon.productId),
				available: addon.available,
			}
			this.prepMessage.addOnPrice = addon.useProductPrice ? addon.productPrice : addon.price
			this.prepChange.emit(this.prepMessage)
		} else {
			this.facade.toast.toast({ text: `Item is not available at this time`, duration: 3000 })
		}
	}
}
