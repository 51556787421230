import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import jsQR from 'jsqr'

@Component({
	selector: 'app-qrscanner',
	templateUrl: './qrscanner.component.html',
	styleUrls: ['./qrscanner.component.css'],
})
export class QrscannerComponent implements OnInit, AfterViewInit {
	@ViewChild('video', { static: false })
	video: ElementRef
	@ViewChild('canvas', { static: false }) canvas: ElementRef
	scanActive = false
	videoElement: any
	canvasElement: any
	canvasContext: any
	stream: MediaStream

	constructor(public modalCtrl: ModalController, private router: Router, private route: ActivatedRoute) {}

	async ngAfterViewInit() {
		this.videoElement = this.video.nativeElement
		this.canvasElement = this.canvas.nativeElement
		this.canvasContext = this.canvasElement.getContext('2d')
	}

	async ngOnInit(): Promise<void> {
		await this.startScan()
	}

	async startScan() {
		this.stream = await navigator.mediaDevices.getUserMedia({
			video: { facingMode: 'environment' },
		})
		this.videoElement.srcObject = this.stream
		this.videoElement.setAttribute('playsinline', true)
		this.videoElement.play()
		requestAnimationFrame(await this.scan.bind(this))
	}

	closeScanModal() {
		this.stopVideoStream()
		this.stream = null
		this.videoElement.srcObject = null
		this.videoElement.pause()
		this.scanActive = false
		this.modalCtrl.dismiss({ event })
	}

	stopVideoStream() {
		const tracks = this.stream.getTracks()
		tracks.forEach((track) => {
			track.stop()
		})
	}

	async scan() {
		this.scanActive = true
		this.canvasContext.drawImage(this.videoElement, 0, 0, 80, 80)
		const imageData = this.canvasContext.getImageData(0, 0, 80, 80)
		const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: 'dontInvert' })

		if (code && code.data) {
			this.stopVideoStream()
			const url = new URL(code.data)
			const searchParams: any = this.setSearchParams(url)
			const queryString = searchParams.toString()
			this.router.navigateByUrl('/menu?' + queryString).then(() => {
				location.reload()
			})
			this.closeScanModal()
		} else {
			requestAnimationFrame(await this.scan.bind(this))
		}
	}

	setSearchParams(url: URL) {
		const searchParams: any = new URLSearchParams(new URL(url).search)
		// Convert search parameters to an object
		const parameterObject = {}
		for (const [key, value] of searchParams.entries()) {
			parameterObject[key] = value
		}
		localStorage.setItem('table_params', JSON.stringify(parameterObject))
		return searchParams
	}
}
