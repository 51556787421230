<ion-card>
	<ion-slides
		#promo
		pager="false"
		scrollbar="true"
		[options]="slideOpts"
		(ionSlideTap)="ionSlideTap()"
		(ionSlideDrag)="ionSlideDrag()"
		(ionSlideTouchStart)="ionSlideTouchStart()"
		[hidden]="slidesHidden"
	>
		<ion-slide *ngFor="let slide of slideImages; let i = index" (click)="promoSelect.emit(slide)">
			<picture>
				<source srcset="{{ env.api }}/images/{{ slide._id }}.webp" />
				<img
					alt="Promotion Image"
					width="100%"
					height="100%"
					src="{{ env.api }}/images/{{ slide._id }}.png"
					(error)="slidesHidden = true"
				/>
			</picture>
		</ion-slide>
	</ion-slides>
</ion-card>
