import { NgModule } from '@angular/core'
import { NoPreloading, RouterModule, Routes } from '@angular/router'

import { AuthGuard } from './guards/auth.guard'
import { CartGuideGuard } from './guards/guides/cart-guide.guard'
import { PaymentoptionsGuideGuard } from './guards/guides/paymentoptions-guide.guard'

const routes: Routes = [
	{
		path: '',
		redirectTo: 'menu',
		pathMatch: 'full',
	},
	{
		path: 'home',
		loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
	},
	{
		path: 'orders',
		loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersPageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'profile',
		loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
	},
	{
		path: 'help',
		loadChildren: () => import('./pages/help/help.module').then((m) => m.HelpPageModule),
	},
	{
		path: 'contact',
		loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactPageModule),
	},
	{
		path: 'about',
		loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutPageModule),
	},
	{
		path: 'cart',
		loadChildren: () => import('./pages/cart/cart.module').then((m) => m.CartPageModule),
		canActivate: [AuthGuard, CartGuideGuard],
	},
	{
		path: 'menu/:id',
		loadChildren: () => import('./pages/menu/menu.module').then((m) => m.MenuPageModule),
	},
	{
		path: 'menu',
		loadChildren: () => import('./pages/menu/menu.module').then((m) => m.MenuPageModule),
	},
	{
		path: 'paymentresult',
		loadChildren: () => import('./pages/paymentresult/paymentresult.module').then((m) => m.MenuPageModule),
	},
	{
		path: 'paymentoptions',
		loadChildren: () => import('./pages/paymentoptions/paymentoptions.module').then((m) => m.PaymentoptionsPageModule),
		canActivate: [PaymentoptionsGuideGuard],
	},
	{
		path: 'orderstatus/:orderNo',
		loadChildren: () => import('./pages/orderstatus/orderstatus.module').then((m) => m.OrderstatusPageModule),
	},
	{
		path: 'orderview/:orderNo',
		loadChildren: () => import('./pages/orderview/orderview.module').then((m) => m.OrderviewPageModule),
	},
	{
		path: 'notifications',
		loadChildren: () => import('./pages/notifications/notifications.module').then((m) => m.NotificationsPageModule),
	},
	{
		path: 'notification-detail/:noteIndex',
		loadChildren: () =>
			import('./pages/notification-detail/notification-detail.module').then((m) => m.NotificationDetailPageModule),
	},
	{
		path: 'rewards',
		loadChildren: () => import('./pages/rewards/rewards.module').then((m) => m.RewardsPageModule),
	},
	{
		path: 'story',
		loadChildren: () => import('./pages/story/story.module').then((m) => m.StoryPageModule),
	},
	{
		path: 'feedback',
		loadChildren: () => import('./pages/feedback/feedback.module').then((m) => m.FeedbackPageModule),
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading, relativeLinkResolution: 'corrected' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
