import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FeedbackQuestion } from '@golo/models/lib/interfaces/feedbacks'
@Component({
	selector: 'app-feedback-input',
	templateUrl: './feedback-input.component.html',
	styleUrls: ['./feedback-input.component.css'],
})
export class FeedbackInputComponent {
	@Input() feedbackQuestion: FeedbackQuestion
	@Input() inputValue: string
	@Output() inputValueChange: EventEmitter<string> = new EventEmitter()

	constructor() {}

	inputChange(value: string) {
		this.inputValue = value
		this.inputValueChange.emit(this.inputValue)
	}
}
