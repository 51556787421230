<ion-card>
	<ion-card-content (click)="addFilter()">
		<ion-icon name="filter"></ion-icon>
		<ion-label *ngIf="!filter?.filtered" class="chip-placeholder">Please select a filter</ion-label>
		<ion-badge
			class="detail-badge"
			color="secondary"
			style="background-color: {{ filter.colour }}"
			*ngFor="let filter of filter?.tags"
		>
			{{ filter.name }}
		</ion-badge>
		<ion-badge class="detail-badge" *ngFor="let filter of filter?.orderingMethods">
			{{ filter.salesOrderTag.name }}
		</ion-badge>
	</ion-card-content>
</ion-card>
