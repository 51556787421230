<ion-card #options (click)="openOptions()">
	<ion-grid *ngIf="selectedStore">
		<ion-row class="ion-no-margin">
			<ion-col size="7">
				<ion-grid class="ion-no-padding">
					<ion-row>
						<ion-col>
							<h1 class="ion-text-wrap global-name ion-no-margin">{{ selectedStore.name }}</h1>
							<p class="global-details ion-no-margin" *ngIf="orderMethod === 'delivery' && !isRewards">
								<span [ngSwitch]="addressState">
									<span> Deliver to {{ orderDestination }} @ {{ orderTime }}</span>
								</span>
							</p>
							<p class="global-details ion-no-margin" *ngIf="orderMethod === 'curbside' && !isRewards">
								{{ orderMethodDescription }} @ {{ orderTime }}
							</p>
							<p class="global-details ion-no-margin" *ngIf="orderMethod === 'collection' && !isRewards">
								{{ orderMethodDescription }} @ {{ orderTime }}
							</p>
							<p class="global-details ion-no-margin" *ngIf="orderMethod === 'eatin' && !isRewards">
								{{ orderMethodDescription }} @ {{ orderTime }}
							</p>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col size="auto">
							<ion-badge class="badge-success badge-status" *ngIf="selectedStore?.status?.isOpen">OPEN</ion-badge>
							<ion-badge class="badge-warning badge-status" *ngIf="!selectedStore?.status?.isOpen">CLOSED</ion-badge>
						</ion-col>
						<ion-col>
							<p class="global-details label-status" *ngIf="!selectedStore?.status?.isOpen">
								Opens at {{ selectedStore?.tradingDetails?.times.open }}
							</p>
							<p class="global-details label-status" *ngIf="selectedStore?.status?.isOpen">
								Closes at {{ selectedStore?.tradingDetails?.times.close }}
							</p>
						</ion-col>
					</ion-row>
				</ion-grid>
			</ion-col>
			<ion-col size="5" class="ion-text-right">
				<ion-grid class="ion-no-padding">
					<ion-row>
						<ion-col>
							<ion-badge class="badge" color="success" *ngIf="storeAlive">{{ orderMethodDescription }}</ion-badge>
							<ion-badge class="badge" color="danger" *ngIf="!storeAlive">OFFLINE</ion-badge>
						</ion-col>
					</ion-row>
					<ion-row class="ion-no-margin">
						<ion-col class="ion-text-right">
							<ion-button
								aria-label="Phone Store"
								fill="solid"
								size="small"
								color="secondary"
								(click)="$event.stopPropagation()"
								href="tel:{{ selectedStore?.contactDetails?.phoneNumber }}"
								><ion-icon class="option-icon" name="call"></ion-icon
							></ion-button>
						</ion-col>
					</ion-row>
				</ion-grid>
			</ion-col>
		</ion-row>
	</ion-grid>
	<ion-grid *ngIf="!selectedStore" (click)="home()">
		<ion-row>
			<ion-col size="12">
				<h1 class="ion-text-wrap global-name ion-no-margin">{{ statusMessage }}</h1>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-card>
