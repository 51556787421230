import { Inject, Injectable } from '@angular/core'
import { LoggerFactory } from '../helpers/logger-factory.class'
import { ENV } from '../services.module'

@Injectable({
	providedIn: 'root',
})
export class EnvService {
	private logger = LoggerFactory.getLogger(this)

	constructor(@Inject(ENV) private enviro: any){
		this.setStaticEnvironment(this.enviro)
	}

	environment: any
	setStaticEnvironment(environment: any){
		this.environment = environment
		const params = localStorage.getItem('golo_params')
		if (params) {
			const paramsObj = JSON.parse(params)
			Object.keys(paramsObj).map((key) => {
				if (paramsObj[key]) {
					this.environment[key] = paramsObj[key]
					console.warn(`environment[${key}] set to ${paramsObj[key]} from localStorage golo_params`)
				}
			})
		}
	}

	get() {
		return this.environment
	}
}
