import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AppNotification } from '@golo/models/lib/interfaces'
import { ModalController } from '@ionic/angular'
import { Storage } from '@ionic/storage'
import { from } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { FacadeService, LoggerFactory } from 'src/app/services/public-api'
import { QrscannerComponent } from '../qrscanner/qrscanner.component'
import { CustomerProfileService } from 'src/app/customer-profile/customer-profile.service'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	@Input() cartItems = []
	@Input() showSearch = false
	@Input() showLastOrder = false
	@Input() appTitle = ''
	@Input() searchFunction: () => void

	menuNav = true
	unreadNotes = 0
	lastOrderNo: string
	currentRoute: string
	private logger = LoggerFactory.getLogger(this)
	isModalOpen: boolean
	eatInEnabled = false
	constructor(
		public modalController: ModalController,
		public services: FacadeService,
		private router: Router,
		private storage: Storage,
		private profile: CustomerProfileService
	) {
		from(this.storage.get('lastOrderNo'))
			.pipe(
				map((lastOrderNo) => {
					this.lastOrderNo = lastOrderNo
				})
			)
			.subscribe()
	}

	ngOnInit() {
		this.services.appNote.notes$.pipe(filter((e) => !!e)).subscribe((notes: AppNotification[]) => {
			this.unreadNotes = notes.filter((n) => !n.read).length
		})
		this.currentRoute = this.router.url
		this.eatInEnabled = this.profile.customer?.currentSelection?.store?.orderingMethods.some((s) => s.name === 'Eat In')
	}

	notifications() {
		this.router.navigate(['/notifications'])
	}

	cart() {
		this.router.navigate(['/cart'])
	}

	lastOrderView() {
		from(this.storage.get('lastOrderNo'))
			.pipe(
				map((lastOrderNo) => {
					if (lastOrderNo) {
						this.router.navigate([`/orderview`, lastOrderNo])
					}
				})
			)
			.subscribe()
	}

	searchFunc() {
		this.searchFunction && this.searchFunction()
	}

	async refresh() {
		localStorage.setItem('backgroundversion', new Date().getTime().toString())
		await this.router.navigate([`/`])
		location.reload()
	}

	async startScan() {
		await this.openModal()
	}

	async openModal() {
		const modal = await this.modalController.create({
			component: QrscannerComponent,
			cssClass: 'my-custom-class',
		})
		return await modal.present()
	}
}
