import { Injectable } from '@angular/core'
import { Availability, Product, Store } from '@golo/models/lib/interfaces'
import isBetween from 'dayjs/plugin/isBetween'
import dayjs from 'dayjs'
import { LoggerFactory } from '../helpers/logger-factory.class'
dayjs.extend(isBetween)

@Injectable({
	providedIn: 'root',
})
export class TradingService {
	private logger = LoggerFactory.getLogger(this)
	public tradingTimes(availability: Availability) {
		let schedule: any = {}
		availability.availability
			.map((a) => {
				return { ...a, days: a.days || [1, 2, 3, 4, 5, 6, 7] }
			})
			.forEach((r) => {
				r.days.forEach((d) => {
					if (r.time) {
						if (schedule[d]) {
							schedule[d].push({ start: r.time.start, end: r.time.end })
						} else {
							schedule[d] = [{ start: r.time.start, end: r.time.end }]
						}
					}
				})
			})
		Object.keys(schedule).forEach((i) => {
			if (!schedule[i].length) {
				schedule[i] = [{ start: '00:00', end: '23:59' }]
			}
		})
		return schedule
	}

	public isTrading(availability: Availability, time: Date): boolean {
		const day = availability && this.tradingTimes(availability)[dayjs(time).day() + 1]
		const canTrade = day
			? !!day.find((t) => {
					return dayjs(time).isBetween(
						dayjs(time)
							.set('h', parseInt(t.start.split(':')[0]))
							.set('m', parseInt(t.start.split(':')[1]))
							.set('s', 0)
							.set('millisecond', 0),
						dayjs(time)
							.set('h', parseInt(t.end.split(':')[0]))
							.set('m', parseInt(t.end.split(':')[1]))
							.set('s', 59)
							.set('millisecond', 999)
					)
			  })
			: false
		return canTrade
	}

	public tradingHoursToday(availability: Availability) {
		const day = availability && this.tradingTimes(availability)[dayjs().day() + 1]
		return day ? { open: day[0].start, close: day[0].end } : { open: '00:00', close: '00:00' }
	}

	itemInStore(p: Product, s?: Store): Boolean {
		const itemTags = (p.productTags || []).filter((i) => !!i).map((i) => i.id)
		if (!itemTags.length || !s) return true // if item has no tags or no store is selected then item is available
		const storeTags = (s.productTags || []).filter((i) => !!i).map((i) => i.id)
		if (!itemTags.some((i) => storeTags.indexOf(i) >= 0)) return false // if item has tags and at least one tag exists in the store tag then it is available
		const storeExcludeTags = (s.productExcludeTags || []).filter((i) => !!i).map((i) => i.id)
		if (storeExcludeTags.some((i) => itemTags.indexOf(i) >= 0)) return false // if item has a tag and none of the tags are in the excluded tag list of the store then it is available
		return true // item is available
	}

	itemCanSell(p: Product, OrderMethodTagId?: string): Boolean {
		const itemTags = (p.salesOrderTags || []).filter((i) => !!i).map((i) => i.id)
		if (!itemTags.length || !OrderMethodTagId) return true
		if (itemTags.indexOf(OrderMethodTagId) === -1) return false
		return true // item is available
	}

	itemIsAvailable(p: Product, d: Date): Boolean {
		if (!p.posConfig.availability.length) return true // if no availability is set then it is available
		return !!p.posConfig.availability.find((a) => {
			return this.isTrading(a, d)
		})
	}
}
