import { PopoverController } from '@ionic/angular'
import { StorefilterpopoverComponent } from './storefilterpopover/storefilterpopover.component'
import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { LoggerFactory } from 'src/app/services/public-api'

@Component({
	selector: 'app-storefilter',
	templateUrl: './storefilter.component.html',
	styleUrls: ['./storefilter.component.scss'],
})
export class StorefilterComponent implements OnInit {
	private logger = LoggerFactory.getLogger(this)
	constructor(private popover: PopoverController) {}
	@Output() filterChange = new EventEmitter()
	@Output() toggleFilter = new EventEmitter()

	filter = { filtered: false, tags: [], orderingMethods: [] }

	async ngOnInit() {
		await this.addFilter()
	}

	async addFilter() {
		const ms = await this.popover.create({
			component: StorefilterpopoverComponent,
			componentProps: {
				filter: this.filter,
			},
			backdropDismiss: false,
		})
		ms.onDidDismiss().then((res) => {
			this.filterChange.emit(this.filter)
		})
		await ms.present()
	}
}
