import { Injectable } from '@angular/core'
import { PrepMessage, Product, TransientPrepMessage } from '@golo/models/lib/interfaces'
import { Storage } from '@ionic/storage'
import { find, get } from 'lodash'
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs'
import { concatMap, map } from 'rxjs/operators'
import { LoggerFactory } from '../helpers/logger-factory.class'
import { faq } from '../helpers/mock/dummy-data'

export interface InitData {
	nodes: any[]
	products: any[]
	productGroups: any[]
	prepMessages: any[]
	tags: any[]
	settings: any[]
	vapidPublicKey: string
	wirecardUrl: string
	frontendLogUrl?: string
	kioskSettings: any[]
}

@Injectable({
	providedIn: 'root',
})
export class DataSourceService {
	private logger = LoggerFactory.getLogger(this)
	dataReloads = ['products', 'productavailabilitytemplates', 'node', 'tags', 'productgroups', 'prepscreens']
	public get nodes() {
		return get(this.data, 'nodes', []).map((node) => {
			return {
				...node,
				name: node.tradingDetails.registeredName ? node.tradingDetails.registeredName : node.name,
				status: {},
			}
		})
	}
	public get products() {
		return get(this.data, 'products', [])
	}
	public get productGroups() {
		return get(this.data, 'productGroups', [])
	}
	public get prepMessages() {
		return get(this.data, 'prepMessages', [])
	}
	public get tags() {
		return get(this.data, 'tags', [])
	}
	public get settings() {
		return get(this.data, 'settings', [])
	}
	public get kioskSettings() {
		return get(this.data, 'kioskSettings', [])
	}
	public get vapidPublicKey() {
		return get(this.data, 'vapidPublicKey')
	}
	public get wirecardUrl() {
		return get(this.data, 'wirecardUrl')
	}
	public get frontendLogUrl() {
		return get(this.data, 'frontendLogUrl')
	}

	loaded$ = new Subject<boolean>()
	dataLoaded$ = new BehaviorSubject<boolean>(false)
	loaded = false
	private data: InitData
	constructor(private storage: Storage) {}

	load(data: InitData): Observable<InitData | null> {
		if (data) {
			this.data = data
			this.storage.set('data', data)
			this.loaded = true
			this.loaded$.next(true)
			this.dataLoaded$.next(true)
			this.logger.debug(`Data:`, data)
			return of(data)
		} else {
			return this.localLoad().pipe(
				concatMap((data: InitData | null) => {
					if (data) {
						return this.load(data)
					} else {
						return of(null)
					}
				})
			)
		}
	}

	localLoad(): Observable<InitData | null> {
		return from(this.storage.get('data')).pipe(
			map((data: InitData | null) => {
				this.data = data
				return data
			})
		)
	}

	getProduct(id: string): Product {
		return find(this.products, ['_id', id])
	}

	getProductCode(id: string) {
		const product = find(this.products, ['_id', id])
		return product?.posConfig?.onlineOrderingCode || id
	}

	getProductCodeFromProduct(product: Product) {
		return product?.posConfig.onlineOrderingCode || product._id
	}

	getProductPrice(product: Product, pricingOption?: string): number {
		return (
			pricingOption
				? product.prices.find(({ id }) => id === pricingOption) ||
				  product.prices.find(({ id }) => id === 'basePrice') ||
				  product.prices[0] || { selling: 0 }
				: product.prices.find(({ id }) => id === 'basePrice') || product.prices[0] || { selling: 0 }
		).selling
	}

	getProductPriceById(productId: string, pricingOption?: string): number {
		const product = find(this.products, ['_id', productId])
		return product
			? (
					product.prices.find(({ id }) => id === pricingOption) ||
					product.prices.find(({ id }) => id === 'basePrice') ||
					product.prices[0] || { selling: 0 }
			  ).selling
			: 0
	}

	getPrepMessages(ids: string[]) {
		return this.prepMessages.filter((p) => {
			return ids.includes(p._id)
		})
	}

	clone(obj: any): any {
		if (!obj) return
		return JSON.parse(JSON.stringify(obj))
	}

	getPrepMessage(id: string, pricingOption: string = 'basePrice'): PrepMessage {
		const prep: PrepMessage = this.clone(this.prepMessages.find((p) => p._id === id))
		if (!prep) return
		prep.messageProducts.forEach((mp) => (mp.productPrice = this.getProductPriceById(mp.productId, pricingOption)))
		return prep
	}

	getFaqs(): any[] {
		return faq
	}

	getFaqByCatIds(catId): any {
		return faq.filter((f) => f.category === catId)
	}

	getTips(): any[] {
		return [
			{ name: '0%', value: 0 },
			{ name: '5%', value: 5 },
			{ name: '10%', value: 10 },
			{ name: '15%', value: 15 },
			{ name: '20%', value: 20 },
		]
	}

	getTransientPreps(
		product: Product,
		parent: string = '',
		parent_ordinal: string = '',
		pricingOption: string = 'basePrice'
	): TransientPrepMessage[] {
		let preps = []
		if (product.posConfig?.selectedPrepMessages) {
			let ordinal = 0
			product.posConfig.selectedPrepMessages.forEach((p) => {
				const thisPrep = this.getPrepMessage(p._id, pricingOption)
				if (p.numCalls > 1) {
					for (let i = 1; i <= p.numCalls; i++) {
						ordinal += 1
						preps.push({
							...thisPrep,
							name: `${parent}${thisPrep.name} #${i}`,
							callId: i,
							selectedPreps: [],
							addOnPrice: 0,
							ordinal: parent_ordinal === '' ? `${ordinal}` : `${parent_ordinal}.${ordinal}`,
						})
					}
				} else {
					ordinal += 1
					preps.push({
						...thisPrep,
						name: `${parent}${thisPrep.name}`,
						callId: 1,
						selectedPreps: [],
						addOnPrice: 0,
						ordinal: parent_ordinal === '' ? `${ordinal}` : `${parent_ordinal}.${ordinal}`,
					})
				}
			})
		}
		return this.sortTransientPreps(preps)
	}

	sortTransientPreps(preps: any[]): TransientPrepMessage[] {
		return preps.sort((a: any, b: any) => {
			if (a.ordinal < b.ordinal) {
				return -1
			} else if (a.ordinal > b.ordinal) {
				return 1
			} else return 0
		})
	}
}
