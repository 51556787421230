import { ToasterService, LoggerFactory } from 'src/app/services/public-api'
import { Injectable } from '@angular/core'
import { FirebaseuiService } from './firebaseui.service'
import { FIREBASE_UI_CONFIG } from '../models'

@Injectable({
	providedIn: 'root',
})
export class FirebaseAuthRedirectService {
	private logger = LoggerFactory.getLogger(this)
	constructor(private toaster: ToasterService, private firebaseUIService: FirebaseuiService) {}

	handleRedirect(afterHandled?: () => void) {
		//always running it on page load is less error prone under the various conditions
		this.checkFirebaseUIForSignInResult(afterHandled)
	}

	checkFirebaseUIForSignInResult(afterHandled?: () => void) {
		try {
			this.firebaseUIService.firebaseUiInstance.start('#hidden-firebaseui-auth-container', {
				...FIREBASE_UI_CONFIG,
				callbacks: {
					signInSuccessWithAuthResult: () => {
						this.logger.debug('checkFirebaseUIForSignInResult')
						afterHandled && afterHandled()
						return false
					},
					signInFailure: (error: firebaseui.auth.AuthUIError) => {
						this.logger.debug('checkFirebaseUIForSignInResult -> error', error)
						return this.errorFeedback(error.message).then(() => {
							afterHandled && afterHandled()
						})
					},
				},
			})
		} catch {}
	}

	async errorFeedback(message: string) {
		this.toaster.toast({ text: message })
	}
}
